import { useState, useContext } from "react";
import { proposeQuote } from "../../apiCalls";
import { UIContext, UIState, OptionTypes } from "../../providers/UIProvider";
import useAccessControl from "../../hooks/useAccessControl";
import ReusableDialog from "../../components/Dialog";
import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import { dispatchError } from "../../common/fx";
import { Stack } from "@mui/system";

function QuoteProposeWarning() {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState(false);
  const proposeAllowed = useAccessControl("Quote", "ProposeQuote");

  const handleClose = () => {
    setOpen(false);
  }

  const handlePropose = () => {
    proposeQuote(
      state.quote.id,
      (res:any) => {
        dispatch({
          type: "Quote",
          payload: {
            quoteStatus: "Quoted",
            lastSaved: new Date(),
          },
          source: "updateQuoteKeyword"
        });
      },
      (err:any) => dispatch(dispatchError(err.response.data))
    )
  }

  return (
      <ReusableDialog
        buttonText="Propose"
        content={
          <>
            <DialogContent>
              <Box sx={{ mt: 2 }}>
                  <p>Proposing a quote will lock down the options, prices, and you can no longer edit this quote.</p>
                  <p style={{ fontSize: "14px", marginBottom: "4px" }}>
                    To <strong>generate a new editable quote</strong>, use the <strong>Clone</strong> feature on a proposed quote.
                  </p>
              </Box>
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }}>
              <Stack
                direction="row"
                sx={{ justifyContent: "space-between", width: "100%" }}
              >
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handlePropose();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Propose
                </Button>
              </Stack>
            </DialogActions>
          </>
        }
        disabled={!proposeAllowed ||
                  state.pendingItems.filter((item: OptionTypes) => !item.isNew).length === 0}
        isOpen={open}
        setIsOpen={(value) => setOpen(value)}
        title="Propose Quote"
      />
    )
}

export default QuoteProposeWarning;