import { useState, useEffect, useContext } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Stack,
} from "@mui/material";
import { DesignServices, Home, Houseboat } from "@mui/icons-material";

import {
  ChangeElevation,
  ChangePlan,
  createSigningDoc,
  downloadChangeOrderPDF,
  getOptionsCatalog,
  getPlanElevations,
  getPlans,
  GetSubmittedBasePlan,
  storeChangeOrderPDF,
  UpdateElevation,
  UpdatePlan,
} from "../../apiCalls";
import {
  PlanTypes,
  Signatory,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import { dispatchError } from "../../common/fx";
import { isEmpty, isNil } from "lodash";
import moment from "moment";
import useAccessControl from "../../hooks/useAccessControl";
import { LoadingButton } from "@mui/lab";
import DocusignStep from "../../components/DocusignStep/DocusignStep";

export type homeInformation = {
  plan: {
    planId: string;
    planNumber: string;
  };
  elevation: {
    price: number;
    planElevationNumber: string;
    projectID: string;
    planElevationID: string;
    salesPriceControlID: string;
  };
};

const PlanChange = ({
  fetchIntention,
  fetchJobid,
  disabled,
  handleClose,
  addressId,
  title,
  updatePlanMode,
}: any) => {
  const [currentPrice, setCurrentPrice] = useState("");
  const [priceDifference, setPriceDifference] = useState("");
  const [planInfo, setPlanInfo] = useState({ id: "" });
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");
  const GetOptionsCatalogAccess = useAccessControl(
    "Option",
    "GetOptionsCatalog"
  );
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const [loadingCalculation, setLoadingCalculation] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [specBuyer, setSpecBuyer] = useState<boolean>(false);
  const [numberOfSignatures, setNumberOfSignatures] = useState<number>(1);
  const [newHomeInformation, setNewHomeInformation] = useState<homeInformation>(
    {
      plan: {
        planNumber: "",
        planId: "",
      },
      elevation: {
        price: 0,
        planElevationNumber: "",
        projectID: "",
        planElevationID: "",
        salesPriceControlID: "",
      },
    }
  );
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [signatories, setSignatories] = useState<Signatory[]>([]);
  const [buyerCount, setBuyerCount] = useState<number>(0);

  useEffect(() => {
    if (state.selectedJob.planId && state.selectedJob.planNumber) {
      GetPlansAccess &&
        getPlans(
          {
            projectID: state.selectedJob.projectId,
            projectNumber: state.selectedJob.projectNumber,
          },
          (res: any) => {
            dispatch({
              type: "AvailablePlans",
              payload: res.data.sort(
                (
                  a: {
                    planNumber: string;
                  },
                  b: {
                    planNumber: string;
                  }
                ) => a.planNumber.localeCompare(b.planNumber)
              ),
            });
          },
          (err: any) => dispatch(dispatchError(err.response.data))
        );
      GetPlanElevationsAccess &&
        getPlanElevations(
          {
            ProjectID: state.selectedJob.projectId,
            projectNumber: state.selectedJob.projectNumber,
            planId: state.selectedJob.planId,
            planNumber: state.selectedJob.planNumber,
          },
          (res: any) => {
            dispatch({
              type: "AvailableElevations",
              payload: res.data.sort(
                (
                  a: {
                    planElevationNumber: string;
                  },
                  b: {
                    planElevationNumber: string;
                  }
                ) => a.planElevationNumber.localeCompare(b.planElevationNumber)
              ),
            });
            fetchJobid();
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err.message,
                statusText: err.response.statusText,
                title: err.response.data.title,
                status: err.response.status,
                detail: err.response.data.detail,
                data: err.response.data,
              })
            )
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedJob, GetPlansAccess]);

  const toggleStep = () => {
    switch (currentStep) {
      case 1:
        return setCurrentStep(2);

      case 2:
        return updatePlanMode
          ? (setLoadingSubmit(true),
            UpdatePlan(
              {
                intentionId: state.selectedJob.id,
                newPlanElevationId:
                  newHomeInformation?.elevation.planElevationID,
                salesPriceControlID:
                  newHomeInformation?.elevation.salesPriceControlID,
                jobId: state.selectedJob.jobId,
              },
              async (res: any) => {
                await fetchJobid((newid: string) => {
                  fetchIntention(() => {
                    dispatch({
                      type: "Snackbar",
                      payload: {
                        show: true,
                        message: `${state.selectedJob.address} plan has been updated. Remember to submit the change order.`,
                        severity: "success",
                      },
                    });
                    setLoadingSubmit(false);
                    setCurrentStep(1);
                    setOpen(false);
                    handleClose();
                  }, newid);
                });
              },
              (err: any) => {
                dispatch(dispatchError(err.response.data));
                handleClose();
                setLoadingSubmit(false);
                setCurrentStep(1);
                setOpen(false);
              }
            ))
          : setCurrentStep(3);

      case 3:
        return (
          setLoadingSubmit(true),
          ChangePlan(
            {
              planElevationOptionId:
                planInfo?.id ?? "00000000-0000-0000-0000-000000000000",
              intentionId: state.selectedJob.id,
              salesPriceControlID:
                newHomeInformation?.elevation.salesPriceControlID,
              newPlanElevationSalesPriceControlID:
                newHomeInformation?.elevation.salesPriceControlID.toString(),
              expectedPriceDifference: priceDifference.toString(),
              useSpecBuyer: specBuyer,
              buyerCount: numberOfSignatures,
            },
            async (res: any) => {
              await storeChangeOrderPDF(
                {
                  changeOrderId: res.data,
                  buyerCount,
                },
                () => {
                  createSigningDoc(
                    res.data as string,
                    buyerCount + 1,
                    signatories,
                    () => {
                      console.log("success");
                    },
                    (err: any) => {
                      dispatch(
                        dispatchError({
                          message: err.message,
                          statusText: err.response.statusText,
                          title: err.response.data.title,
                          status: err.response.status,
                          detail: err.response.data.detail,
                          data: err.response.data,
                        })
                      );
                    }
                  );
                },
                (err: any) => {
                  dispatch(
                    dispatchError({
                      message: err.message,
                      statusText: err.response.statusText,
                      title: err.response.data.title,
                      status: err.response.status,
                      detail: err.response.data.detail,
                      data: err.response.data,
                    })
                  );
                }
              );

              await fetchIntention((coUpdate: any) => {
                dispatch({
                  type: "Snackbar",
                  payload: {
                    show: true,
                    message: `${state.selectedJob.address} plang has been updated. Remember to submit the change order.`,
                    severity: "success",
                  },
                });
                setLoadingSubmit(false);
                setCurrentStep(1);
                setOpen(false);
                setPriceDifference("");
                handleClose();
              });
            },
            (err: any) => {
              dispatch(dispatchError(err.response.data));
              setLoadingSubmit(false);
              setCurrentStep(1);
              setOpen(false);
              handleClose();
            }
          )
        );
    }
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        toolTipTitle={
          disabled
            ? "Must have a submitted CO with a base plan option to change elevation."
            : undefined
        }
        disabled={disabled}
        buttonVariant={"text"}
        buttonStyle={{
          color: "black",
          paddingRight: "3rem",
        }}
        buttonText={title}
        icon={
          <DesignServices
            sx={{
              marginRight: "5px",
            }}
          />
        }
        maxWidth="sm"
        title={
          currentStep === 1
            ? title
            : updatePlanMode
            ? "Confirm Update"
            : "Confirm Change"
        }
        content={
          <>
            <DialogContent>
              <Box p={2}>
                {currentStep === 1 && state.selectedJob.planNumber && (
                  <Step1
                    updatePlanMode={updatePlanMode}
                    setSpecBuyer={setSpecBuyer}
                    newHomeInformation={newHomeInformation}
                    setNewHomeInformation={setNewHomeInformation}
                    setNumberOfSignatures={setNumberOfSignatures}
                    numberOfSignatures={numberOfSignatures}
                  />
                )}
                {currentStep === 2 && (
                  <Step2
                    priceDifference={priceDifference}
                    setPriceDifference={setPriceDifference}
                    setPlanInfo={setPlanInfo}
                    loadingCalculation={loadingCalculation}
                    setLoadingCalculation={setLoadingCalculation}
                    currentPrice={currentPrice}
                    setCurrentPrice={setCurrentPrice}
                    updatePlanMode={updatePlanMode}
                    newHomeInformation={newHomeInformation}
                    //type={title === "Plan Change" ? "change" : "update"}
                  />
                )}
                {currentStep === 3 && (
                  <DocusignStep
                    type={
                      specBuyer
                        ? "Spec"
                        : state?.changeOrder?.customerBuyer?.connectionNumber
                    }
                    setSignatories={setSignatories}
                    signatories={signatories}
                    buyerCount={buyerCount}
                    setBuyerCount={setBuyerCount}
                  />
                )}
              </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Box>
                {currentStep === 2 && (
                  <Button
                    onClick={() => {
                      setCurrentStep(1);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    Back
                  </Button>
                )}
              </Box>
              <Stack direction="row" spacing={1}>
                <Button
                  onClick={() => {
                    setOpen(false);
                    setCurrentStep(1);
                    handleClose();
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loadingSubmit}
                  disabled={
                    isEmpty(newHomeInformation.elevation.planElevationNumber) ||
                    isEmpty(newHomeInformation.plan.planNumber) ||
                    loadingSubmit
                  }
                  onClick={(e) => toggleStep()}
                  variant="contained"
                  color="primary"
                >
                  {currentStep === 1 && "Next"}

                  {currentStep === 2 && updatePlanMode && "Update"}

                  {currentStep === 2 && !updatePlanMode && "Next"}

                  {currentStep === 3 && !updatePlanMode && "Submit"}
                </LoadingButton>
              </Stack>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default PlanChange;
