import { useContext, useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPro, GridColDef, GridFilterModel } from "@mui/x-data-grid-pro";
import {
  Stack,
  Tooltip,
  Button,
  Chip,
  Skeleton,
  TextField,
  ListItemText,
  TablePagination,
  InputAdornment,
  Autocomplete,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { Link as RLink, useNavigate, useSearchParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { numberWithCommas } from "../../utils/formatMoney";
import {
  CatchingPokemon,
  CheckCircle,
  ContentCopy,
  Delete,
  Download,
  Edit,
  FlutterDash,
  HourglassTop,
  Info,
  LocalBar,
  PendingOutlined,
  PublishedWithChanges,
  RequestQuoteOutlined,
  RestorePageOutlined,
  SearchOutlined,
  Visibility,
} from "@mui/icons-material";
import Notes from "../../modals/LogNotes";
import {
  CommunityTypes,
  QuoteSummary,
  UIContext,
} from "../../providers/UIProvider";
import {
  deleteQuote,
  downloadQuotePDF,
  getListQuoteForBuyer,
  updateQuote,
} from "../../apiCalls";
import { isNil, isString } from "lodash";
import { dispatchError } from "../../common/fx";
import LoadingGridOverlay from "../../components/Loading/LoadingGridOverlay";
import useAccessControl from "../../hooks/useAccessControl";
import DeleteConfirmation from "../../modals/DeleteConfirmation";
import CreateQuoteWithBuyer from "../../modals/CreateQuoteWithBuyer/CreateQuoteWithBuyer";
import { filterOptions } from "../../utils/filterOptions";
import EditQuoteBuyer from "../../modals/EditQuoteBuyer/EditQuoteBuyer";
import ValidatedInput from "../../components/ValidatedInput";
import "./QuoteSummary.scss";

function Copyright(props: any) {
  const applicationPrId = process.env.REACT_APP_APPLICATION_PR_ID;
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      sx={{ mt: (theme) => theme.spacing(12), mb: (theme) => theme.spacing(4) }}
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://highlandhomes.com/">
        Highland Homes
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
      <span>{applicationPrId}</span>
    </Typography>
  );
}

const theme = createTheme();

export default function BuyerQuoteSummary() {
  const [searchOptions, setSearchOptions] = useState<{
    community: CommunityTypes;

    search: any;
  }>({
    search: "",

    community: {
      projectNumber: "",
      projectId: "",
      name: "",
    },
  });

  const [state, dispatch] = useContext<any>(UIContext);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 0,
  });

  // Update the pagination model when the TablePagination changes
  const handleChangePage = (event: unknown, newPage: number) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };

  const handleChangePageSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaginationModel((prev) => ({
      ...prev,
      pageSize: parseInt(event.target.value, 10),
      page: 0,
    }));
  };

  const [downloading, setDownloading] = useState<{
    id: string;
    loading: boolean;
  }>({
    id: "",
    loading: false,
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  // Filter out non-submitted objects

  const GetIntentionAccess = useAccessControl("Intention", "GetIntention");
  const UpdateQuoteAccess = useAccessControl("Quote", "UpdateQuote");
  const DeleteQuoteAccess = useAccessControl("Quote", "DeleteQuote");

  let quoteBuyerId = searchParams.get("quoteBuyerId");

  const [loading, setLoading] = useState<boolean>(true);
  const [hasBeenDeleted, setHasBeenDeleted] = useState<number>(0);

  const [confirmDelete, setConfirmDelete] = useState<{
    status: boolean;
    quoteNumber: "";
    id: "";
  }>({ status: false, quoteNumber: "", id: "" });

  const handleUpdateKeyword = (quoteId: string, newKeyword: string) => {
    updateQuote(
      quoteId,
      { quoteKeyword: newKeyword },
      (res: any) => {
        GetIntentionAccess && fetchQuote();
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const fetchQuote = () => {
    setLoading(true);
    getListQuoteForBuyer(
      {
        quoteBuyerId: quoteBuyerId as string,
        projectId: searchOptions.community.projectId,
        search: searchOptions.search,
      },
      (res: { data: QuoteSummary }) => {
        dispatch({
          type: "SelectedBuyerQuote",
          payload: res.data,
        });

        dispatch({
          type: "Navbar",
          payload: {
            title: `Buyer Quote Summary - ${
              !isNil(res?.data?.buyerName) && isString(res?.data?.buyerName) ? (
                res?.data?.buyerName
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ minWidth: "5rem", fontSize: "1rem" }}
                />
              )
            }`,
          },
        });

        setLoading(false);
      },
      (err: any) => {
        const errorMessage =
          err?.response?.data?.errors?.OperationCanceledException;

        dispatch(dispatchError(err.response.data));
        dispatch({
          type: "Navbar",
          payload: {
            title: `Buyer Quote Summary - ${
              !isNil(state?.selectedBuyerQuote?.createdByName) &&
              isString(state?.selectedBuyerQuote?.createdByName) ? (
                state?.selectedBuyerQuote?.createdByName
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ minWidth: "5rem", fontSize: "1rem" }}
                />
              )
            }`,
          },
        });
      }
    );
  };

  const handleDeleteRow = (id: string, quoteOrderNumber: any) => {
    deleteQuote(
      id,
      (res: any) => {
        const updatedQuoteList = state.selectedBuyerQuote.quotes.filter(
          (obj: { quoteId: string }) => obj.quoteId !== id
        );
        dispatch({
          type: "SelectedBuyerQuote",
          payload: { ...state.selectedBuyerQuote, quotes: updatedQuoteList },
        });
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `Quote ${quoteOrderNumber} has been deleted.`,
            severity: "success",
          },
        });
      },
      (err: any) => {
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: err.response.data.Message,
            severity: "error",
          },
        });
      }
    );

    //snackbar of quote number
  };

  const [filt, setFilt] = useState({
    items: [],
    quickFilterValues: [""],
  } as any);

  useEffect(() => {
    GetIntentionAccess && fetchQuote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GetIntentionAccess, quoteBuyerId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchQuote();
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchOptions.search, searchOptions.community]);

  const columns: GridColDef[] = [
    {
      field: "quoteNumber",
      headerName: "Quote Number",
      width: 400,
      renderCell: (params) =>
        params?.row.status === "Submitted" ? (
          <RLink
            to={{
              pathname: "/view",
              search: `?quoteId=${params.row.quoteId}`,
            }}
          >
            {params.row.quoteNumber}
          </RLink>
        ) : (
          <RLink
            to={{
              pathname: "/quote",
              search: `?quoteId=${params.row.quoteId}`,
            }}
          >
            {params.row.quoteNumber}
          </RLink>
        ),
    },
    {
      field: "quoteKeyword",
      headerName: "Keyword",
      flex: 1,
      width: 140,
      renderCell: (params) => (
        <ValidatedInput
          className="grid-keyword"
          editTitle="Edit Quote keyword"
          inputVariant="standard"
          mode="editor"
          disabled={!UpdateQuoteAccess}
          onChange={(newValue) =>
            handleUpdateKeyword(params.row.quoteId, newValue)
          }
          type="text"
          value={params.value}
          verify={false}
          saveTitle="Save this keyword"
        />
      ),
    },
    {
      field: "communityName",
      headerName: "Community",
      width: 200,
    },
    {
      field: "planNumberAndElevation",
      headerName: "Plan/Elevation",
      width: 200,
    },
    {
      field: "address",
      headerName: "Address",
      width: 250,

      align: "left",
      headerAlign: "left",
    },
    {
      field: "quoteStatus",
      headerName: "Status",
      width: 100,

      renderCell: (params) =>
        params.value && (
          <Chip
            icon={
              params.value === "Open" ? (
                <HourglassTop />
              ) : params.value === "Converted" ? (
                <PublishedWithChanges />
              ) : params.value === "Quoted" ? (
                <RequestQuoteOutlined />
              ) : (
                <></>
              )
            }
            sx={{
              textTransform: "capitalize",
            }}
            label={params.value ?? ""}
            variant="outlined"
          />
        ),
    },
    {
      field: "salesforceStage",
      headerName: "SF Stage",
      width: 100,
      align: "right",
      headerAlign: "right",

      // valueFormatter: (params) =>
      //   `$ ${numberWithCommas(faker.number.int({ min: 200000, max: 600000 }))}`,
    },
    {
      field: "notes",
      headerName: "Notes",
      width: 25,

      renderCell: (params) => (
        <Tooltip title="View Notes">
          <Notes disable={true} title={"Quote Notes"} rows={params.row} />
        </Tooltip>
      ),
    },
    {
      field: "quotedPrice",
      headerName: "Quote Price",
      width: 200,

      align: "right",
      headerAlign: "right",
      renderCell: (params: any) =>
        `$ ${numberWithCommas(params.row.quotedPrice)}`,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      minWidth: 175,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      hideable: true,
      pinnable: false,
      editable: false,
      align: "right",
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={0.5} mr={2}>
            {
              <>
                {
                  <Tooltip title="Edit">
                    <IconButton
                      onClick={() =>
                        navigate({
                          pathname: "/quote",
                          search: `?quoteId=${params.row.quoteId}`,
                        })
                      }
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                }
                {
                  <Tooltip title="Delete">
                    <IconButton
                      disabled={!DeleteQuoteAccess}
                      onClick={() =>
                        setConfirmDelete({
                          status: true,
                          quoteNumber: params?.row?.quoteNumber,
                          id: params?.row?.quoteId,
                        })
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                }

                <Tooltip title="Download">
                  <IconButton
                    disabled={downloading.loading}
                    onClick={() => handleDownloadItems(params.row)}
                  >
                    {downloading.id !== params.row.quoteId ? (
                      <Download />
                    ) : (
                      <CircularProgress size={"1.5rem"} />
                    )}
                  </IconButton>
                </Tooltip>
              </>
            }
          </Stack>
        );
      },
    },
  ];

  const handleDownloadItems = (quoteInfo: any) => {
    setDownloading({
      id: quoteInfo.quoteId,
      loading: true,
    });
    downloadQuotePDF(
      { quoteId: quoteInfo.quoteId, useTemplateForPrinting: false },
      async (res: {
        fileData: "string";
        contentType: "string";
        documentName: "string";
      }) => {
        function base64ToBlob(base64: string, contentType: string = ""): Blob {
          // Convert Base64 to a byte array
          const byteCharacters = atob(base64);
          const byteArrays = [];

          for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }

          // Create a blob from the byte array
          return new Blob(byteArrays, { type: contentType });
        }

        function downloadPDF(
          blobName: string,
          contentType: string,
          fileData: string
        ) {
          // Convert Base64 fileData to Blob
          const blob = base64ToBlob(fileData, contentType);

          // Create a Blob URL
          const blobUrl = window.URL.createObjectURL(blob);

          // Create a link element
          const link = document.createElement("a");

          // Set the download attribute with a filename
          link.download = blobName;

          // Set the href to the blob URL
          link.href = blobUrl;

          // Append the link to the document body
          document.body.appendChild(link);

          // Programmatically click the link to trigger the download
          link.click();

          // Clean-up: remove the link from the document
          document.body.removeChild(link);
        }

        downloadPDF(res.documentName, res.contentType, res.fileData);
        setDownloading({
          id: "",
          loading: false,
        });
      },
      (err: any) => {
        dispatch(dispatchError(err.response.data));
        setDownloading({
          id: "",
          loading: false,
        });
      }
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Stack
          flexDirection={"row"}
          gap={1}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Stack
            alignItems={"center"}
            justifyContent={"center"}
            alignContent={"center"}
            mb={2}
            mt={2}
            flexDirection={"row"}
            sx={{
              alignItems: "center",
              border: 1,
              borderColor: (mainTheme) => mainTheme.palette.grey[300],
              borderTop: 4,
              borderRadius: "10px",
              width: "100%",
              borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
              justifyContent: { xs: "center", md: "space-between" },
              flexDirection: { xs: "column", md: "row" },
              maxWidth: { xs: "100%", lg: "50%" },
              py: 0.5,
              px: 1,
            }}
          >
            <div className="summary-details">
              <p style={{ margin: "0 auto .5rem" }}>
                <strong>Buyer:</strong>
              </p>
              <p className="summary-details--info">
                {state?.selectedBuyerQuote?.sfAccountId ? (
                  <CheckCircle className="summary-icon success" />
                ) : (
                  <span title="Click Edit to assign buyer from Salesforce in order to Submit">
                    <Info className="summary-icon info" />
                  </span>
                )}
                <span
                  className={`${
                    state?.selectedBuyerQuote?.buyerName
                      ? "summary-buyer--legal"
                      : ""
                  }`}
                  title={state?.selectedBuyerQuote?.buyerName}
                >
                  {!isNil(state?.selectedBuyerQuote?.buyerName) &&
                  isString(state?.selectedBuyerQuote?.buyerName) ? (
                    state?.selectedBuyerQuote?.buyerName
                  ) : (
                    <Skeleton
                      variant="text"
                      sx={{ minWidth: "5rem", fontSize: "1rem" }}
                    />
                  )}
                </span>
              </p>
            </div>
            <div className="summary-details">
              <p className="summary-details--title">Phone:</p>
              <p className="summary-details--info">
                {state?.selectedBuyerQuote?.buyerPhone}
              </p>
            </div>
            <div className="summary-details">
              <p className="summary-details--title">Email:</p>
              <p className="summary-details--info">
                {state?.selectedBuyerQuote?.buyerEmail}
              </p>
            </div>
            {!state?.selectedBuyerQuote?.sfAccountId && (
              <EditQuoteBuyer fetchQuote={fetchQuote} />
            )}{" "}
          </Stack>
          <Box
            sx={{
              minWidth: 600,
              flexDirection: { xs: "column", md: "row" },
              display: "flex",
            }}
            gap={2}
            display={"flex"}
            alignItems={"center"}
          >
            <TextField
              fullWidth
              size="medium"
              sx={{
                minWidth: 150,
                maxWidth: 300,
              }}
              label="Search"
              variant="outlined"
              onChange={(e: any) => (
                setSearchOptions({
                  ...searchOptions,
                  search: e.target.value,
                }),
                setPaginationModel({
                  ...paginationModel,
                  page: 0,
                })
              )}
              value={searchOptions.search}
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined color="info" />
                  </InputAdornment>
                ),
              }}
            />
            <Autocomplete
              multiple={false}
              fullWidth
              size="medium"
              value={searchOptions.community || null}
              onChange={(e, value, reason) => {
                if (reason === "clear") {
                  setSearchOptions({
                    ...searchOptions,
                    community: {
                      projectNumber: "",
                      projectId: "",
                      name: "",
                    },
                  });
                } else if (value) {
                  setPaginationModel({
                    ...paginationModel,
                    page: 0,
                  });
                  setSearchOptions({
                    ...searchOptions,
                    community: value,
                  });
                }
              }}
              options={state.communities as CommunityTypes[]}
              getOptionLabel={(option) =>
                option.projectNumber
                  ? `${option.name} (${option.projectNumber})`
                  : option.name
              }
              filterOptions={filterOptions}
              renderOption={(props, item) => (
                <li {...props} key={item.projectId}>
                  <ListItemText>{`${item.name} (${item.projectNumber})`}</ListItemText>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Community"
                  fullWidth
                  sx={{
                    minWidth: 150,
                    maxWidth: 300,
                  }}
                />
              )}
            />
          </Box>
          <Box>
            <CreateQuoteWithBuyer />
          </Box>
        </Stack>

        <DeleteConfirmation
          setIsOpen={() =>
            setConfirmDelete({ status: false, quoteNumber: "", id: "" })
          }
          deleteMessage={`Delete the quote: ${confirmDelete.quoteNumber} ?`}
          deleteTitle={"Delete Quote"}
          onClose={() =>
            setConfirmDelete({ status: false, quoteNumber: "", id: "" })
          }
          isOpen={confirmDelete.status}
          confirmDelete={() => {
            handleDeleteRow(confirmDelete.id, confirmDelete.quoteNumber);
          }}
        />
        <>
          <DataGridPro
            paginationMode="server"
            pagination
            hideFooter
            scrollbarSize={10}
            sx={{
              marginTop: (theme) => theme.spacing(2),
              height: "100%",
              minHeight: "20rem",
              "& .MuiDataGrid-virtualScroller": {
                transform: "rotateX(180deg)",
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                transform: "rotateX(180deg)",
              },
              "& .css-1iledgx-MuiFormControl-root": {
                marginBottom: "0px",
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              sorting: {
                sortModel: [{ field: "changeOrderNumber", sort: "asc" }],
              },
            }}
            getRowId={(row) => row.quoteId}
            disableRowSelectionOnClick
            filterModel={filt as GridFilterModel | undefined}
            onFilterModelChange={(newFilterModel) =>
              setFilt(newFilterModel as any)
            }
            columns={columns}
            rows={state.selectedBuyerQuote.quotes ?? []}
            // rowCount={state.selectedBuyerQuote.intentions.length ?? 0}
            rowCount={0}
            slots={{
              loadingOverlay: LoadingGridOverlay,
            }}
            loading={loading}
          />
          <TablePagination
            component="div"
            // count={state.selectedBuyerQuote.intentions.length}
            count={0}
            page={paginationModel.page}
            nextIconButtonProps={{
              disabled: paginationModel.pageSize > 0 ? true : false,
            }}
            onPageChange={handleChangePage}
            rowsPerPage={paginationModel.pageSize}
            onRowsPerPageChange={handleChangePageSize}
          />
        </>

        <Box
          sx={{
            mt: (theme) => theme.spacing(2),
            backgroundColor: "white",
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/");
            }}
          >
            Back
          </Button>{" "}
        </Box>
        <Copyright />
      </Container>
    </ThemeProvider>
  );
}
