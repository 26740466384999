import { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import {
  DataGridPro,
  GridActionsCellItem,
  useGridApiRef,
  GridEventListener,
} from "@mui/x-data-grid-pro";
import { MoveToInbox, Check, DeleteOutline } from "@mui/icons-material";
import ReusableDialog from "../../components/Dialog";
import Loading from "../../components/Loading";
import {
  HighlandCategoryOption,
  UIState,
  UIContext,
  TemplateType,
} from "../../providers/UIProvider";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "../../styles/MainTheme";
import { loadTemplate } from "../../apiCalls";
import ConfirmDeleteTemplate from "../ConfirmDeleteTemplate";

export interface LoadTemplateProps {
  mode: "quote" | "edit" | "view"
};

const LoadTemplate = (props:LoadTemplateProps) => {
  const [open, setOpen] = useState(false);
  const [templatetoDelete, setTemplateToDelete] = useState<TemplateType | null>(
    null
  );
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType | null>(
    null
  );
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridRows, setGridRows] = useState<any[]>([]);
  const apiRefGrid = useGridApiRef();
  const [state, dispatch] = useContext<UIState | any>(UIContext);

  useEffect(() => {
    const newRows = buildRows();
    setGridRows(newRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.templates, selectedTemplate]);

  useEffect(() => {
    setGridColumns(buildColumns());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridRows]);

  const buildRows = () => {
    return state.templates.map((template: TemplateType) => {
      return {
        isSelected:
          selectedTemplate !== null && selectedTemplate.id === template.id,
        ...template,
      };
    });
  };

  const buildColumns = () => {
    return [
      {
        disableColumnMenu: true,
        editable: false,
        headerName: "",
        field: "isSelected",
        flex: 1,
        maxWidth: 50,
        width: 32,
        renderCell: (params: any) => {
          return !!params.value ? (
            <ThemeProvider theme={mainTheme}>
              <Check style={{ color: mainTheme.palette.primary.main }} />
            </ThemeProvider>
          ) : (
            ""
          );
        },
      },
      {
        flex: 3,
        field: "name",
        headerName: "Name",
        editable: false,
        type: "string",
      },
      {
        flex: 3,
        field: "createdBy",
        headerName: "Created By",
        editable: false,
        type: "string",
      },
      {
        flex: 3,
        field: "createdAt",
        headerName: "Created On",
        editable: false,
        type: "date",
      },
      {
        flex: 1,
        minWidth: 50,
        field: "actions",
        type: "actions",
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        hideable: true,
        pinnable: false,
        editable: false,
        headerName: "Delete",
        cellClassName: "actions",
        getActions: ({ id, row }: any) => {
          const rowActions = [
            <Tooltip title={"Delete"}>
              <GridActionsCellItem
                color="inherit"
                icon={<DeleteOutline />}
                id={`${id}_delete`}
                label="Delete"
                onClick={handleDeleteClick(id, row)}
                sx={{ color: "primary.main" }}
                tabIndex={0}
              />
            </Tooltip>,
          ];
          return rowActions;
        },
      },
    ];
  };

  const handleCellClick: GridEventListener<"cellClick"> = (
    params,
    event,
    details
  ) => {
    event.defaultMuiPrevented = true;
    if (params.field === "name" || params.field === "isSelected" || params.field === "createdBy" || params.field === "createdAt") {
      const { id, name, createdAt, createdBy } = params.row;
      setSelectedTemplate({ id, name, createdAt, createdBy });
    }
  };

  const handleDeleteClick = (id: string, row: any) => () => {
    setTemplateToDelete({
      id: row.id,
      name: row.name,
      createdAt: row.createdAt,
      createdBy: row.createBy,
    });
  };

  const showDeleteTemplateModal = () => {
    return (
      <ConfirmDeleteTemplate
        isOpen={true}
        templateId={templatetoDelete?.id || ""}
        templateName={templatetoDelete?.name || ""}
        onClose={() => setTemplateToDelete(null)}
      />
    );
  };

  const loadSelectedTemplate = () => {

    loadTemplate(
      selectedTemplate?.id || "",
      props.mode === "quote" ? state.quote.address.planElevationId : state.changeOrder.address.planElevationId,
      (res: any) => {
        let currentSort: number;
        let currentHighlandCategory: string | null = null;
        const pendingItems = res.data
          .sort((objA: any, objB: any) => {
            // which highlandCategory this item belongs to?
            const hcA = state.highlandCategories.find(
              (hc: HighlandCategoryOption) => hc.key === objA.highlandCategory
            );
            const hcB = state.highlandCategories.find(
              (hc: HighlandCategoryOption) => hc.key === objB.highlandCategory
            );
            if (hcA.sort !== hcB.sort) return hcA.sort - hcB.sort;
            if (objA.sortOrder !== objB.sortOrder)
              return objA.sortOrder - objB.sortOrder;
            if (objA.category !== objB.category)
              return objA.category.localeCompare(objB.category);
            return objA.description.localeCompare(objB.description);
          })
          .map((item: any, itemIx: number) => {
            const newItem = { ...item };
            newItem.forcedOption = false;
            newItem.highlandCategory = state.highlandCategories.find(
              (category: HighlandCategoryOption) =>
                category.key === item.highlandCategory
            );
            newItem.id = Math.random().toString(16).slice(2);
            newItem.isNew = true;
            newItem.isRequired = false;
            newItem.manualPrice = item.manualPrice || false;
            newItem.option = item.category;
            currentSort =
              itemIx === 0 || item.highlandCategory !== currentHighlandCategory
                ? 1
                : currentSort + 1;
            newItem.sortOrder = currentSort;
            currentHighlandCategory = item.highlandCategory;
            return newItem;
          });

        // Building used HighlandCategories out of data:
        const pendingCategories: HighlandCategoryOption[] = [];
        if (pendingItems) {
          res.data.forEach((item: any) => {
            if (
              item.highlandCategory !== null &&
              !pendingCategories.some(
                (category: HighlandCategoryOption) =>
                  category.key === item.highlandCategory
              )
            ) {
              pendingCategories.push(
                state.highlandCategories.find(
                  (category: HighlandCategoryOption) =>
                    category.key === item.highlandCategory
                )
              );
            }
          });
          pendingCategories
            .sort((a: HighlandCategoryOption, b: HighlandCategoryOption) => {
              if (a.sort < b.sort) return -1;
              if (a.sort > b.sort) return 1;
              return 0;
            })
            .forEach((hc: HighlandCategoryOption) => {
              dispatch({
                type: "AddPendingCategory",
                payload: hc,
              });
            });

          dispatch({
            type: "ChangeOrderPending",
            payload: pendingItems,
            source: "LoadTemplate",
          });
        }

        dispatch({
          type: "SetTemplate",
          payload: selectedTemplate,
        });
        setSelectedTemplate(null);
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  return (
    <>
      <ReusableDialog
        buttonVariant="outlined"
        buttonFullWidth={false}
        disabled={state.templates.length === 0}
        setIsOpen={(value) => setOpen(value)}
        icon={<MoveToInbox />}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent>
              <>
                <Box marginY={(theme) => theme.spacing(2)}>
                  {gridColumns && gridRows ? (
                    <DataGridPro
                      apiRef={apiRefGrid}
                      columnHeaderHeight={46}
                      columns={gridColumns}
                      disableMultipleRowSelection={true}
                      initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                      }}
                      onCellClick={handleCellClick}
                      pageSizeOptions={[5, 10, 20]}
                      pagination
                      rowCount={gridRows.length}
                      rowHeight={46}
                      rows={gridRows}
                    />
                  ) : (
                    <Loading />
                  )}
                </Box>
              </>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setSelectedTemplate(null);
                  setOpen(false);
                }}
                variant="outlined"
                color="primary"
              >
                Exit
              </Button>
              <Button
                onClick={() => {
                  loadSelectedTemplate();
                  setOpen(false);
                }}
                variant="contained"
                color="primary"
                disabled={selectedTemplate === null}
              >
                Load
              </Button>
            </DialogActions>
          </>
        }
        buttonText="Load Template"
        title="Load Template"
      />
      {templatetoDelete && showDeleteTemplateModal()}
    </>
  );
};

export default LoadTemplate;
