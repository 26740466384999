import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Print } from "@mui/icons-material/";
import { LoadingButton } from "@mui/lab";
import ReusableDialog from "../../components/Dialog";
import { useSearchParams } from "react-router-dom";
import { downloadStartMemoPDF } from "../../apiCalls";
import { Signatory, UIContext, UIState } from "../../providers/UIProvider";
import Loading from "../../components/Loading";
import useAccessControl from "../../hooks/useAccessControl";

type Props = {
  disabled: boolean;
  intentionId?: string;
  startMemoId: string;
  bypassValidation?: boolean;
  iconButton?: boolean;
  mode: "submit" | "printPreview";
  printFlow: (buyerCount: number, signatories?: Signatory[]) => void;
  title: string;
};

const StartMemoPDFPreview = ({
  disabled,
  mode,
  iconButton,
  printFlow,
  startMemoId,
  bypassValidation,
  title,
  intentionId: propsIntentionId,
}: Props) => {
  const [searchParams] = useSearchParams();
  const [state] = useContext<UIState | any>(UIContext);
  let intentionId = propsIntentionId || searchParams.get("intentionId");
  const [PDFUrl, setPDFUrl] = useState<string>("");
  const [open, setOpen] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const [buyerCount, setBuyerCount] = useState<number>(0);
  const [signatories, setSignatories] = useState<Signatory[]>([]);
  const [oldBuyerCount, setOldBuyerCount] = useState<number>(2);
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );

  useEffect(() => {
    if (
      bypassValidation ||
      (state.pendingItems.length > 0 &&
        ((state.changeOrder.lastSaved &&
          state.changeOrder.lastSaved !== lastSaved) ||
          oldBuyerCount !== buyerCount))
    ) {
      setIsDownloading(true);
      DownloadChangeOrderAccess &&
        downloadStartMemoPDF(
          {
            startMemoId: startMemoId as string,
          },
          async (res: {
            fileData: "string";
            contentType: "string";
            blobName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              blobName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);
              setPDFUrl(blobUrl);
            }

            downloadPDF(res.blobName, res.contentType, res.fileData);
            setIsDownloading(false);
            setIsSaving(false);
          },
          (err: any) => {
            setIsDownloading(false);
            setIsSaving(false);
          }
        );
      setLastSaved(state.changeOrder.lastSaved);
      setOldBuyerCount(buyerCount);
    }

    return () => {
      setPDFUrl("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.lastSaved, buyerCount]);

  useEffect(() => {
    if (state.changeOrder.customerBuyer) {
      setBuyerCount(
        state.changeOrder.customerBuyer.salesforceAccountId === "Spec" ? 0 : 2
      );
    }
  }, [state.changeOrder.customerBuyer]);

  useEffect(() => {
    if (state.isAdmin.userPrincipalName && state.changeOrder.customerBuyer) {
      const newSignatories: Signatory[] = [];
      const newSignatorie: Signatory = {
        name: state.isAdmin.name,
        email: state.isAdmin.userPrincipalName,
        nameError: false,
        emailError: false,
      };
      newSignatories.push(newSignatorie);
      for (let bc = 1; bc <= buyerCount; bc++) {
        let name = "";
        let names = [];
        if (state?.changeOrder?.customerBuyer?.contractBuyerNames) {
          names = state?.changeOrder?.customerBuyer?.contractBuyerNames.split(
            /\s*(?:&|\s+and\s+)\s*/
          );
        }
        if (bc === 1 && state.changeOrder.customerBuyer) {
          name = `${state.changeOrder.customerBuyer.buyerFirstName} ${state.changeOrder.customerBuyer.buyerLastName}`;
        }
        if (bc > 1 && bc <= names.length) {
          name = names[bc - 1];
        }
        const newSignatorie: Signatory = {
          name,
          email: "",
          nameError: false,
          emailError: false,
        };
        newSignatories.push(newSignatorie);
      }
      setSignatories(newSignatories);
    }
  }, [state.isAdmin, state.changeOrder.customerBuyer, buyerCount]);

  const updateSignerName = (newName: string, signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].name = newName;
    setSignatories(newSignatories);
  };

  const updateSignerEmail = (newEmail: string, signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].email = newEmail;
    setSignatories(newSignatories);
  };

  const validateSignerName = (signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].nameError = false;
    if (!(newSignatories[signerIx].name.trim().length > 0)) {
      newSignatories[signerIx].nameError = "Please provide a valid name";
    }
    setSignatories(newSignatories);
  };

  const validateSignerEmail = (signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].emailError = false;
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signatories[signerIx].email)) {
      newSignatories[signerIx].emailError =
        "Please provide a valid email address";
    }
    if (
      signerIx === 0 &&
      !signatories[0].email.endsWith("@highlandhomes.com")
    ) {
      newSignatories[signerIx].emailError =
        "Authorized signature must come from a valid Highland Homes email address";
    }
    setSignatories(newSignatories);
  };

  const submitToolTip = () => {
    if (!disabled) return undefined;
    if (disabled) {
      if (
        (!state.changeOrder.customerBuyer ||
          !state.changeOrder.customerBuyer.salesforceAccountId) &&
        !state.changeOrder.address?.jobID
      ) {
        return "Verify Buyer and Address";
      }
      if (
        !state.changeOrder.customerBuyer ||
        !state.changeOrder.customerBuyer.salesforceAccountId
      )
        return "Verify Buyer";
      if (!state.changeOrder.address?.jobID) return "Verify Address";
    }
    return undefined;
  };

  const renderEmailFields = () => {
    return signatories.map((signer: Signatory, signerIx: number) => (
      <Box
        component="fieldset"
        key={`signer-box-${signerIx}`}
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: 2,
          mt: 2,
          px: 2,
          py: 1,
          position: "relative",
        }}
      >
        <Box component="legend" sx={{ px: 1, fontSize: "0.75rem" }}>
          {signerIx === 0 ? "Authorizing" : `Buyer ${signerIx}:`}
        </Box>
        <FormControl fullWidth>
          <TextField
            error={signer.nameError !== false}
            label={
              signerIx === 0 ? "Authorizing name" : `Buyer ${signerIx} name:`
            }
            helperText={signer.nameError}
            id={`buyer-name-${signerIx}`}
            fullWidth
            value={signer.name}
            onBlur={() => validateSignerName(signerIx)}
            onChange={(event: any) =>
              updateSignerName(event.target.value, signerIx)
            }
            size="small"
            sx={{ backgroundColor: "white" }}
            type="email"
            required
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1.5 }}>
          <TextField
            error={signer.emailError !== false}
            label={
              signerIx === 0 ? "Authorizing email" : `Buyer ${signerIx} email:`
            }
            helperText={signer.emailError}
            id={`buyer-email-${signerIx}`}
            fullWidth
            value={signer.email}
            onBlur={() => validateSignerEmail(signerIx)}
            onChange={(event: any) =>
              updateSignerEmail(event.target.value, signerIx)
            }
            size="small"
            sx={{ backgroundColor: "white" }}
            type="email"
            required
          />
        </FormControl>
      </Box>
    ));
  };

  const submitFooter = () => {
    return (
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          padding: "10px 24px",
        }}
      >
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => setOpen(false)}
            variant="outlined"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            disabled={
              isSaving ||
              (mode === "submit" &&
                (signatories.some(
                  (signer: Signatory) =>
                    signer.name.trim() === "" || signer.email.trim() === ""
                ) ||
                  signatories.some(
                    (signer: Signatory) => signer.emailError || signer.nameError
                  )))
            }
            loading={isSaving}
            onClick={() => {
              setOpen(false);
              setIsSaving(true);
              printFlow(buyerCount, signatories);
            }}
            variant="contained"
            color="primary"
          >
            Confirm
          </LoadingButton>
        </Stack>
      </DialogActions>
    );
  };

  const printPreviewFooter = () => {
    return (
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "10px 24px",
        }}
      >
        <Button
          onClick={() => setOpen(false)}
          variant="outlined"
          color="primary"
        >
          Cancel
        </Button>
        <Stack direction="row" spacing={2}>
          <Button
            onClick={() => {
              setOpen(false);
              printFlow(0);
            }}
            variant="contained"
            color="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => {
              const previewIframe = document.getElementById(
                "pdfPreview"
              ) as HTMLIFrameElement;
              if (previewIframe) previewIframe.contentWindow?.print();
            }}
            variant="contained"
            color="primary"
          >
            Print
          </Button>
        </Stack>
      </DialogActions>
    );
  };

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        type={iconButton ? "icon" : ""}
        maxWidth={mode === "submit" ? "lg" : "md"}
        content={
          isDownloading ? (
            <Loading />
          ) : (
            <>
              <DialogContent sx={{ p: 0 }}>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  spacing={3}
                  useFlexGap
                >
                  <Box
                    component="iframe"
                    id="pdfPreview"
                    sx={{
                      height: 600,
                      width: "100%",
                      mb: 2,
                      ml: 3,
                      mt: 2,
                      maxHeight: { xs: 400, md: 700 },
                      maxWidth: { xs: 500, md: 852 },
                    }}
                    src={PDFUrl}
                  />
                  {mode === "submit" && (
                    <Stack
                      sx={{
                        backgroundColor: "rgba(0,0,0,.04)",
                        border: "1px solid #E0E0E0",
                        overflowY: "auto",
                        maxHeight: "600px",
                        mt: 2,
                        mr: 3,
                        ml: 0,
                        mb: 2,
                        px: 3,
                        pt: 2,
                      }}
                    >
                      <h4 style={{ margin: 0 }}>
                        {buyerCount === 0
                          ? "Confirm the authorized signature details"
                          : "Select the number of buyer signatures required"}
                      </h4>
                      {buyerCount > 0 && (
                        <FormControl sx={{ mt: 1, width: "284px" }}>
                          <InputLabel id="buyer-signatures-label">
                            Number of Buyer signatures
                          </InputLabel>
                          <Select
                            label="Number of Buyer signatures"
                            labelId="subyer-signatures-label"
                            id="buyer-signatures"
                            value={buyerCount}
                            onChange={(event: any) =>
                              setBuyerCount(parseInt(event.target.value))
                            }
                            size="small"
                            sx={{
                              backgroundColor: "white",
                              textAlign: "right",
                              width: "185px",
                            }}
                          >
                            {[1, 2, 3, 4].map((bc: number) => (
                              <MenuItem key={`mi-${bc}`} value={bc}>
                                {bc}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                      {renderEmailFields()}
                    </Stack>
                  )}
                </Stack>
              </DialogContent>
              {mode === "submit" && submitFooter()}
              {mode === "printPreview" && printPreviewFooter()}
            </>
          )
        }
        buttonVariant="contained"
        buttonText={mode === "submit" ? "Submit" : ""}
        disabled={disabled}
        icon={mode === "printPreview" ? <Print /> : null}
        title={title}
        toolTipTitle={submitToolTip()}
        buttonColor="primary"
      />
    </>
  );
};

export default StartMemoPDFPreview;
