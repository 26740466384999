import { useContext, useState } from "react";
import { UIContext, UIState } from "../../providers/UIProvider";
import ReusableDialog from "../../components/Dialog";
import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import { Stack } from "@mui/system";

import { AddNonSignerNotifications } from "../../apiCalls";
import EmailCCInput from "../../components/EmailCCInput";
import { dispatchError } from "../../common/fx";

const CCDocuSign = ({ intentionId }: { intentionId: string | null }) => {
  const [open, setOpen] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);
  const [state, dispatch] = useContext<UIState | any>(UIContext);

  const handleConfirm = (emails: string[]) => {
    AddNonSignerNotifications(
      {
        intentionId: intentionId ?? "",
        emails: emails,
      },
      () => (
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `CC Emails Have Been Scheduled to Send`,
            severity: "success",
          },
        }),
        setOpen(false),
        setEmails([])
      ),
      (err: any) => dispatch(dispatchError(err.response.data))
    );
    setOpen(false);
  };

  return (
    <ReusableDialog
      setIsOpen={setOpen}
      isOpen={open}
      maxWidth={"md"}
      buttonText={"Share Document"}
      title={`Share Document with Non-Signers
      `}
      buttonVariant="contained"
      content={
        <>
          <DialogContent>
            <Box padding={(theme) => theme.spacing(2)}>
              <EmailCCInput emails={emails} setEmails={setEmails} />
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={(e) => {
                setOpen(false);
              }}
              variant="outlined"
              color="primary"
            >
              Cancel
            </Button>
            <Stack
              flexDirection={"row"}
              gap={2}
              justifyContent={"space-between"}
            >
              <Button
                disabled={emails.length === 0}
                onClick={() => {
                  handleConfirm(emails);
                  setOpen(false);
                }}
                variant="contained"
                color="primary"
              >
                Share
              </Button>
            </Stack>
          </DialogActions>
        </>
      }
    />
  );
};

export default CCDocuSign;
