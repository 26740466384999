import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";

interface EmailAutoCompleteProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
}

const EmailCCInput: React.FC<EmailAutoCompleteProps> = ({
  emails,
  setEmails,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      event.preventDefault();

      if (!isValidEmail(inputValue.trim())) {
        setError(true);
        return;
      }

      setEmails([...emails, inputValue.trim()]);
      setInputValue("");
      setError(false);
    }
  };

  const handleDelete = (emailToDelete: string) => {
    setEmails(emails.filter((email) => email !== emailToDelete));
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={emails}
      onChange={(_, newValue) => {
        setEmails(newValue.filter((email) => isValidEmail(email)));
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="outlined"
            label={option}
            {...getTagProps({ index })}
            onDelete={() => handleDelete(option)}
          />
        ))
      }
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
        setError(false);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Enter Recipient Emails"
          onKeyDown={handleKeyDown}
          error={error}
          helperText={
            error ? "Please enter a valid email (e.g., user@example.com)" : ""
          }
        />
      )}
    />
  );
};

export default EmailCCInput;
