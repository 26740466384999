import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const previousPath = sessionStorage.getItem("currentPath");

    if (currentPath !== previousPath) {
      sessionStorage.setItem("previousPath", previousPath || "");
      sessionStorage.setItem("currentPath", currentPath);
    }
  }, [location]);

  return null;
};

export default RouteTracker;
