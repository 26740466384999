export const filterOptions = (
  options: any[],
  state: any,
  keys: string[] = ["name"]
) => {
  // Escape special regex characters in the input value and convert to lower case
  const inputValue = state.inputValue
    .toLowerCase()
    .replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
  const regex = new RegExp(`\\b${inputValue}`, "i");

  return options.filter((option) =>
    keys.some((key) => {
      const value = option[key];
      return value && regex.test(value.toString().toLowerCase());
    })
  );
};
