import * as React from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridSearchIcon,
} from "@mui/x-data-grid-pro";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  ListItemText,
  Paper,
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { Link as RLink } from "react-router-dom";
import { numberWithCommas } from "../../utils/formatMoney";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  AssignmentLateOutlined,
  CheckCircle,
  CheckCircleOutline,
  Error,
  ErrorOutline,
  PendingActionsOutlined,
  PendingOutlined,
  PreviewOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import CircularProgressWithLabel from "../../components/CircularProgressLabel";
import { debounce, isEmpty, uniqueId } from "lodash";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CommunityTypes, UIContext, UIState } from "../../providers/UIProvider";
import { filterOptions } from "../../utils/filterOptions";
import { Container } from "@mui/system";
import {
  downloadChangeOrderPDF,
  downloadStartMemoPDF,
  listDocumentSummary,
  listStartMemoSummary,
} from "../../apiCalls";
import { dispatchError } from "../../common/fx";
import COPDFPreview from "../../modals/COPDFPreview";
import CreateStartMemo from "../../modals/CreateStartMemo";
import StartMemoPDFPreview from "../../modals/StartMemoPDFPreview";

interface DocumentSummary {
  id: string;
  address: string;
  communityName: string;
  buyerName: string;
  buyerLegalName: string;
  planNumber: string;
  planElevationNumber: string;
  planName: string;
  jobKey: string;
  stage: number;
  planId: number;
  planElevationId: number;
  StartMemos: StartMemos[];
}

interface StartMemos {
  changeOrderNumber: string;
  startMemoStatus: string;
  startMemoId: string;
  intentionId: string;
  createdBy: string;
  createdByName: string;
  createdAt: string;
  submittedBy: string;
  submittedByName: string;
  submittedAt: string;
  updatedAt: string;
  lastUpdatedBy: string;
  lastUpdatedByName: string;
  jobId: string;
  planId: number;
  planNumber: string;
  planElevationNumber: string;
  planElevationId: number;
  changeOrderPrice: number;
  currentApprovalStatus: string;
  StartMemoApprovers: {
    StartMemoApproverRole: string;
    StartMemoApproverName: string;
    StartMemoApproverEmail: string;
  }[];
}

interface StepperButtonGroupProps {
  params: {
    row: StartMemos;
  };
}

const columns: GridColDef[] = [
  {
    field: "jobKey",
    headerName: "Job Number",
    flex: 1,
    width: 150,
  },

  {
    field: "communityName",
    headerName: "Community",
    flex: 1,
    width: 200,
  },
  {
    field: "address",
    headerName: "Address",
    width: 300,
    renderCell: (params) => (
      <RLink
        to={{
          pathname: "/job-summary",
          search: `?addressid=${params.row.startMemos[0].intentionId}`,
        }}
      >
        {params.row.address}
      </RLink>
    ),
  },
  {
    field: "planNumber",
    headerName: "Plan / Elev",
    width: 150,
    renderCell: (params) =>
      `${params.row.planNumber}-${params.row.planElevationNumber}`,
  },
  {
    field: "stage",
    headerName: "Stage",
    flex: 1,
    width: 125,
    renderCell: (params) => params.row.stage,
  },
];

export default function MasterDetailDataGrid() {
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);
  const [pageSize, setPageSize] = React.useState(0);
  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);
  const [currentRoles, setCurrentRoles] = React.useState([]);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
    React.useState<GridRowId[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [page, setPage] = React.useState(0);
  const [communities, setCommunities] = useLocalStorage<any[]>(
    "communities",
    []
  );

  const [approved, setApproved] = React.useState(false);

  const debouncedSetSearch = React.useMemo(
    () =>
      debounce((nextValue) => {
        setSearch(nextValue);
        setPage(0);
      }, 300),
    []
  );

  const handleSearchChange = (event: any) => {
    debouncedSetSearch(event.target.value);
  };

  const StepIconComponent = (props: StepIconProps) => {
    const { active, completed, error } = props;

    return completed ? (
      <CheckCircle color="primary" />
    ) : error ? (
      <ErrorOutline color="error" />
    ) : active ? (
      <RadioButtonUnchecked color="primary" />
    ) : (
      <RadioButtonUnchecked color="disabled" />
    );
  };

  const renderStepperCell = (params: any) => (
    <StepperButtonGroup params={params} />
  );

  const StepperButtonGroup: React.FC<StepperButtonGroupProps> = ({
    params,
  }) => {
    const startMemoStatus = params?.row?.startMemoStatus;

    const stepOptions = [
      {
        label: "Awaiting CM",
        fullLabel: "Awaiting Construction Manager",
      },
      {
        label: "Awaiting DM",
        fullLabel: "Awaiting Division Sales Manager",
      },
      {
        label: "Fully-Approved",
        fullLabel: "Start Memo Fully-Approved",
      },
    ];

    const stepStatusMap: Record<string, string> = {
      AwaitingConstructionManager: "Awaiting Construction Manager",
      AwaitingDivisionSalesManager: "Awaiting Division Sales Manager",
      StartMemoFullyApproved: "Start Memo Fully-Approved",
    };

    const getStepIndex = (status: string) => {
      const mappedStep = stepStatusMap[status];
      return stepOptions.findIndex((step) => step.fullLabel === mappedStep);
    };

    const statusIndex = getStepIndex(startMemoStatus);
    const derivedSelectedSteps =
      statusIndex >= 0
        ? stepOptions.slice(0, statusIndex + 1).map((s) => s.fullLabel)
        : [];

    const [selectedSteps, setSelectedSteps] =
      React.useState<string[]>(derivedSelectedSteps);

    React.useEffect(() => {
      setSelectedSteps(derivedSelectedSteps);
    }, [startMemoStatus]);

    // Error condition – show error icon if status is unavailable
    if (startMemoStatus === "StatusUnavailable") {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight={80}
        >
          <Error color="error" fontSize="large" />
        </Box>
      );
    }

    return (
      <Stepper
        alternativeLabel
        sx={{
          "& .MuiStepLabel-root": {
            flexDirection: "column",
            alignItems: "center",
            gap: "0px",
          },
          "& .MuiStepLabel-iconContainer": {
            paddingRight: "0",
          },
          "& .MuiStepLabel-label": {
            marginTop: "0",
          },
          "& .MuiStep-root": {
            padding: "0 16px",
          },
        }}
      >
        {stepOptions.map((step) => {
          return (
            <Step
              key={step.fullLabel}
              completed={selectedSteps.includes(step.fullLabel)}
              active={selectedSteps.includes(step.fullLabel)}
            >
              <StepLabel StepIconComponent={StepIconComponent}>
                {step.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };

  const handleDownloadItems = (startMemoId: string) => {
    setDownloading(true);

    if (startMemoId) {
      downloadStartMemoPDF(
        { startMemoId: startMemoId },
        async (res: {
          fileData: string;
          contentType: string;
          documentName: string;
        }) => {
          function base64ToBlob(
            base64: string,
            contentType: string = ""
          ): Blob {
            // Convert Base64 to a byte array
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 512
            ) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);

              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            // Create a blob from the byte array
            return new Blob(byteArrays, { type: contentType });
          }

          function downloadPDF(
            documentName: string,
            contentType: string,
            fileData: string
          ) {
            // Convert Base64 fileData to Blob
            const blob = base64ToBlob(fileData, contentType);

            // Create a Blob URL
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set the download attribute with a filename
            link.download = documentName;

            // Set the href to the blob URL
            link.href = blobUrl;

            // Append the link to the document body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean-up: remove the link from the document
            document.body.removeChild(link);
          }

          downloadPDF(res.documentName, res.contentType, res.fileData);
          setDownloading(false);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
          setDownloading(false);
        }
      );
    }
  };

  function DetailPanelContent({ row }: { row: any }) {
    const [startMemoPreview, setStartMemoPreview] = React.useState({
      intentionId: "",
      open: false,
    });

    const jobSummaryColumns: GridColDef[] = [
      {
        field: "changeOrderNumber",
        headerName: "CO Number",
        width: 200,
        renderCell: (params) => (
          <RLink
            to={{
              pathname: "/view",
              search: `?intentionId=${params.row.intentionId}`,
            }}
          >
            {params.row.changeOrderNumber}
          </RLink>
        ),
      },
      {
        field: "createdByName",
        headerName: "Submitted By",
        width: 200,
        renderCell: (params) => (
          <Box
            sx={{
              textTransform: "capitalize",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            {params.value}
          </Box>
        ),
      },
      {
        field: "submittedAt",
        headerName: "Submitted On",
        width: 150,
        valueFormatter: (params) =>
          `${moment(params.value).format("MM-DD-YYYY")}`,
        renderCell: (params) => (
          <Tooltip
            placement="right-start"
            title={`Created On ${moment(params.row.createdAt).format(
              "MM-DD-YYYY"
            )} by  ${params.row.createdByName} `}
          >
            <span className="table-cell-trucate">
              {moment(params.row.createdAt).format("MM-DD-YYYY")}
            </span>
          </Tooltip>
        ),
      },

      {
        field: "currentApprovalStatus",
        headerName: "Ops Review Status",
        width: 300,
        renderCell: (params) =>
          params.row.currentApprovalStatus && (
            <Chip
              icon={
                params.row.currentApprovalStatus === "Approved" ? (
                  <CheckCircleOutline color="success" />
                ) : params.row.currentApprovalStatus ===
                  "FurtherActionNeeded" ? (
                  <AssignmentLateOutlined color={"error"} />
                ) : (
                  <PendingActionsOutlined color="warning" />
                )
              }
              sx={{
                textTransform: "capitalize",
              }}
              label={params.value}
              variant="outlined"
            />
          ),
        // </Tooltip>
      },

      {
        field: "changeOrderPrice",
        headerName: "CO Price",
        width: 150,
        align: "right",
        headerAlign: "right",
        valueFormatter: (params) => `$ ${numberWithCommas(params.value ?? 0)}`,
      },
      {
        field: "actions",
        headerName: "",
        align: "center",
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        hideable: true,
        pinnable: false,
        editable: false,
        minWidth: 200,

        renderCell: (params) => {
          return (
            <Stack
              justifyContent={"center"}
              direction="row"
              spacing={0.5}
              mr={2}
            >
              <>
                <StartMemoPDFPreview
                  iconButton
                  bypassValidation
                  disabled={false}
                  startMemoId={params.row.startMemoId}
                  mode="printPreview"
                  printFlow={() => handleDownloadItems(params.row.startMemoId)}
                  title="Print Preview"
                />

                <CreateStartMemo
                  open={
                    startMemoPreview.open &&
                    startMemoPreview.intentionId === params.row.intentionId
                  }
                  setOpen={(value) =>
                    setStartMemoPreview({
                      intentionId: params.row.intentionId,
                      open: value,
                    })
                  }
                  type={"icon"}
                  icon={<PreviewOutlined />}
                  jobId={params.row.jobId}
                  intentionId={params.row.intentionId}
                  startMemoId={params.row.startMemoId}
                  title={`Update Start Memo - ${params.row.address} | ${params.row.jobKey} | ${params.row.planNumber}-${params.row.planElevationNumber}`}
                />
              </>
            </Stack>
          );
        },
      },
      {
        field: "approvalSteps",
        headerName: "",
        width: 480,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {renderStepperCell(params)}
          </Box>
        ),
      },
    ];

    return (
      <Box sx={{ px: 6, py: 2 }}>
        <Card
          sx={{
            width: "100%",
            minHeight: 100,
            backgroundColor: "#f4f4f4",
            padding: 2,
            borderRadius: 2,
            border: (theme) => `solid black 1px`,
          }}
        >
          <Typography variant="h6">{`Start Memos for Job #${row.jobKey}`}</Typography>

          {
            <Box sx={{ width: "100%", height: "100%" }}>
              <DataGridPro
                density="comfortable"
                columns={jobSummaryColumns}
                rows={row.startMemos}
                getRowId={(row) => row.intentionId}
                sx={{ height: "100%" }}
                hideFooter
              />
            </Box>
          }
        </Card>
      </Box>
    );
  }

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds
      );
    },
    []
  );
  const getDetailPanelContent = React.useCallback(
    (params: GridRowParams) => {
      if (isEmpty(currentRoles)) return null; // Prevents rendering until roles are set
      return <DetailPanelContent row={params.row} />;
    },
    [currentRoles] // Forces re-render when roles are updated
  );

  const getDetailPanelHeight = React.useCallback(
    (params: GridRowParams) =>
      params.row.startMemos.length === 1
        ? 250
        : params.row.startMemos.length * 180,
    []
  );

  React.useEffect(() => {
    const fetchDocumentSummary = (
      paginationModel: {
        page: number;
        pageSize: number;
      },
      setData: any
    ) => {
      listStartMemoSummary(
        {
          searchText: search,
          pagination: {
            pageNumber: page + 1,
            pageSize: pageSize,
          },
          showApproved: approved,
          projectIds: communities.map((obj: any) => String(obj.projectId)),
        },
        (res: any) => {
          return setData(res.data);
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
        }
      );
    };

    fetchDocumentSummary({ page: page, pageSize: pageSize }, setData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approved, search, page, pageSize, dispatch, communities]);

  React.useEffect(() => {
    dispatch({
      type: "Navbar",
      payload: { title: "Start Memos" },
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);

    if (!isEmpty(state.isAdmin.grantedRoles)) {
      setCurrentRoles(state.isAdmin.grantedRoles);
    }
  }, [state.isAdmin.grantedRoles]);

  React.useEffect(() => {
    if (!isEmpty(currentRoles)) {
      setLoading(false);
    }
  }, [currentRoles]);
  return (
    <Container component="main" maxWidth={false}>
      <Stack
        gap={2}
        mt={2}
        mb={2}
        sx={{
          alignItems: "center",
          flexDirection: { xs: "column", sm: "column", lg: "row" },
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Box gap={2} display={"flex"} alignItems={"center"}>
          <TextField
            sx={{
              minWidth: "20rem",
              width: "100%",
            }}
            fullWidth
            size="small"
            label="Search"
            variant="outlined"
            onChange={handleSearchChange}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon color="info" />
                </InputAdornment>
              ),
            }}
          />

          <Autocomplete
            sx={{
              minWidth: "20rem",
              width: "100%",
            }}
            fullWidth
            multiple
            disableCloseOnSelect
            size="small"
            onChange={async (events, value) => {
              setPage(0);
              setCommunities(value);
            }}
            options={state.communities as CommunityTypes[]}
            getOptionLabel={(option) =>
              `${option.name} (${option.projectNumber})`
            }
            value={communities}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Community"
                fullWidth
              />
            )}
            filterOptions={(options, state) =>
              filterOptions(options, state, ["name", "projectNumber"])
            }
            renderOption={(props, item) => (
              <li {...props} key={item.projectId}>
                <ListItemText>{`${item.name} (${item.projectNumber})`}</ListItemText>
              </li>
            )}
          />
          <FormGroup
            sx={{
              width: "100%",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={approved}
                  onChange={(e, checked) => setApproved(checked)}
                  inputProps={{ "aria-label": "Show Approved" }}
                />
              }
              label="Show Approved"
            />
          </FormGroup>
        </Box>
      </Stack>
      <Box sx={{ width: "100%", height: "100%" }}>
        {loading || isEmpty(currentRoles) ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <DataGridPro
            columns={columns}
            rows={data}
            getRowId={() => uniqueId()}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            onDetailPanelExpandedRowIdsChange={
              handleDetailPanelExpandedRowIdsChange
            }
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            sx={{
              "& .MuiDataGrid-row--detailPanelExpanded": {
                backgroundColor: "#e3f2fd !important",

                "&:hover": {
                  backgroundColor: "#bbdefb !important",
                },
              },
            }}
          />
        )}
      </Box>
    </Container>
  );
}
