import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import { numberWithCommas } from "../../utils/formatMoney";
import { Button, DialogContent, DialogActions } from "@mui/material";
import { Add, PriceChange, Remove } from "@mui/icons-material";
import { UpdateQuotePrices } from "../../apiCalls";
import ReusableDialog from "../../components/Dialog";
import { UIContext, UIState } from "../../providers/UIProvider";

interface OptionPricesToUpdateProps {
  title: string;
  onClose?: any;
};

const opuColumns = [
  { key: 'expander', label: '', class: 'center' },
  { key: 'highlandCategoryType', label: 'Highland Category' },
  { key: 'category', label: 'Option' },
  { key: 'optionCode', label: 'Option Code' },
  { key: 'description', label: 'Description' },
  { key: 'previousPrice', label: 'Current', class: 'right' },
  { key: 'updatedPrice', label: 'Updated', class: 'right' },
  { key: 'difference', label: 'Difference', class: 'right' },
  { key: 'priceChangeDate', label: 'Date updated', class: 'right' }
];

const OptionPricesToUpdate = (props:OptionPricesToUpdateProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [opuRows, setOpuRows] = useState<any[]>([]);
  const [openBlocks, setOpenBlocks] = useState<string[]>([]);

  useEffect(() => {
    if(state.quote.updatedPrices.length > 0) {
      
      const sortedUpdatedPrices = [...state.quote.updatedPrices];
      sortedUpdatedPrices.sort((a:any, b:any) => {
        if(a.highlandCategoryType !== b.highlandCategoryType) 
          return a.highlandCategoryType.localeCompare(b.highlandCategoryType);
        if(a.description !== b.description)
          return a.description.localeCompare(b.description);
        return b.priceChangeDate.localeCompare(a.priceChangeDate);
      });
      setOpuRows(sortedUpdatedPrices);
    }
  }, [state.quote.updatedPrices]);

  const renderTable = () => {
    return (
      <table className="price-updates--table">
        <thead>
          <tr>
            { opuColumns.map((column:any) => 
              <th id={`th-${column.key}`} key={`th-${column.key}`}>
                {column.label}
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          { 
            opuRows.map((option:any, oIx:number) => {
              if(oIx === 0 || (oIx > 0 && option.quoteOptionId !== opuRows[oIx - 1].quoteOptionId)) {
                if(oIx !== opuRows.length - 1 && option.quoteOptionId === opuRows[oIx + 1].quoteOptionId) {
                  //is a parent
                  const parentOption = { ...option };
                  const historyRows = opuRows.filter((row:any) => row.quoteOptionId === parentOption.quoteOptionId);
                  console.log('history', historyRows);
                  parentOption.previousPrice = historyRows[historyRows.length - 1].previousPrice;
                  parentOption.updatedPrice = historyRows[0].updatedPrice;
                  parentOption.difference = parentOption.updatedPrice - parentOption.previousPrice;
                  return (
                    <>
                      <tr className="parent" key={`row-${option.quoteOptionId}-parent`}>
                        { opuColumns.map((column:any) => renderCell(parentOption, column, "parent")) }
                      </tr>
                      { openBlocks.includes(option.quoteOptionId) && 
                        <tr className="child" key={`row-${option.quoteOptionId}-child-1`}>
                          { opuColumns.map((column:any) => renderCell(option, column, "child")) }
                        </tr>
                      }
                    </>);
                } else {
                  //is a single
                  return (
                    <tr className="single" key={`row-${option.quoteOptionId}`}>
                      { opuColumns.map((column:any) => renderCell(option, column, "single")) }
                    </tr>
                  );
                }
              } else {
                //is a kid
                if(openBlocks.includes(option.quoteOptionId)) {
                  return (
                    <tr
                      className={`child${oIx !== opuRows.length - 1 && option.quoteOptionId === opuRows[oIx + 1].quoteOptionId ? '' : ' last'}`}
                      key={`row-${option.quoteOptionId}-child-${oIx}`}
                    >
                      { opuColumns.map((column:any) => renderCell(option, column, "child")) }
                    </tr>
                  );
                }
              }
            })
          }
        </tbody>
      </table>
    )
  }

  const renderCell = (option:any, column:any, type: "parent" | "child" | "single") => {
    let cellValue;
    switch(column.key) {
      case 'expander':
        if(type === "parent")
          if(openBlocks.includes(option.quoteOptionId)) {
            cellValue = <div className="parent-toggle" onClick={() => handleRemoveBlock(option.quoteOptionId)}>
                          <Remove />
                        </div>;
          } else {
            cellValue = <div className="parent-toggle" onClick={() => handleAddBlock(option.quoteOptionId)}>
                          <Add />
                        </div>;
          }
        else if(type === "child")
          cellValue = <span className="child-branch"></span>
        else
          cellValue = '';
        break;
      case 'highlandCategoryType':
      case 'category':
      case 'optionCode':
      case 'description':
        cellValue = type !== "child" ? option[column.key] : '';
        break;
      case 'previousPrice':
      case 'updatedPrice':
      case 'difference':
        cellValue = `$ ${numberWithCommas(option[column.key])}`;
        break;
      case 'priceChangeDate':
        const updatedDate = new Date(option['priceChangeDate']);
        const month = String(updatedDate.getMonth() + 1).padStart(2, '0');
        const day = String(updatedDate.getDate()).padStart(2, '0');
        const year = updatedDate.getFullYear();
        cellValue = `${month}/${day}/${year}`;
        break;
      default:
        cellValue = option[column.key];
    }
    return (
      <td
        className={`${column.class ?? ''}`}
        key={`${option.quoteOptionId}-${column.key}`}
        id={`${option.quoteOptionId}-${column.key}`}
      >
        {cellValue}
      </td>
    );
  }

  const handleAddBlock = (optionId:string) => {
    if(openBlocks.includes(optionId)) return false;
    const newOpenBlocks = [...openBlocks];
    newOpenBlocks.push(optionId);
    setOpenBlocks(newOpenBlocks);
  }

  const handleRemoveBlock = (optionId:string) => {
    if(openBlocks.includes(optionId)) {
      const optionIx = openBlocks.indexOf(optionId);
      const newOpenBlocks = [...openBlocks];
      newOpenBlocks.splice(optionIx, 1);
      setOpenBlocks(newOpenBlocks);
    }
  }

  const handleClose = () => {
    setIsOpen(false);
    if(props.onClose) props.onClose();
  }

  const handleUpdatePrices = () => {
    UpdateQuotePrices(
      state.quote.id,
      (response:any) => {
        dispatch({
          type: "Quote",
          payload: {
            lastSaved: new Date(),
            updatedPrices: []
          },
          source: "Apply new prices",
        });
        handleClose();
      },
      (err:any) => {
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        );
      }
    )
  }

  return (
    <ReusableDialog
      buttonFullWidth={false}
      content={
        <>
          <DialogContent>
            <p style={{ color: "#e56d29" }}>
              {props.title}
            </p>
            { renderTable() }
          </DialogContent>
          <DialogActions>
            <Button
                onClick={() => {
                  handleClose();
                }}
                variant="outlined"
                color="primary"
              >
                Exit
            </Button>
            <Button
              onClick={() => {
                handleUpdatePrices();
              }}
              variant="contained"
            >
              Apply new prices
            </Button>
          </DialogActions>
        </>
      }
      icon={<PriceChange sx={{ color: "#e56d29", fontSize: "1.25rem" }} />}
      isOpen={isOpen}
      maxWidth="lg"
      setIsOpen={(value) => setIsOpen(value)}
      title="Updated Prices"
      toolTipTitle={`${props.title} Click here to know more.`}
      type="icon"
    />
  )
}

export default OptionPricesToUpdate;