import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  Theme,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { CheckCircle, Edit, MarkEmailRead } from '@mui/icons-material/';
import logo from "../../assets/images/logo-white.png";
import { Signatory, UIContext, UIState } from "../../providers/UIProvider";
import { dispatchError } from "../../common/fx";
import { updateSigningDoc } from "../../apiCalls";
import "./COSubmitSuccess.scss";

interface COSubmitSuccessProps {
  intentionId: string;
  docusignId: string;
  signers: Signatory[];
  onClose: any;
}

const COSubmitSuccess = ({
  intentionId,
  docusignId,
  signers,
  onClose,
}: COSubmitSuccessProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [signatories, setSignatories] = useState<Signatory[]>(signers);
  const [isEditing, setIsEditing] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleUpdateEmail = (signerIx:number) => {
    updateSigningDoc(
      {
        documentId: docusignId,
        recipientGuidToFix: signatories[signerIx].id || "",
        newEmail: signatories[signerIx].email
      },
      (response:any) => {
        setIsEditing(null);
      },
      (err:any) => {
        dispatch(dispatchError(err));
      }
    )
  }

  const handleClose = () => {
    if(onClose) onClose();
    setIsOpen(false);
  };

  return (
    <Dialog
      maxWidth={"md"}
      open={isOpen}
      data-testid="dialog-wrapper"
      onClose={() => setIsOpen(false)}
      fullWidth
    >
      <DialogTitle
        sx={(theme: Theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        })}
      >
        Successful Submit!
      </DialogTitle>
      <DialogContent>
        <Box marginY={(theme) => theme.spacing(2)}>
          <Stack direction="row" useFlexGap sx={{ alignItems: "center" }}>
            <div className="success-icon">
              <CheckCircle />
            </div>
            <p>
              The Change Order&nbsp;
              <strong>{state.changeOrder.address && state.changeOrder.address.address}
              {state.changeOrder.lot && "Lot:" + state.changeOrder.lot}
              {state.changeOrder.block && "Block:" + state.changeOrder.block}</strong>&nbsp;
              has been submitted successfully!
            </p>
          </Stack>
          
          <p>A DocuSign has been created and sent out to:</p>
          <table className="signers">
            { signatories
              .map((signer:Signatory, sIx:number) => 
                <tr>
                  <td className="email-icon">
                    { sIx === 0 ? <span title="Highland Homes Administrator"><img src={logo} /></span> : <MarkEmailRead />}</td>
                  <td>{signer.name}&nbsp;
                    { isEditing === sIx
                      ? 
                        <>
                          <TextField
                            label="Signer email"
                            onChange={(event => {
                              const newSignatories = [ ...signatories ];
                              newSignatories[sIx].email = event.target.value;
                              setSignatories(newSignatories);
                            })}
                            size="small"
                            sx={{ width: "350px" }}
                            type="email"
                            value={signer.email}
                            variant="outlined"
                          />
                          &nbsp;
                          <Button
                            color="primary"
                            onClick={() => handleUpdateEmail(sIx)}
                            variant="contained"
                          >
                            Update
                          </Button>
                        </>
                      : <>
                          - <span className="email-text">{signer.email}</span>
                          { !isEditing && 
                            <>
                              &nbsp;
                              <Tooltip title="Edit this email address">
                                <IconButton
                                  aria-label="Edit"
                                  color="primary"
                                  onClick={() => setIsEditing(sIx)}
                                  size="small"
                                >
                                  <Edit />
                                </IconButton>
                              </Tooltip>
                            </>
                          }
                        </>
                    }
                     </td>
                </tr>
              )
            }
          </table>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
            navigate(`/job-summary?addressid=${intentionId}`);
          }}
          variant="contained"
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default COSubmitSuccess;
