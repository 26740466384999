import { Article, PictureAsPdf } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";

interface CircularProgressProps {
  current: number;
  total: number;
}

const CircularProgressWithLabel = ({
  current,
  total,
}: CircularProgressProps) => {
  const percentage = total > 0 ? (current / total) * 100 : 0;

  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        style={{
          borderRadius: "50%",
          boxShadow: "inset 0 0 0 5.5px #d1d1d1",
        }}
        color={current === total ? "success" : "warning"}
        size={35}
        variant="determinate"
        value={percentage}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {`${current}/${total}`}
        </Typography>
      </Box>

      {/* Article Icon positioned at the top-right corner */}
      <PictureAsPdf
        sx={{
          position: "absolute",
          top: -4,
          left: -6,
          fontSize: "1rem",
          color: "text.secondary",
          backgroundColor: "white",
          borderRadius: "50%",
          boxShadow: 1,
        }}
      />
    </Box>
  );
};

export default CircularProgressWithLabel;
