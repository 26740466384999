import { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import { ReportProblem } from "@mui/icons-material";
import { Stack, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { OptionTypes, Signatory, UIContext, UIState } from "../../providers/UIProvider";
import useAccessControl from "../../hooks/useAccessControl";
import {
  changeOrderOptions,
  createSigningDoc,
  storeChangeOrderPDF,
  modifyCOOptions,
  submitIntention,
} from "../../apiCalls";
import { isEmpty, omit } from "lodash";
import COPDFPreview from "../../modals/COPDFPreview";
import SaveTemplate from "../../modals/SaveTemplate";
import { handleUnchangedNavigation, dispatchError } from "../../common/fx";
import DiscardCOConfirmation from "../../modals/DiscardCOConfirmation";
import COSubmitSuccess from "../../modals/COSubmitSuccess";

const EditFooter = () => {
  const navigate = useNavigate();
  //const theme = useTheme();

  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [PDFUrl, setPDFUrl] = useState<string>("");
  const [isReadyForSave, setIsReadyForSave] = useState<boolean>(false);
  const [incompleteOptions, setIncompleteOptions] = useState<number>(0);
  const [isIncomplete, setIsIncomplete] = useState<boolean>(false);
  const [saveRequirements, setSaveRequirements] = useState<string>("");
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState<boolean>(false);
  const [signers, setSigners] = useState<Signatory[]>([]);
  const [docusignId, setDocusignId] = useState<string>("");
  //const [isCODone, setIsCODone] = useState<boolean>(false);
  const templatesAllowed = useAccessControl("OptionTemplate", "GetTemplates");
  const storeChangeOrderPDFAccess = useAccessControl("ChangeOrder", "StorePDF");
  const submitIntentionAccess = useAccessControl(
    "Intention",
    "SubmitIntention"
  );

  useEffect(() => {
    setIncompleteOptions(
      state.pendingItems.filter(
        (item: OptionTypes) =>
          !item.category ||
          item.category === "" ||
          ((!item.description ||
          item.description === "") &&
          item.option !== "Formatting Separator")
      ).length
    );
  }, [state.pendingItems]);

  useEffect(() => {
    if(state.changeOrder.address?.jobID !== null && state.changeOrder.hasSubmittedBasePlan === null) {
      setIsReadyForSave(false);
      return;
    }
    setIsIncomplete(incompleteOptions > 0);
    if (incompleteOptions > 0)
      setSaveRequirements(
        "You have option line(s) that are incomplete. Please complete or delete the option line(s) to Save."
      );
    else setSaveRequirements("");
    const newIsReadyForSave = !(
      state.changeOrder.status !== "editing" || 
      incompleteOptions > 0 ||
      state.pendingItems.some((item:OptionTypes) => item.isInConflict)
    );
    setIsReadyForSave(newIsReadyForSave);
  }, [state.changeOrder.status, incompleteOptions, state.changeOrder.hasSubmittedBasePlan]);

  const handleExit = () => {
    // is the order completely empty? Maybe the user wants to discard it:
    if((incompleteOptions === state.pendingItems &&
      state.pendingItems.length > 0) ||
     state.pendingItems.length === 0) {
      setShowDiscardConfirmation(true);
     } else {
      // Is the order unchanged?
      handleUnchangedNavigation(
        -1,
        state.changeOrder.status,
        navigate,
        dispatch,
        () => {
          // So it's unchanged, the user wants to leave,
          // but if all the items are new, that means it will become empty...
          // therefore, maybe the users wants to discard it?
          if(state.pendingItems
              .filter((item:OptionTypes) => item.isNew)
              .length === state.pendingItems.length) {
            setShowDiscardConfirmation(true);
          } else {
            navigate(-1);
            dispatch({
              type: "ResetChangeOrder",
            });
            dispatch({
              type: "ResetCreateChangeOrderForm",
            });
          }
        }
      );
     }
  }

  const saveChangeOrder = (
    redirect?: boolean
  ) => {
    dispatch({
      type: "ChangeOrder",
      payload: {
        lastSaved: null,
        status: "blocked",
      },
      source: "Edit Page save",
    });
    modifyCOOptions(
      intentionId as string,
      {
        options: state.pendingItems
          ? state.pendingItems
              .filter((obj: changeOrderOptions) => !(isEmpty(obj.description) && obj.option !== "Formatting Separator"))
              .map((obj: changeOrderOptions) => {
                const newObj = { ...obj };
                if (
                  newObj.category === "Manual Option" &&
                  newObj.optionCode === null
                ) {
                  newObj.optionCode = "";
                }
                newObj.highlandCategory = obj.highlandCategory.key;
                newObj.salesPrice = (
                  newObj.quantity * newObj.unitPrice
                ).toFixed(2);
                return newObj.isNew ? omit(newObj, "id") : newObj;
              })
          : null,
      },
      (res: any) => {
        dispatch({
          type: "ChangeOrder",
          payload: {
            lastSaved: Date.now(),
          },
          source: "Edit page Print",
        });
        redirect && navigate(`/job-summary?addressid=${intentionId}`);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `The Change Order ${
              state.changeOrder.address && state.changeOrder.address.address
            } ${state.changeOrder.lot && "Lot:" + state.changeOrder.lot} ${
              state.changeOrder.block && "Block:" + state.changeOrder.block
            } has been saved successfully!`,
            severity: "success",
          },
        });
      },
      (err: any) => {
        dispatch({
          type: "ChangeOrder",
          payload: {
            lastSaved: new Date(),
            status: "editing",
          },
          source: "Edit Page - error after Save",
        });
        const errDetail = err.response.status === 400 || err.response.status === 404
          ? `${err.response.data.errors ? `${err.response.data.errors.OperationCanceledException} - ` : ""}Change Order could not be saved`
          : err.response.data?.detail;
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: errDetail,
            data: err.response.data,
          })
        );
      }
    );
  };

  const handlePrint = (buyerCount: number, signatories:Signatory[]) => {
    if(!submitIntentionAccess) return false;
    dispatch({
      type: "ChangeOrder",
      payload: {
        lastSaved: null,
        status: "blocked",
      },
      source: "Edit Page Submit",
    });
    submitIntention(
      intentionId as string,
      () => {
        if (intentionId) {
          storeChangeOrderPDFAccess &&
          storeChangeOrderPDF(
            {
              changeOrderId: intentionId,
              buyerCount,
            },
            (res:any) => {
              /*function base64ToBlob(
                base64: string,
                contentType: string = ""
              ): Blob {
                // Convert Base64 to a byte array
                const byteCharacters = atob(base64);
                const byteArrays = [];

                for (
                  let offset = 0;
                  offset < byteCharacters.length;
                  offset += 512
                ) {
                  const slice = byteCharacters.slice(
                    offset,
                    offset + 512
                  );
                  const byteNumbers = new Array(slice.length);

                  for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                  }

                  const byteArray = new Uint8Array(byteNumbers);
                  byteArrays.push(byteArray);
                }

                // Create a blob from the byte array
                return new Blob(byteArrays, { type: contentType });
              }

              function downloadPDF(
                documentName: string,
                contentType: string,
                fileData: string
              ) {
                // Convert Base64 fileData to Blob
                const blob = base64ToBlob(fileData, contentType);

                // Create a Blob URL
                const blobUrl = window.URL.createObjectURL(blob);
                setPDFUrl(blobUrl);
                // Create a link element
                const link = document.createElement("a");

                // Set the download attribute with a filename
                link.download = documentName;

                // Set the href to the blob URL
                link.href = blobUrl;

                // Append the link to the document body
                document.body.appendChild(link);

                // Programmatically click the link to trigger the download
                link.click();

                // Clean-up: remove the link from the document
                document.body.removeChild(link);
              }

              downloadPDF(
                res.documentName,
                res.contentType,
                res.fileData
              );*/

              

              /*dispatch({
                type: "Snackbar",
                payload: {
                  show: true,
                  message: `The Change Order ${
                    state.changeOrder.address && state.changeOrder.address.address
                  } ${state.changeOrder.lot && "Lot:" + state.changeOrder.lot} ${
                    state.changeOrder.block && "Block:" + state.changeOrder.block
                  } has been submitted successfully!`,
                  severity: "success",
                },
              });
              navigate(`/job-summary?addressid=${intentionId}`);*/
            },
            (err: any) => {
              dispatch(
                dispatchError({
                  message: err.message,
                  statusText: err.response.statusText,
                  title: err.response.data.title,
                  status: err.response.status,
                  detail: err.response.data.detail,
                  data: err.response.data,
                })
              );
            }
          );
          createSigningDoc(
            intentionId as string,
            buyerCount + 1,
            signatories,
            (response:any) => {
              setDocusignId(response.data.documentId);
              const signers = signatories.map((signer:Signatory) => {
                const responseSigner = response.data.signatories.find((signatorie:any) => signatorie.email === signer.email);
                signer.id = responseSigner.recipientIdGuid;
                signer.userId = responseSigner.userId;
                return(signer);
              });
              setSigners(signers);
            },
            (err: any) => {
              dispatch(
                dispatchError({
                  message: err.message,
                  statusText: err.response.statusText,
                  title: err.response.data.title,
                  status: err.response.status,
                  detail: err.response.data.detail,
                  data: err.response.data,
                })
              );
            }
          );
        }
      },
      (err: any) => {
        const errorMessage = err.response.data.errors.OperationCanceledException[0] ?? err.message;
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: errorMessage,
            data: err.response.data,
          })
        );
      }
    );
  };

  const isSaveTemplateDisabled = () => {
    return (
      state.changeOrder.lastSaved === null ||
      state.changeOrder.status === "editing" ||
      state.pendingItems.length === 0 ||
      state.pendingItems.filter((item: OptionTypes) => item.isNew)
        .length > 0
    );
  };

  const isSubmitDisabled = () => {
    return (
      state.changeOrder.lastSaved === null ||
      !state.changeOrder.customerBuyer?.salesforceAccountId ||
      !state.changeOrder.address.jobID ||
      state.changeOrder.status === "editing" ||
      state.pendingItems.length === 0 ||
      state.pendingItems.filter((item: OptionTypes) => item.isNew)
        .length > 0
    );
  };

  return (
    <>
      <Stack
        className="edit-footer"
        justifyContent={"space-between"}
        sx={{
          width: "100%",
          flexDirection: "row",
          marginTop: "0!important",
          alignItems: "center",
        }}
      >
        <Box width={"100%"} display={"flex"} alignItems={"center"}>
          <Button
            onClick={handleExit}
            variant="outlined"
          >
            Exit
          </Button>
          {templatesAllowed && (
            <>
              <span style={{ margin: "0 20px" }}>|</span>
              <SaveTemplate disabled={isSaveTemplateDisabled()} />
            </>
          )}
        </Box>

        <Box
          width={"100%"}
          gap={2}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          {isIncomplete && (
            <span title={saveRequirements} style={{ height: "1.5rem" }}>
              <ReportProblem sx={{ color: "#e56d29" }} />
            </span>
          )}
          <Button
            disabled={!isReadyForSave}
            onClick={() => saveChangeOrder(false)}
            variant="contained"
          >
            Save
          </Button>
          <Button
            disabled={!isReadyForSave}
            onClick={() => saveChangeOrder(true)}
            variant="contained"
          >
            Save & Exit
          </Button>
          <Box maxWidth={500}>
            <COPDFPreview
              disabled={isSubmitDisabled()}
              mode="submit"
              printFlow={(buyerCount: number, signatories?: Signatory[]) => handlePrint(buyerCount, signatories ?? [])}
              title="Submission Confirmation"
            />
          </Box>
        </Box>
      </Stack>
      { showDiscardConfirmation && <DiscardCOConfirmation /> }
      { signers.length > 0 && docusignId &&
        <COSubmitSuccess
          intentionId={intentionId || ""}
          docusignId={docusignId}
          signers={signers}
          onClose={() => setDocusignId("")} 
        />
      }
    </>
  );
};

export default EditFooter;
