import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { dispatchError } from "../../common/fx";
import ReusableDialog from "../../components/Dialog";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AddressTypes,
  BuyerTypes,
  CommunityTypes,
  ElevationTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import { useNavigate } from "react-router-dom";
import {
  startNewIntention,
  findCustomerByNameSF,
  getAddress,
  getPlanElevations,
  getPlans,
  newIntention,
  updateKeyword,
  JobHasSubmittedBasePlan,
  JobHasSubmittedIntention,
  GetParentIntentionId,
  AddNewQuoteBuyer,
  AddNewQuote,
  findCustomerByNameSFQuote,
  communityDropdownApi,
} from "../../apiCalls";
import {
  debounce,
  isNil,
  isNull,
  isEmpty,
  uniqBy,
  isObject,
  mapValues,
  trim,
} from "lodash";
import useAccessControl from "../../hooks/useAccessControl";
import { PersonAddAlt1Sharp, Warning } from "@mui/icons-material";
import { display, Stack } from "@mui/system";
import AddSFBuyer from "../AddQuoteBuyer/AddQuoteBuyer";
import { filterOptions } from "../../utils/filterOptions";
import { getEnvironmentFromURL } from "../../utils/getEnviromentFromUrl";
import { ReactComponent as SalesforceIcon } from "../../icons/salesforce.svg";
import { ReactComponent as ChangeOrderIcon } from "../../icons/changeOrderIcon.svg";

import axios, { CancelTokenSource } from "axios";

// import useAccessControl from "../../hooks/useAccessControl";

const CreateQuoteOrder = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [buyerLabel, setBuyerLabel] = useState("Buyer");

  const [alreadySubmittedCODisable, setAlreadySubmittedCoDisable] =
    useState(false);
  const [parentIntentionId, setParentIntentionId] = useState(null);

  /* const communityDropdownApiAccess = useAccessControl(
    "Community",
    "GetActiveCommunities"
  ); */
  const [cancelTokenSource, setCancelTokenSource] =
    useState<CancelTokenSource | null>(null);

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const GetPlansAccess = useAccessControl("Plan", "GetPlans");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");
  const FindCustomerByNameFromSFAccess = useAccessControl(
    "Customer",
    "FindCustomerByNameFromSF"
  );
  const StartNewIntentionAccess = useAccessControl(
    "Intention",
    "StartNewIntention"
  );

  const searchSalesForce = useCallback(
    debounce((value: string, cancelTokenSourceRef: any) => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel(
          "Operation canceled due to new request."
        );
      }

      const newCancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = newCancelTokenSource;

      try {
        findCustomerByNameSFQuote(
          {
            searchCriteria: value,
            projectID: state.createQuoteForm.community.projectId || "",
          },
          (res: any) => {
            if (res?.data) {
              dispatch({
                type: "AvailableBuyerQuoteNames",
                payload: res.data,
              });
            }
            setLoadingBuyerNames(false);
          },
          (err: any) => {
            console.error("API Error:", err);
          },
          newCancelTokenSource
        );
      } catch (err) {
        console.error("Promise Error:", err);
      }
    }, 2000),
    [state.createQuoteForm.community.projectId]
  );

  const cancelTokenSourceRef = useRef<any>(null);

  const handleInputChange = (e: any, value: string) => {
    if (value.length >= 3) {
      setLoadingBuyerNames(true);
      searchSalesForce(value, cancelTokenSourceRef);
      dispatch({
        type: "CreateQuoteForm",
        payload: {
          customerBuyerFreeForm: value,
        },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: "availableBuyerQuoteNames",
      payload: [],
    });
    dispatch({
      type: "ResetCreateQuoteForm",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableCreate = () => {
    function isEmptyOrNull(value: any): boolean {
      const trimedValue = trim(value);

      return trimedValue === "" || trimedValue === null;
    }

    if (
      !isEmptyOrNull(state?.createQuoteForm?.community) &&
      state?.createQuoteForm?.addressType === "knownAddress" &&
      !isEmptyOrNull(state?.createQuoteForm?.address) &&
      !isEmptyOrNull(state?.createQuoteForm?.plan) &&
      !isEmptyOrNull(state?.createQuoteForm?.plan.planNumber) &&
      !isEmptyOrNull(state?.createQuoteForm?.elevation) &&
      !isEmptyOrNull(state?.createQuoteForm?.elevation?.planElevationNumber)
    ) {
      return false;
    }
    if (
      !isEmptyOrNull(
        state?.createQuoteForm?.customerBuyer ||
          state?.createQuoteForm?.customerBuyerFreeForm
      ) &&
      !isEmptyOrNull(state?.createQuoteForm?.community) &&
      state?.createQuoteForm?.addressType === "lotBlock" &&
      !isEmptyOrNull(state?.createQuoteForm?.lot) &&
      !isEmptyOrNull(state?.createQuoteForm?.block) &&
      !isEmptyOrNull(state?.createQuoteForm?.plan) &&
      !isEmptyOrNull(state?.createQuoteForm?.plan.planNumber) &&
      !isEmptyOrNull(state?.createQuoteForm?.elevation.planElevationNumber) &&
      !isEmptyOrNull(state?.createQuoteForm?.elevation)
    ) {
      return false;
    }
    if (
      !isEmptyOrNull(
        state?.createQuoteForm?.customerBuyer ||
          state?.createQuoteForm?.customerBuyerFreeForm
      ) &&
      !isEmptyOrNull(state?.createQuoteForm?.community) &&
      !isEmptyOrNull(state?.createQuoteForm?.addressFreeform) &&
      !isEmptyOrNull(state?.createQuoteForm?.plan) &&
      !isEmptyOrNull(state?.createQuoteForm?.plan?.planNumber) &&
      !isEmptyOrNull(state?.createQuoteForm?.elevation?.planElevationNumber) &&
      !isEmptyOrNull(state?.createQuoteForm?.elevation)
    ) {
      return false;
    } else return true;
  };

  const createQuote = () => {
    AddNewQuote(
      {
        quoteBuyerId: state?.selectedBuyerQuote?.id,
        projectId: state.createQuoteForm.community.projectId,
        planElevationId:
          state.createQuoteForm.address &&
          state.createQuoteForm.address.planElevationId !== ""
            ? state.createQuoteForm.address.planElevationId
            : state.createQuoteForm.elevation.planElevationID,

        jobId: state.createQuoteForm.address.jobID,
      },
      (res: any) => navigate(`../quote?quoteId=${res.data.id}`),
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  const fetchCommunities = () => {
    communityDropdownApi(
      (res: any) => {
        dispatch({
          type: "Communities",
          payload: res.data,
        });
      },
      (res: any) =>
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
            severity: "error",
          },
        })
    );
  };

  useEffect(() => {
    fetchCommunities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReusableDialog
      setIsOpen={(value) => setOpen(value)}
      isOpen={open}
      onChange={() =>
        dispatch({
          type: "ResetCreateQuoteForm",
        })
      }
      maxWidth={"md"}
      content={
        <>
          <DialogContent>
            <>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  justifyItems={"center"}
                >
                  <Autocomplete
                    fullWidth
                    disabled={true}
                    multiple={false}
                    freeSolo
                    loading={loadingBuyerName}
                    size="medium"
                    value={{
                      name: state?.selectedBuyerQuote?.buyerName,
                      email: state?.selectedBuyerQuote?.buyerEmail,
                      phone: state?.selectedBuyerQuote?.buyerPhone,
                    }}
                    sx={{
                      ".Mui-error": {
                        color: "green!important",
                        borderColor: "green!important",
                      },
                      ".Mui-error fieldset": {
                        borderColor: "green!important",
                      },
                      ".Mui-error input": {
                        color: "black",
                      },
                    }}
                    onChange={(e, value) => {
                      dispatch({
                        type: "CreateQuoteForm",
                        payload: {
                          customerBuyer: value,
                          buyerType: "buyer",
                        },
                      });
                    }}
                    onInputChange={handleInputChange}
                    options={[]}
                    getOptionLabel={(option: any) => {
                      const name = option.name || "";
                      const email = option.email
                        ? `- Email: ${option.email}`
                        : "";
                      const phone = option.phone
                        ? `Phone: ${option.phone}`
                        : "";

                      return `${name} ${email} ${phone}`.trim();
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={
                          isObject(state?.selectedBuyerQuote) &&
                          state?.selectedBuyerQuote?.sfAccountId
                        }
                        helperText={
                          isObject(state?.selectedBuyerQuote) &&
                          state?.selectedBuyerQuote?.sfAccountId &&
                          "Buyer in Salesforce"
                        }
                        label={buyerLabel}
                        fullWidth
                      />
                    )}
                  />
                </Stack>
              </Box>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Autocomplete
                  multiple={false}
                  fullWidth
                  size="medium"
                  value={state.createQuoteForm.community || null}
                  onChange={(e, value, reason) => {
                    if (reason === "clear") {
                      setAlreadySubmittedCoDisable(false);
                      dispatch({
                        type: "ResetCreateQuoteForm",
                      });
                      return;
                    } else setAlreadySubmittedCoDisable(false);
                    dispatch({
                      type: "CreateQuoteForm",
                      payload: {
                        community: value,
                        plan: "",
                        elevation: "",
                        customerBuyer: "",
                        address: "",
                      },
                    });
                    if (value) {
                      setAlreadySubmittedCoDisable(false);

                      getAddress(
                        { projectID: value.projectId },
                        (res: any) => {
                          dispatch({
                            type: "AvailableAddresses",
                            payload: res.data,
                          });
                        },
                        (err: any) =>
                          dispatch(
                            dispatchError({
                              message: err.message,
                              statusText: err.response.statusText,
                              title: err.response.data.title,
                              status: err.response.status,
                              detail: err.response.data.detail,
                              data: err.response.data,
                            })
                          )
                      );
                      GetPlansAccess &&
                        getPlans(
                          {
                            ProjectID: value.projectId,
                            ProjectNumber: value.projectNumber,
                          },
                          (res: any) => {
                            dispatch({
                              type: "AvailablePlans",
                              payload: res.data.sort(
                                (
                                  a: {
                                    planNumber: string;
                                  },
                                  b: {
                                    planNumber: string;
                                  }
                                ) => a.planNumber.localeCompare(b.planNumber)
                              ),
                            });
                          },
                          (err: any) =>
                            dispatch(
                              dispatchError({
                                message: err.message,
                                statusText: err.response.statusText,
                                title: err.response.data.title,
                                status: err.response.status,
                                detail: err.response.data.detail,
                                data: err.response.data,
                              })
                            )
                        );
                    }
                  }}
                  options={state.communities as CommunityTypes[]}
                  getOptionLabel={(option) =>
                    `${option.name} (${option.projectNumber})`
                  }
                  filterOptions={(options, state) =>
                    filterOptions(options, state, ["name", "projectNumber"])
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Community"
                      fullWidth
                    />
                  )}
                />
              </Box>

              <Box gap={2} marginY={(theme) => theme.spacing(2)}>
                {state.createQuoteForm.addressType === "knownAddress" && (
                  <Autocomplete
                    multiple={false}
                    fullWidth
                    size="medium"
                    onFocus={async () => {
                      if (state.createQuoteForm.customerBuyer === "Spec") {
                        await dispatch({
                          type: "CreateQuoteForm",
                          payload: {
                            customerBuyer: state.availableBuyerQuoteNames[0],
                            buyerType: "buyer",
                          },
                        });
                      }
                    }}
                    sx={{
                      ".Mui-error": {
                        color: "green!important",
                        borderColor: "green!important",
                      },
                      ".Mui-error fieldset": {
                        borderColor: "green!important",
                      },
                      ".Mui-error input": {
                        color: "black",
                      },
                    }}
                    defaultValue={""}
                    disabled={isEmpty(state.createQuoteForm.community)}
                    value={
                      state.createQuoteForm.address ||
                      state.createQuoteForm.addressFreeform ||
                      null
                    }
                    onChange={(e, value) => {
                      if (isNull(value)) {
                        setAlreadySubmittedCoDisable(false);

                        dispatch({
                          type: "CreateQuoteForm",
                          payload: {
                            address: "",
                            plan: {
                              planNumber: "",
                              planElevationNumber: "",
                            },
                            elevation: {
                              planNumber: "",
                              planElevationNumber: "",
                              abbreviation: "",
                            },
                          },
                        });
                      } else if (value) {
                        console.log("Address", value);
                        if (value.planId && value.planNumber) {
                          GetPlanElevationsAccess &&
                            getPlanElevations(
                              {
                                projectID:
                                  state.createQuoteForm.community.projectId,
                                projectNumber:
                                  state.createQuoteForm.community.projectNumber,
                                planId: value.planId,

                                planNumber: value.planNumber,
                              },
                              (res: any) => {
                                dispatch({
                                  type: "AvailableElevations",
                                  payload: res.data.sort(
                                    (
                                      a: {
                                        planElevationNumber: string;
                                      },
                                      b: {
                                        planElevationNumber: string;
                                      }
                                    ) =>
                                      a.planElevationNumber.localeCompare(
                                        b.planElevationNumber
                                      )
                                  ),
                                });
                              },
                              (err: any) =>
                                dispatch(
                                  dispatchError({
                                    message: err.message,
                                    statusText: err.response.statusText,
                                    title: err.response.data.title,
                                    status: err.response.status,
                                    detail: err.response.data.detail,
                                    data: err.response.data,
                                  })
                                )
                            );
                        }
                        if (value.jobID) {
                          JobHasSubmittedBasePlan(
                            { jobId: value.jobID },
                            (res: any) =>
                              res &&
                              res.data &&
                              GetParentIntentionId(
                                { jobId: value.jobID },
                                (res: any) => setParentIntentionId(res.data),
                                (err: any) =>
                                  dispatch(dispatchError(err.response.data))
                              ),
                            (err: any) =>
                              dispatch(dispatchError(err.response.data))
                          );
                          JobHasSubmittedIntention(
                            { jobId: value.jobID },
                            (res: any) =>
                              res &&
                              (setAlreadySubmittedCoDisable(res.data),
                              res.data &&
                                GetParentIntentionId(
                                  { jobId: value.jobID },
                                  (res: any) => setParentIntentionId(res.data),
                                  (err: any) =>
                                    dispatch(dispatchError(err.response.data))
                                )),
                            (err: any) =>
                              dispatch(dispatchError(err.response.data))
                          );
                        }
                        GetPlansAccess &&
                          getPlans(
                            {
                              ProjectID:
                                state.createQuoteForm.community.projectId,
                              projectNumber:
                                state.createQuoteForm.community.projectNumber,
                            },
                            (res: any) => {
                              dispatch({
                                type: "AvailablePlans",
                                payload: res.data.sort(
                                  (
                                    a: {
                                      planNumber: string;
                                    },
                                    b: {
                                      planNumber: string;
                                    }
                                  ) => a.planNumber.localeCompare(b.planNumber)
                                ),
                              });
                              const {
                                planNumber,
                                planElevationNumber,
                                abbreviation,
                                description,
                              } = value;
                              const updatedObject = mapValues(value, (value) =>
                                value === null ? "" : value
                              );

                              dispatch({
                                type: "CreateQuoteForm",
                                payload: {
                                  lot: "",
                                  block: "",
                                  addressFreeform: "",
                                  address: updatedObject,
                                  plan: {
                                    planNumber: isNull(planNumber)
                                      ? ""
                                      : planNumber,
                                    planElevationNumber: isNull(
                                      planElevationNumber
                                    )
                                      ? ""
                                      : planElevationNumber,
                                    description,
                                  },
                                  elevation: {
                                    planNumber: isNull(planNumber)
                                      ? ""
                                      : planNumber,
                                    planElevationNumber: isNull(
                                      planElevationNumber
                                    )
                                      ? ""
                                      : planElevationNumber,
                                    abbreviation,
                                  },
                                },
                              });
                            },
                            (err: any) =>
                              dispatch(
                                dispatchError({
                                  message: err.message,
                                  statusText: err.response.statusText,
                                  title: err.response.data.title,
                                  status: err.response.status,
                                  detail: err.response.data.detail,
                                  data: err.response.data,
                                })
                              )
                          );
                      }
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                      if (reason === "clear") {
                        setAlreadySubmittedCoDisable(false);

                        dispatch({
                          type: "CreateQuoteForm",
                          payload: {
                            lot: "",
                            block: "",
                            addressFreeform: "TBD / Preliminary",
                            address: "",
                            plan: "",
                            elevation: "",
                          },
                        });
                      }
                    }}
                    options={(state.availableAddresses as AddressTypes[]) || ""}
                    getOptionLabel={(option: any) => {
                      if (typeof option === "string") return option;
                      if (option.address === "TBD / Preliminary")
                        return option.address;
                      return option && option.address !== null
                        ? `${option.address}  ${
                            !isNull(option.planNumber) &&
                            !isNull(option.planElevationNumber) &&
                            !isEmpty(option.planNumber) &&
                            !isEmpty(option.planElevationNumber)
                              ? `(${option.planNumber}-${option.planElevationNumber})`
                              : ""
                          } | ${option.projectNumber}-${option.jobNumber}`
                        : "";
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Address"
                        fullWidth
                        error={isObject(state?.createQuoteForm?.address)}
                        helperText={
                          isObject(state?.createQuoteForm?.address) &&
                          "Address in BRIX"
                        }
                      />
                    )}
                  />
                )}
              </Box>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Autocomplete
                  defaultValue={""}
                  onFocus={() => {
                    if (state.createQuoteForm.customerBuyer === "Spec") {
                      console.log(state.availableBuyerQuoteNames[0]);
                      dispatch({
                        type: "CreateQuoteForm",
                        payload: {
                          customerBuyer: state.availableBuyerQuoteNames[0],
                          buyerType: "buyer",
                        },
                      });
                    }
                  }}
                  disabled={
                    isEmpty(state.createQuoteForm.community) ||
                    alreadySubmittedCODisable
                  }
                  fullWidth
                  getOptionLabel={(option) => {
                    return `${option.planNumber}${
                      option.description ? ` - ${option.description}` : ""
                    }`;
                  }}
                  multiple={false}
                  onChange={(e, value) => {
                    dispatch({
                      type: "CreateQuoteForm",
                      payload: {
                        plan: value,
                        elevation: "",
                      },
                    });
                    if (!isNull(value)) {
                      if (value.projectID) {
                        const { projectID, projectNumber, planId, planNumber } =
                          value;
                        GetPlanElevationsAccess &&
                          getPlanElevations(
                            { projectID, projectNumber, planId, planNumber },
                            (res: any) => {
                              dispatch({
                                type: "AvailableElevations",
                                payload: res.data.sort(
                                  (
                                    a: {
                                      planElevationNumber: string;
                                    },
                                    b: {
                                      planElevationNumber: string;
                                    }
                                  ) =>
                                    a.planElevationNumber.localeCompare(
                                      b.planElevationNumber
                                    )
                                ),
                              });
                            },
                            (err: any) =>
                              dispatch(
                                dispatchError({
                                  message: err.message,
                                  statusText: err.response.statusText,
                                  title: err.response.data.title,
                                  status: err.response.status,
                                  detail: err.response.data.detail,
                                  data: err.response.data,
                                })
                              )
                          );
                      }
                    }
                  }}
                  onInputChange={(e, value, reason) => {
                    if (!isNil(e)) {
                      if (e.type !== "click") {
                        dispatch({
                          type: "CreateQuoteForm",
                          payload: {
                            planOverride: value,
                          },
                        });
                      }
                    }
                  }}
                  options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
                    return obj.description;
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        state.createQuoteForm.addressFreeform === "" &&
                        !isEmpty(state?.createQuoteForm?.address?.planNumber) &&
                        state?.createQuoteForm?.address?.planNumber !==
                          state?.createQuoteForm?.plan?.planNumber &&
                        true
                      }
                      helperText={
                        state.createQuoteForm.addressFreeform === "" &&
                        !isEmpty(state?.createQuoteForm?.address?.planNumber) &&
                        state?.createQuoteForm?.address?.planNumber !==
                          state?.createQuoteForm?.plan?.planNumber &&
                        "*Address plan has been overriden"
                      }
                      variant="outlined"
                      label="Plan"
                      fullWidth
                    />
                  )}
                  size="medium"
                  value={state.createQuoteForm.plan || null}
                />
              </Box>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Autocomplete
                  multiple={false}
                  fullWidth
                  size="medium"
                  defaultValue={""}
                  disabled={
                    isEmpty(state.createQuoteForm.plan) ||
                    alreadySubmittedCODisable
                  }
                  value={state.createQuoteForm.elevation || null}
                  onInputChange={(e, value) => {
                    if (!isNil(e)) {
                      if (e.type !== "click") {
                        dispatch({
                          type: "CreateQuoteForm",
                          payload: {
                            planElevationOverride: value,
                          },
                        });
                      }
                    }
                    if (state.createQuoteForm.customerBuyer === "Spec") {
                      console.log(state.availableBuyerQuoteNames[0]);
                      dispatch({
                        type: "CreateQuoteForm",
                        payload: {
                          customerBuyer: state.availableBuyerQuoteNames[0],
                          buyerType: "buyer",
                        },
                      });
                    }
                  }}
                  onChange={(e, value) => {
                    if (isNil(value)) {
                      console.log(value);
                      dispatch({
                        type: "CreateQuoteForm",
                        payload: {
                          elevation: {
                            planElevationNumber: "",
                          },
                        },
                      });
                    } else
                      dispatch({
                        type: "CreateQuoteForm",
                        payload: {
                          elevation: value,
                        },
                      });
                  }}
                  options={state.availableElevations as ElevationTypes[]}
                  getOptionLabel={(option) => `${option.planElevationNumber}`}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        state.createQuoteForm.addressFreeform === "" &&
                        !isEmpty(
                          state?.createQuoteForm?.address?.planElevationNumber
                        ) &&
                        state?.createQuoteForm?.address?.planElevationNumber !==
                          state?.createQuoteForm?.elevation
                            ?.planElevationNumber &&
                        true
                      }
                      helperText={
                        state.createQuoteForm.addressFreeform === "" &&
                        !isEmpty(
                          state?.createQuoteForm?.address?.planElevationNumber
                        ) &&
                        state?.createQuoteForm?.address?.planElevationNumber !==
                          state?.createQuoteForm?.elevation
                            ?.planElevationNumber &&
                        "*Address elevation has been overriden"
                      }
                      variant="outlined"
                      label="Elevation"
                      fullWidth
                    />
                  )}
                />
              </Box>
              {/* <Box marginY={(theme) => theme.spacing(2)}>
                <TextField
                  fullWidth
                  onChange={(e) =>
                    dispatch({
                      type: "CreateQuoteForm",
                      payload: {
                        keyword: e.target.value,
                      },
                    })
                  }
                  value={state.createQuoteForm.keyword}
                  label="Keyword (25 Character Limit)"
                  variant="outlined"
                  placeholder="Keywords (25 Character Limit)"
                  inputProps={{
                    maxLength: 25,
                  }}
                  helperText={`${
                    state.createQuoteForm.keyword
                      ? state.createQuoteForm.keyword.length
                      : 0
                  }/25 Characters`}
                />
              </Box> */}
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setAlreadySubmittedCoDisable(false);
                dispatch({
                  type: "ResetCreateQuoteForm",
                });
              }}
              variant="outlined"
              color="primary"
            >
              Exit
            </Button>
            <Button
              onClick={() => createQuote()}
              disabled={disableCreate() || submitLoading}
              variant="contained"
              color="primary"
            >
              Create
            </Button>
          </DialogActions>
        </>
      }
      buttonText="Create Quote"
      title="Create Quote"
    />
  );
};

export default CreateQuoteOrder;
