import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Theme,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { ReactNode } from "react";

interface ReusableDialogProps {
  buttonColor?: "primary" | "secondary" | "error" | "info" | "success";
  buttonText?: string;
  dialogsx?: any;
  toolTipTitle?: string;
  buttonVariant?: "contained" | "outlined" | "text";
  buttonFullWidth?: boolean;
  buttonStyle?: any;
  content: ReactNode;
  disabled?: boolean;
  isOpen: boolean;
  icon?: ReactNode;
  loading?: boolean;
  onChange?: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  setIsOpen: (state: boolean) => void;
  title: string;
  type?: string;
}

const ReusableDialog: React.FC<ReusableDialogProps> = ({
  buttonColor = "primary",
  loading = false,
  buttonText,
  buttonFullWidth = true,
  buttonVariant = "contained",
  buttonStyle = null,
  content,
  dialogsx,
  toolTipTitle,
  disabled,
  icon,
  isOpen,
  maxWidth,
  onChange,
  setIsOpen,
  title,
  type,
}) => {
  const handleClickOpen = () => {
    setIsOpen(true);
    if (onChange) onChange();
  };

  const handleClose = (event: object, reason: string) => {
    if (reason && reason === "backdropClick") return;
    setIsOpen(false);
    if (onChange) onChange();
  };

  return (
    <>
      <span className="dialog-button" title={title}>
        <Tooltip title={toolTipTitle ?? buttonText}>
          {type !== "icon" ? (
            <LoadingButton
              color={buttonColor}
              data-testid="onClick-button"
              disabled={disabled}
              loading={loading}
              fullWidth={buttonFullWidth}
              onClick={handleClickOpen}
              size="medium"
              sx={buttonStyle}
              variant={buttonVariant}
            >
              {icon ? icon : null}
              {buttonText}
            </LoadingButton>
          ) : loading ? (
            <IconButton
              disabled={loading}
              data-testid="onClick-button"
              sx={buttonStyle}
            >
              <CircularProgress size={25} />
            </IconButton>
          ) : (
            <IconButton
              data-testid="onClick-button"
              onClick={handleClickOpen}
              sx={buttonStyle}
            >
              {icon}
            </IconButton>
          )}
        </Tooltip>
      </span>
      {isOpen && (
        <Dialog
          sx={dialogsx}
          maxWidth={maxWidth}
          open={isOpen}
          onDoubleClick={(e) => e.stopPropagation()}
          data-testid="dialog-wrapper"
          onClose={handleClose}
          fullWidth
        >
          <DialogTitle
            data-testid={"dialog-title"}
            sx={(theme: Theme) => ({
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
            })}
          >
            {title}
          </DialogTitle>
          {content}
        </Dialog>
      )}
    </>
  );
};

export default ReusableDialog;
