import { useState, useContext } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import DocusignStep from "../../components/DocusignStep/DocusignStep";
import useAccessControl from "../../hooks/useAccessControl";
import { Signatory, UIContext, UIState } from "../../providers/UIProvider";
import {
  BustaJob,
  createSigningDoc,
  downloadChangeOrderPDF,
  storeChangeOrderPDF,
} from "../../apiCalls";
import { dispatchError } from "../../common/fx";
import { LoadingButton } from "@mui/lab";
import { isEmpty, isUndefined } from "lodash";

const ConfirmBust = ({
  address,
  disabled,
  handleClose,
  jobId,
  title,
  fetchIntention,
}: any) => {
  const GetOptionsCatalogAccess = useAccessControl(
    "Option",
    "GetOptionsCatalog"
  );

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [currentStep, setCurrentStep] = useState<number>(1);
  const [specBuyer, setSpecBuyer] = useState<boolean>(false);

  const [signatories, setSignatories] = useState<Signatory[]>([]);
  const [buyerCount, setBuyerCount] = useState<number>(0);

  return (
    <>
      <ReusableDialog
        setIsOpen={setOpen}
        isOpen={open}
        toolTipTitle={
          disabled
            ? "Must have a submitted CO with a base plan option to change elevation."
            : undefined
        }
        disabled={disabled}
        buttonVariant={"text"}
        buttonStyle={{
          color: "black",
          paddingRight: "3rem",
        }}
        buttonText={title}
        icon={
          <AssignmentReturnIcon
            sx={{
              marginRight: "5px",
            }}
          />
        }
        maxWidth="sm"
        title={"Confirm Bust"}
        content={
          <>
            <DialogContent>
              {currentStep === 1 && (
                <>
                  {" "}
                  <Box p={2}>
                    <Typography variant="h6" color="text.primary">
                      Bust {address}?
                    </Typography>
                  </Box>
                  <Box p={2}>
                    <Typography variant="body2" color="text.primary">
                      This will create and submit a change order to delete all
                      previously submitted options across all change order(s)
                      and return the job price to $0.
                    </Typography>
                  </Box>
                  {console.log(state?.changeOrder)}
                  {state?.changeOrder?.customerBuyer?.connectionNumber !==
                    "Spec" && (
                    <Stack direction="column" p={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e, value) => {
                              setSpecBuyer(value);
                            }}
                          />
                        }
                        label="Change buyer to Spec"
                      />
                    </Stack>
                  )}
                </>
              )}
              {currentStep === 2 && (
                <DocusignStep
                  type={
                    specBuyer
                      ? "Spec"
                      : state?.changeOrder?.customerBuyer?.connectionNumber
                  }
                  setSignatories={setSignatories}
                  signatories={signatories}
                  buyerCount={buyerCount}
                  setBuyerCount={setBuyerCount}
                />
              )}
            </DialogContent>
            <DialogActions>
              <Stack
                sx={{ justifyContent: "space-between" }}
                direction="row"
                spacing={1}
              >
                <Button
                  onClick={() => {
                    setOpen(false);
                    setCurrentStep(1);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Cancel
                </Button>

                <LoadingButton
                  loading={loadingSubmit}
                  disabled={loadingSubmit}
                  onClick={(e) => {
                    if (currentStep === 1) {
                      setCurrentStep(2);
                    } else {
                      setLoadingSubmit(true);
                      BustaJob(
                        {
                          jobId: jobId,
                          buyerCount: buyerCount,
                          useSpecBuyer: specBuyer,
                        },
                        async (res: any) => {
                          await storeChangeOrderPDF(
                            {
                              changeOrderId: res.data,
                              buyerCount,
                            },
                            () => {
                              createSigningDoc(
                                res.data as string,
                                buyerCount + 1,
                                signatories,
                                () => {
                                  console.log("success");
                                },
                                (err: any) => {
                                  dispatch(
                                    dispatchError({
                                      message: err.message,
                                      statusText: err.response.statusText,
                                      title: err.response.data.title,
                                      status: err.response.status,
                                      detail: err.response.data.detail,
                                      data: err.response.data,
                                    })
                                  );
                                }
                              );
                            },
                            (err: any) => {
                              dispatch(
                                dispatchError({
                                  message: err.message,
                                  statusText: err.response.statusText,
                                  title: err.response.data.title,
                                  status: err.response.status,
                                  detail: err.response.data.detail,
                                  data: err.response.data,
                                })
                              );
                            }
                          );

                          await fetchIntention((coUpdate: any) => {
                            dispatch({
                              type: "Snackbar",
                              payload: {
                                show: true,
                                message: `${state.selectedJob.address} has been busted.`,
                                severity: "success",
                              },
                            });
                            setOpen(false);
                            setCurrentStep(1);
                            setLoadingSubmit(false);
                            handleClose();
                          }, res.data);
                        },

                        (err: any) =>
                          dispatch(
                            dispatchError(err.response.data),
                            setOpen(false),
                            setLoadingSubmit(false),
                            setCurrentStep(1)
                          )
                      );
                    }
                  }}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </LoadingButton>
              </Stack>
            </DialogActions>
          </>
        }
      />
    </>
  );
};

export default ConfirmBust;
