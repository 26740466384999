import { useEffect } from "react";
import { useNavigationType } from "react-router-dom";

const usePreviousRoute = (targetPath: string, onMatch: () => void) => {
  const navigationType = useNavigationType();

  useEffect(() => {
    const prevPath = sessionStorage.getItem("previousPath");

    if (prevPath === targetPath) {
      console.log("HOOK - MATCHED, triggering onMatch");
      onMatch();
      sessionStorage.removeItem("previousPath");
    }
  }, [navigationType, targetPath, onMatch]);
};

export default usePreviousRoute;
