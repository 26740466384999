import * as React from "react";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridSearchIcon,
} from "@mui/x-data-grid-pro";
import {
  Autocomplete,
  Box,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  ListItemText,
  Paper,
  Stack,
  Step,
  StepIconProps,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Button, { ButtonProps } from "@mui/material/Button";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { Link as RLink } from "react-router-dom";
import { numberWithCommas } from "../../utils/formatMoney";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  AssignmentLateOutlined,
  CheckCircle,
  CheckCircleOutline,
  ErrorOutline,
  PendingActionsOutlined,
  PendingOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import CircularProgressWithLabel from "../../components/CircularProgressLabel";
import { debounce, isEmpty, uniqueId } from "lodash";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CommunityTypes, UIContext, UIState } from "../../providers/UIProvider";
import { filterOptions } from "../../utils/filterOptions";
import { Container } from "@mui/system";
import {
  downloadChangeOrderPDF,
  listDocumentSummary,
  UpdateProcessingStatus,
} from "../../apiCalls";
import { dispatchError } from "../../common/fx";
import COPDFPreview from "../../modals/COPDFPreview";

interface ProcessorFunction {
  processorFunction: string;
}

interface DocumentSummary {
  id: string;
  documentId: string;
  jobKey: string;
  address: string;
  communityName: string;
  planNumber: string;
  planElevationNumber: string;
  buyerName: string;
  stage: string;
  signedChangeOrderProcessors: ProcessorFunction[];
  signedCOs: SignedCO[];
}

interface SignedCO {
  documentId: string;
  intentionId: string;
  changeOrderNumber: string;
  status: string;
  currentApprovalStatus: string;
  createdAt: string;
  updatedAt: string;
  buyerName: string;
  createdByName: string;
  lastUpdatedByName: string;
  currentNumberOfSignatures: number;
  totalNumberOfSignatories: number;
  changeOrderPrice: number;
}

interface StepperButtonGroupProps {
  params: {
    row: DocumentSummary;
  };
  updatedProcessingStatus: (obj: {
    documentId: string;
    processorBusinessFunction: string;
    processedStatus: boolean;
  }) => void;
  grantedRoles: Array<{ teamName: string }>;
}

const columns: GridColDef[] = [
  {
    field: "jobKey",
    headerName: "Job Number",
    flex: 1,
    width: 150,
  },

  {
    field: "communityName",
    headerName: "Community",
    flex: 1,
    width: 200,
  },
  {
    field: "address",
    headerName: "Address",
    width: 300,
    renderCell: (params) => (
      <RLink
        to={{
          pathname: "/job-summary",
          search: `?addressid=${params.row.id}`,
        }}
      >
        {params.row.address}
      </RLink>
    ),
  },
  {
    field: "planNumber",
    headerName: "Plan",
    flex: 1,
    width: 100,
  },
  {
    field: "planElevationNumber",
    headerName: "Elevation",
    flex: 1,
    width: 130,
  },
  // {
  //   field: "type",
  //   headerName: "Type",
  //   flex: 1,
  // },
  {
    field: "buyerLegalName",
    headerName: "Buyer",
    flex: 1,
    width: 125,
    renderCell: (params) => params.row.buyerName,
  },
  {
    field: "stage",
    headerName: "Stage",
    flex: 1,
    width: 125,
    renderCell: (params) => params.row.stage,
  },
];

export default function MasterDetailDataGrid() {
  const [state, dispatch] = React.useContext<UIState | any>(UIContext);
  const [pageSize, setPageSize] = React.useState(0);
  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);
  const [downloading, setDownloading] = React.useState(false);
  const [currentRoles, setCurrentRoles] = React.useState([]);

  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] =
    React.useState<GridRowId[]>([]);
  const [search, setSearch] = React.useState<string>("");
  const [page, setPage] = React.useState(0);
  const [communities, setCommunities] = useLocalStorage<any[]>(
    "communities",
    []
  );

  const [processed, setProcessed] = React.useState(false);

  const debouncedSetSearch = React.useMemo(
    () =>
      debounce((nextValue) => {
        setSearch(nextValue);
        setPage(0);
      }, 300),
    []
  );

  const updatedProcessingStatus = (obj: {
    documentId?: string;
    processorBusinessFunction?: string;
    processedStatus?: boolean;
  }) => {
    UpdateProcessingStatus(
      obj,
      (res: any) => console.log(res),
      (err: any) => console.log(err)
    );
  };

  const handleSearchChange = (event: any) => {
    debouncedSetSearch(event.target.value);
  };

  const stepOptions = ["Operations", "Purchasing", "Construction"];

  const StepIconComponent = (props: StepIconProps) => {
    const { active, completed, error } = props;

    return completed ? (
      <CheckCircle color="primary" />
    ) : error ? (
      <ErrorOutline color="error" />
    ) : active ? (
      <RadioButtonUnchecked color="primary" />
    ) : (
      <RadioButtonUnchecked color="disabled" />
    );
  };

  const renderStepperCell = (
    params: any,
    updatedProcessingStatus: any,
    grantedRoles: any
  ) => (
    <StepperButtonGroup
      params={params}
      updatedProcessingStatus={updatedProcessingStatus}
      grantedRoles={grantedRoles}
    />
  );

  const StepperButtonGroup: React.FC<StepperButtonGroupProps> = ({
    params,
    updatedProcessingStatus,
    grantedRoles,
  }) => {
    const userRoles = grantedRoles?.map((role: any) => role.teamName) || [];

    const processedSteps = params.row.signedChangeOrderProcessors.map(
      (obj: any) => obj.processorFunction
    );

    const [selectedSteps, setSelectedSteps] = React.useState(processedSteps);

    const handleStepToggle = (step: string) => {
      const hasPermission = userRoles.some(
        (role: string) => role?.toLowerCase() === step?.toLowerCase()
      );

      if (!hasPermission) {
        return;
      }

      const isSelected = selectedSteps.includes(step);
      const updatedSteps = isSelected
        ? selectedSteps.filter((s: string) => s !== step)
        : [...selectedSteps, step];

      setSelectedSteps(updatedSteps);

      updatedProcessingStatus({
        documentId: params.row.documentId,
        processorBusinessFunction: step,
        processedStatus: !isSelected,
      });
    };

    return (
      <Stepper
        alternativeLabel
        sx={{
          "& .MuiStepLabel-root": {
            flexDirection: "column",
            alignItems: "center",
            gap: "0px",
          },
          "& .MuiStepLabel-iconContainer": {
            paddingRight: "0",
          },
          "& .MuiStepLabel-label": {
            marginTop: "0",
          },
          "& .MuiStep-root": {
            padding: "0 16px",
          },
        }}
      >
        {stepOptions.map((step) => {
          const isDisabled = !userRoles.some(
            (role: string) => role?.toLowerCase() === step?.toLowerCase()
          );

          return (
            <Step
              key={step}
              completed={selectedSteps.includes(step)}
              active={selectedSteps.includes(step)}
            >
              <StepLabel
                StepIconComponent={StepIconComponent}
                onClick={() => !isDisabled && handleStepToggle(step)}
                sx={{
                  cursor: isDisabled ? "not-allowed" : "pointer",
                  opacity: isDisabled ? 0.5 : 1,
                  pointerEvents: isDisabled ? "none" : "auto",
                }}
              >
                {step}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  };

  const handleDownloadItems = (intentionId: string) => {
    setDownloading(true);

    if (intentionId) {
      downloadChangeOrderPDF(
        { changeOrderId: intentionId },
        async (res: {
          fileData: "string";
          contentType: "string";
          documentName: "string";
        }) => {
          function base64ToBlob(
            base64: string,
            contentType: string = ""
          ): Blob {
            // Convert Base64 to a byte array
            const byteCharacters = atob(base64);
            const byteArrays = [];

            for (
              let offset = 0;
              offset < byteCharacters.length;
              offset += 512
            ) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);

              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }

              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            // Create a blob from the byte array
            return new Blob(byteArrays, { type: contentType });
          }

          function downloadPDF(
            documentName: string,
            contentType: string,
            fileData: string
          ) {
            // Convert Base64 fileData to Blob
            const blob = base64ToBlob(fileData, contentType);

            // Create a Blob URL
            const blobUrl = window.URL.createObjectURL(blob);

            // Create a link element
            const link = document.createElement("a");

            // Set the download attribute with a filename
            link.download = documentName;

            // Set the href to the blob URL
            link.href = blobUrl;

            // Append the link to the document body
            document.body.appendChild(link);

            // Programmatically click the link to trigger the download
            link.click();

            // Clean-up: remove the link from the document
            document.body.removeChild(link);
          }

          downloadPDF(res.documentName, res.contentType, res.fileData);
          setDownloading(false);
        },
        (err: any) => {
          dispatch(
            dispatchError({
              message: err.message,
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: err.response.data.detail,
              data: err.response.data,
            })
          );
          setDownloading(false);
        }
      );
    }
  };

  function DetailPanelContent({ row }: { row: (typeof data)[number] }) {
    const jobSummaryColumns: GridColDef[] = [
      {
        field: "buyerLegalName",
        headerName: "Buyer Name",
        width: 200,
        renderCell: (params) => (
          <Box
            sx={{
              textTransform: "capitalize",
              display: "flex",
              alignContent: "center",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
            }}
          >
            {params.row.status === "Submitted" && (
              <CircularProgressWithLabel
                current={params.row.currentNumberOfSignatures}
                total={params.row.totalNumberOfSignatories}
              />
            )}{" "}
            {params.value}
          </Box>
        ),
      },
      {
        field: "createdAt",
        headerName: "Create Date",
        width: 150,
        valueFormatter: (params) =>
          `${moment(params.value).format("MM-DD-YYYY")}`,
        renderCell: (params) => (
          <Tooltip
            placement="right-start"
            title={`Created On ${moment(params.row.createdAt).format(
              "MM-DD-YYYY"
            )} by  ${params.row.createdByName} `}
          >
            <span className="table-cell-trucate">
              {moment(params.row.createdAt).format("MM-DD-YYYY")}
            </span>
          </Tooltip>
        ),
      },
      {
        field: "updatedAt",
        headerName: "Last Updated",
        width: 150,
        renderCell: (params) => (
          <Tooltip
            placement="right-start"
            title={`Last Updated On ${moment(params.row.updatedAt).format(
              "MM-DD-YYYY"
            )} by  ${params.row.lastUpdatedByName} `}
          >
            <span className="table-cell-trucate">
              {moment(params.row.updatedAt).format("MM-DD-YYYY")}
            </span>
          </Tooltip>
        ),
      },

      {
        field: "changeOrderNumber",
        headerName: "CO Number",
        width: 130,
        renderCell: (params) =>
          params?.row.status === "Submitted" ? (
            <RLink
              to={{
                pathname: "/view",
                search: `?intentionId=${params.row.intentionId}`,
              }}
            >
              {params.row.changeOrderNumber}
            </RLink>
          ) : (
            <RLink
              to={{
                pathname: "/edit",
                search: `?intentionId=${params.row.id}`,
              }}
            >
              {params.row.changeOrderNumber}
            </RLink>
          ),
      },
      {
        field: "status",
        headerName: "Status",
        width: 135,

        renderCell: (params) => (
          <Chip
            icon={
              params.value === "Submitted" ? (
                params.row.currentApprovalStatus === "Approved" ? (
                  <CheckCircleOutline color="success" />
                ) : params.row.currentApprovalStatus ===
                  "FurtherActionNeeded" ? (
                  <AssignmentLateOutlined color={"error"} />
                ) : (
                  <PendingActionsOutlined color="warning" />
                )
              ) : params.value === "Pending" ? (
                <PendingOutlined />
              ) : (
                <ErrorOutline />
              )
            }
            sx={{
              textTransform: "capitalize",
            }}
            label={params.value}
            variant="outlined"
          />
          // </Tooltip>
        ),
      },

      {
        field: "planNumber",
        headerName: "Plan / Elev",
        width: 100,
        renderCell: (params) =>
          `${params.row.planNumber}-${params.row.planElevationNumber}`,
      },
      {
        field: "changeOrderPrice",
        headerName: "CO Price",
        width: 150,

        align: "right",
        headerAlign: "right",
        valueFormatter: (params) => `$ ${numberWithCommas(params.value)}`,
      },
      {
        field: "actions",
        headerName: "",
        align: "right",
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        hideable: true,
        pinnable: false,
        editable: false,
        minWidth: 100,

        renderCell: (params) => {
          return (
            <Stack
              justifyContent={"center"}
              direction="row"
              spacing={0.5}
              mr={2}
            >
              <>
                <COPDFPreview
                  iconButton
                  bypassValidation
                  disabled={false}
                  intentionId={params.row.intentionId}
                  mode="printPreview"
                  printFlow={() => handleDownloadItems(params.row.intentionId)}
                  title="Print Preview"
                />
              </>
            </Stack>
          );
        },
      },
      {
        field: "approvalSteps",
        headerName: "",
        width: 480,
        align: "right",
        headerAlign: "right",
        renderCell: (params) => (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            {renderStepperCell(params, updatedProcessingStatus, currentRoles)}
          </Box>
        ),
      },
    ];

    return (
      <Box sx={{ px: 6, py: 2 }}>
        <Card
          sx={{
            width: "100%",
            minHeight: 100,
            backgroundColor: "#f4f4f4",
            padding: 2,
            borderRadius: 2,
            border: (theme) => `solid black 1px`,
          }}
        >
          <Typography variant="h6">{`Recently Executed Change Orders for Job #${row.jobKey}`}</Typography>

          {
            <Box sx={{ width: "100%", height: "100%" }}>
              <DataGridPro
                density="comfortable"
                columns={jobSummaryColumns}
                rows={row.signedCOs}
                getRowId={(row) => row.documentId}
                sx={{ height: "100%" }}
                hideFooter
              />
            </Box>
          }
        </Card>
      </Box>
    );
  }

  const handleDetailPanelExpandedRowIdsChange = React.useCallback(
    (newIds: GridRowId[]) => {
      setDetailPanelExpandedRowIds(
        newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds
      );
    },
    []
  );
  const getDetailPanelContent = React.useCallback(
    (params: GridRowParams) => {
      if (isEmpty(currentRoles)) return null; // Prevents rendering until roles are set
      return <DetailPanelContent row={params.row} />;
    },
    [currentRoles] // Forces re-render when roles are updated
  );

  const getDetailPanelHeight = React.useCallback(
    (params: GridRowParams) =>
      params.row.signedCOs.length === 1
        ? 250
        : params.row.signedCOs.length * 180,
    []
  );

  React.useEffect(() => {
    const fetchDocumentSummary = (
      paginationModel: {
        page: number;
        pageSize: number;
      },
      setData: any
    ) => {
      listDocumentSummary(
        {
          searchText: search,
          pagination: {
            pageNumber: page + 1,
            pageSize: pageSize,
          },
          showProcessed: processed,
          projectIds: communities.map((obj: any) => String(obj.projectId)),
        },
        (res: any) => {
          return setData(res.data);
        },
        (err: any) => {
          dispatch(dispatchError(err.response.data));
        }
      );
    };

    fetchDocumentSummary({ page: page, pageSize: pageSize }, setData);

    dispatch({
      type: "Navbar",
      payload: { title: `Change Order` },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processed, search, page, pageSize, dispatch, communities]);

  React.useEffect(() => {
    dispatch({
      type: "Navbar",
      payload: { title: "Executed Change Orders" },
    });
  }, []);

  React.useEffect(() => {
    setLoading(true);

    if (!isEmpty(state.isAdmin.grantedRoles)) {
      setCurrentRoles(state.isAdmin.grantedRoles);
    }
  }, [state.isAdmin.grantedRoles]);

  React.useEffect(() => {
    if (!isEmpty(currentRoles)) {
      setLoading(false);
    }
  }, [currentRoles]);
  return (
    <Container component="main" maxWidth={false}>
      <Stack
        gap={2}
        mt={2}
        mb={2}
        sx={{
          alignItems: "center",
          flexDirection: { xs: "column", sm: "column", lg: "row" },
          justifyContent: { xs: "center", md: "space-between" },
        }}
      >
        <Box gap={2} display={"flex"} alignItems={"center"}>
          <TextField
            sx={{
              minWidth: "20rem",
              width: "100%",
            }}
            fullWidth
            size="small"
            label="Search"
            variant="outlined"
            onChange={handleSearchChange}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GridSearchIcon color="info" />
                </InputAdornment>
              ),
            }}
          />

          <Autocomplete
            sx={{
              minWidth: "20rem",
              width: "100%",
            }}
            fullWidth
            multiple
            disableCloseOnSelect
            size="small"
            onChange={async (events, value) => {
              setPage(0);
              setCommunities(value);
            }}
            options={state.communities as CommunityTypes[]}
            getOptionLabel={(option) =>
              `${option.name} (${option.projectNumber})`
            }
            value={communities}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Community"
                fullWidth
              />
            )}
            filterOptions={(options, state) =>
              filterOptions(options, state, ["name", "projectNumber"])
            }
            renderOption={(props, item) => (
              <li {...props} key={item.projectId}>
                <ListItemText>{`${item.name} (${item.projectNumber})`}</ListItemText>
              </li>
            )}
          />
          <FormGroup
            sx={{
              width: "100%",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={processed}
                  onChange={(e, checked) => setProcessed(checked)}
                  inputProps={{ "aria-label": "Show Processed" }}
                />
              }
              label="Show Processed"
            />
          </FormGroup>
        </Box>
      </Stack>
      <Box sx={{ width: "100%", height: "100%" }}>
        {loading || isEmpty(currentRoles) ? (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <CircularProgress />
          </Box>
        ) : (
          <DataGridPro
            columns={columns}
            rows={data}
            getRowId={() => uniqueId()}
            getDetailPanelHeight={getDetailPanelHeight}
            getDetailPanelContent={getDetailPanelContent}
            onDetailPanelExpandedRowIdsChange={
              handleDetailPanelExpandedRowIdsChange
            }
            detailPanelExpandedRowIds={detailPanelExpandedRowIds}
            sx={{
              "& .MuiDataGrid-row--detailPanelExpanded": {
                backgroundColor: "#e3f2fd !important",

                "&:hover": {
                  backgroundColor: "#bbdefb !important",
                },
              },
            }}
          />
        )}
      </Box>
    </Container>
  );
}
