import { useState, useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import ReusableDialog from "../../components/Dialog";
import { UIState, UIContext, TemplateType } from "../../providers/UIProvider";
import {
  saveTemplate,
  SaveTemplateParams,
  saveQuoteTemplate,
  SaveQuoteTemplateParams,
} from "../../apiCalls";
import { Warning } from "@mui/icons-material";

interface SaveTemplateProps {
  disabled: boolean;
}

const SaveTemplate = (props: SaveTemplateProps) => {
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [templateName, setTemplateName] = useState<string>(
    state.currentTemplate?.name || ""
  );
  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId") || "";
  let quoteId = searchParams.get("quoteId") || "";

  const handleSaveSuccess = () => {
    setTemplateName("");
    dispatch({
      type: "Snackbar",
      payload: {
        show: true,
        message: "The Template has been saved successfully!",
        severity: "success",
      },
    });
  };

  const handleSaveError = (res: any) => {
    dispatch({
      type: "Snackbar",
      payload: {
        show: true,
        message: `${res.message} - ${res.response.statusText} -${res.response.data}`,
        severity: "error",
      },
    });
  };

  const saveCurrentTemplate = () => {
    let payload: SaveTemplateParams | SaveQuoteTemplateParams;
    if (intentionId) {
      payload = {
        intentionId,
        templateName,
        isPublic: false,
      };
      saveTemplate(payload, handleSaveSuccess, handleSaveError);
    }
    if (quoteId) {
      payload = {
        quoteId,
        templateName,
        isPublic: false,
      };
      saveQuoteTemplate(payload, handleSaveSuccess, handleSaveError);
    }
  };

  useEffect(() => {
    setTemplateName("");
  }, []);

  return (
    <ReusableDialog
      buttonVariant="outlined"
      buttonFullWidth={false}
      disabled={props.disabled}
      setIsOpen={(value) => setOpen(value)}
      isOpen={open}
      maxWidth={"md"}
      content={
        <>
          <DialogContent>
            <>
              <Box marginY={(theme) => theme.spacing(2)}>
                <TextField
                  className={
                    state.templates.some(
                      (template: TemplateType) => template.name === templateName
                    )
                      ? "textfield-warning"
                      : ""
                  }
                  fullWidth
                  helperText={
                    (state.templates.some(
                      (template: TemplateType) => template.name === templateName
                    ) && (
                      <>
                        <Warning /> <span>Template name already exists.</span>
                      </>
                    )) ||
                    `${templateName.length}/30 characters`
                  }
                  inputProps={{
                    maxLength: 30,
                    "data-testid": "input-box",
                  }}
                  label="Name"
                  onChange={(e) => setTemplateName(e.target.value)}
                  placeholder="Name (30 Character Limit)"
                  size="medium"
                  value={templateName}
                  variant="outlined"
                />
              </Box>
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setTemplateName("");
              }}
              variant="outlined"
              color="primary"
            >
              Exit
            </Button>
            <Button
              disabled={templateName.trim() === ""}
              onClick={() => {
                saveCurrentTemplate();
                setOpen(false);
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </>
      }
      buttonText="Save Template"
      title="Save Template"
    />
  );
};

export default SaveTemplate;
