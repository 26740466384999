import { useState, useContext, useEffect } from "react";
import { UIState, UIContext, OptionTypes, HighlandCategoryOption } from "../../providers/UIProvider";
import { numberWithCommas } from "../../utils/formatMoney";
import { removeFirstRowIfEmpty } from "../../common/fx";
import { mainTheme } from "../../styles/MainTheme";
import { Button, DialogContent, DialogActions, Stack } from "@mui/material";
import { Add, Check, CheckBoxOutlineBlank, PlaylistAdd, UnfoldLess, UnfoldMore, Remove } from "@mui/icons-material";
import ReusableDialog from "../../components/Dialog";
import "../../styles/ToggledGrids.scss";

interface StructuralGroupsProps {
  category: HighlandCategoryOption;
  onClose?: any;
  mode: "quote" | "edit" | "view";
};

interface GroupedOption {
  groupCode: string;
  salesPriceControlId: number;
}

interface OptionsGroup {
  groupCode: string;
  groupLabel: string;
  options: OptionTypes[];
  selectedOption: OptionTypes | null;
}

const copColumns = [
  { key: 'expander', class: 'icon' },
  { key: 'groupLabel' }
];

const coColumns = [
  { key: 'expander', class: 'icon' },
  { key: 'selected' },
  { key: 'optionCode' },
  { key: 'forced', class: 'icon' },
  { key: 'description'},
  { key: 'salesPrice', class: 'right' }
];

const StructuralGroups = (props:StructuralGroupsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [groups, setGroups] = useState<OptionsGroup[]>([]);
  const [openBlocks, setOpenBlocks] = useState<string[]>([]);

  useEffect(() => {
    const newGroups:Record<string, { groupLabel:string, options:OptionTypes[], selectedOption: OptionTypes|null }> = {}

    const categoryOptions = state.availableOptions
      .filter((availableOption: any) => availableOption.highlandCategory === "Structural");

    categoryOptions.forEach((item:OptionTypes) => {
      const groupCode = item.optionCode.split('-')[0];
      if(!newGroups[groupCode]) {
        newGroups[groupCode] = {
          groupLabel: item.category,
          options: [],
          selectedOption: null };
      }
      if(item.salesPriceControlID !== 0) {
        newGroups[groupCode].options.push(item);
        if(state.pendingItems.some((option:OptionTypes) => option.salesPriceControlID === item.salesPriceControlID)) {
          newGroups[groupCode].selectedOption = item;
        }
      }
    });

    setGroups(Object.entries(newGroups)
      .map(([groupCode, { groupLabel, options, selectedOption }]) => (
        {
          groupCode,
          groupLabel,
          options: options.sort((a,b) => a.optionCode.localeCompare(b.optionCode)),
          selectedOption
        })
      )
      .filter((group:OptionsGroup) => group.options.length > 0)
      .sort((a,b) => a.groupLabel.localeCompare(b.groupLabel))
    );
  }, [state.availableOptions, state.pendingItems]);

  const handleExpandAll = () => {
    setOpenBlocks(groups.map((group:OptionsGroup) => group.groupCode));
  }

  const handleCollapseAll = () => {
    setOpenBlocks([]);
  }

  const handleAddBlock = (groupId:string) => {
    if(openBlocks.includes(groupId)) return false;
    const newOpenBlocks = [...openBlocks];
    newOpenBlocks.push(groupId);
    setOpenBlocks(newOpenBlocks);
  }

  const handleRemoveBlock = (groupId:string) => {
    if(openBlocks.includes(groupId)) {
      const optionIx = openBlocks.indexOf(groupId);
      const newOpenBlocks = [...openBlocks];
      newOpenBlocks.splice(optionIx, 1);
      setOpenBlocks(newOpenBlocks);
    }
  }

  const handleSelectOption = (groupIx:number, option:OptionTypes) => {
    const newGroups = [...groups];
    newGroups[groupIx].selectedOption = option;
    console.log(newGroups);
    setGroups(newGroups);
  }

  const handleUnselect = (groupIx:number) => {
    const newGroups = [...groups];
    newGroups[groupIx].selectedOption = null;
    setGroups(newGroups);
  }

  const handleSelectForced = () => {
    const newGroups = [...groups];
    newGroups.forEach((group:OptionsGroup, gIx:number) => {
      const forcedOption = group.options.find((option:OptionTypes) => option.forcedOption);
      if(forcedOption) {
        newGroups[gIx].selectedOption = forcedOption;
      }
    });
    setGroups(newGroups);
  }
  
  const displayTable = () => {
    const rows:any[] = [];

    groups.forEach((group:OptionsGroup, groupIx:number) => {
      rows.push(
        <tr key={`option-group-${group.groupCode}`} className="parent">
          { copColumns.map((column: any) =>
            displayCell(group, column, "parent")
          )}
        </tr>
      );
      if(openBlocks.includes(group.groupCode)) {
        group.options.forEach((option:OptionTypes, rowIx:number) => {
          const isSelected = group.selectedOption !== null && option.salesPriceControlID === group.selectedOption.salesPriceControlID;
          rows.push(
            <tr
              className={`child${rowIx === group.options.length - 1 ? ' last' : ''}${
                isSelected ? ' selected' : ' eligible'}`}
              key={`conflicted-options-${option.salesPriceControlID}-${rowIx + 1}`}
              onClick={() =>
                group.selectedOption && group.selectedOption.salesPriceControlID === option.salesPriceControlID
                  ? handleUnselect(groupIx)
                  : handleSelectOption(groupIx, option)
              }
            >
              { coColumns.map((column:any) =>
                displayCell(option, column, isSelected ? "selected" : "child")
              )}
            </tr>
          )
        })
      }
    });
    return rows;
  }

  const displayCell = (option:any, column:any, type: "parent" | "child" | "selected") => {
    let cellValue;
    let cellWidth;
    switch(column.key) {
      case 'expander':
        if(type === "parent")
          if(openBlocks.includes(option.groupCode)) {
            cellValue = <div className="parent-toggle" onClick={() => handleRemoveBlock(option.groupCode)}>
                          <Remove />
                        </div>;
          } else {
            cellValue = <div
                          className={`parent-toggle${option.selectedOption ? " selected" : "" }`}
                          onClick={() => handleAddBlock(option.groupCode)}
                        >
                          { option.selectedOption === null ? <Add /> : <Check />}
                        </div>;
          }
        else
          cellValue = <span className="child-branch"></span>
        break;
      case 'selected':
        cellValue = type === "selected" ? <Check color="primary" /> : <CheckBoxOutlineBlank color="primary"  />;
        break;
      case 'groupLabel':
        cellValue = `${option.groupCode} | ${option[column.key]} (${option.options.length})`;
        cellWidth = 4;
        break;
      case 'forced':
        cellValue = option.forcedOption
          ? <span
              style={{
                color: mainTheme.palette.primary.main,
                fontWeight: "bold",
                fontStyle: "italic",
                fontSize: "18px",
              }}
              title="Forced Option"
            >F</span>
          : '';
        break;
      case 'optionCode':
      case 'description':
        cellValue = option[column.key];
        break;
      case 'salesPrice':
        cellValue = `$ ${numberWithCommas(option[column.key])}`;
        break;
      default:
        cellValue = option[column.key];
    }
    return (
      <td
        className={`${column.class ?? ''}`}
        key={`${option.salesPriceControlID ?? option.groupCode}-${column.key}`}
        id={`${option.salesPriceControlID ?? option.groupCode}-${column.key}`}
        colSpan={cellWidth}
      >
        {cellValue}
      </td>
    );
  }

  const handleClose = () => {
    setIsOpen(false);
    if(props.onClose) props.onClose();
  }

  const addOptions = () => {
    const newPendingItems = removeFirstRowIfEmpty([...state.pendingItems], props.category);
    const clearedPendingItems = newPendingItems
      .filter((item:OptionTypes) =>
        item.highlandCategory.key !== props.category.key || item.salesPriceControlID === 0
      );
    const hcOptions = groups
      .filter((group:OptionsGroup) => group.selectedOption !== null)
      .map((group:OptionsGroup, iIx:number):any => {
        const id = Math.random().toString(16).slice(2);
        return {
          id,
          category: group.selectedOption?.category || "",
          forcedOption: group.selectedOption?.forcedOption || false,
          highlandCategory: props.category,
          isInConflict: false,
          manualPrice: group.selectedOption?.manualPrice || false,
          option: group.selectedOption?.category || "",
          optionCode: group.selectedOption?.optionCode || "",
          description: group.selectedOption?.description || "",
          notes: "",
          quantity: 1,
          unitPrice: group.selectedOption?.salesPrice,
          isNew: true,
          salesPriceControlID: group.selectedOption?.salesPriceControlID || 0,
          totalPrice: group.selectedOption?.salesPrice,
          sortOrder: iIx + 1,
        };
      });
    
    const finalOptions = clearedPendingItems.concat(hcOptions);
    dispatch({
      type: props.mode === "edit" ? "ChangeOrderPending" : "QuotePending",
      payload: finalOptions,
      source: "Add Structural",
    });
    handleClose();
  };

  return (
    <ReusableDialog
      buttonVariant="outlined"
      buttonFullWidth={false}
      content={
        <>
          <DialogContent>
            <Stack
              alignItems={"center"}
              gap={2}
              flexDirection={"row"}
              sx={{
                width: "100%",
                justifyContent: { xs: "center", md: "space-between" },
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <p style={{ color: "#e56d29" }}>
                Select Structural Options:
              </p>
              <Stack
                alignItems={"center"}
                alignContent={"center"}
                gap={2}
                flexDirection={"row"}
              >
                <Button variant="text" onClick={handleExpandAll}>
                  <UnfoldMore /> Expand all
                </Button>{" "}
                |
                <Button variant="text" onClick={handleCollapseAll}>
                  <UnfoldLess /> Collapse All
                </Button>
              </Stack>
            </Stack>
            
            <table className="toggle-grid--table">
              <tbody>
                { displayTable() }
              </tbody>
            </table>
            <div style={{ marginTop: '16px', textAlign: 'right' }}>
              Total selected Structural Options: <strong>{groups.filter((group:OptionsGroup) => group.selectedOption !== null).length}</strong>
            </div>
          </DialogContent>
          <DialogActions>
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", width: "100%", px: "16px", mb: "12px" }}
            >
              <Button
                onClick={() => {
                  handleClose();
                }}
                variant="outlined"
                color="primary"
              >
                Cancel
              </Button>
              <Stack direction="row" spacing={2}>
                <Button
                  onClick={handleSelectForced}
                  variant="outlined"
                  color="primary"
                >
                  Select Forced Options
                </Button>
                <Button
                  disabled={groups.filter((group:OptionsGroup) => group.selectedOption !== null).length === 0}
                  onClick={addOptions}
                  variant="contained"
                  color="primary"
                >
                  Add Selected Options
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </>
        
      }
      icon={<PlaylistAdd />}
      isOpen={isOpen}
      maxWidth="lg"
      setIsOpen={(value) => setIsOpen(value)}
      toolTipTitle="Structural Options Wizard"
      title="Available Structural Options"
    />
  );
}

export default StructuralGroups;