import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  ElevationTypes,
  PlanTypes,
  UIContext,
  UIState,
} from "../../../providers/UIProvider";
import { isNil, isNull, uniqBy } from "lodash";
import { dispatchError } from "../../../common/fx";
import {
  getPlanElevations,
  getPlans,
  UpdateElevation,
} from "../../../apiCalls";
import useAccessControl from "../../../hooks/useAccessControl";

const Step1 = ({
  setNewHomeInformation,
  newHomeInformation,
  setSpecBuyer,
  currentPrice,
  setNumberOfSignatures,
  adjustPrice,
  setError,
  setAdjustPrice,
  numberOfSignatures,
  updateElevationMode,
}: any) => {
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const [priceDifference, setPriceDifference] = useState("");
  const GetPlanElevationsAccess = useAccessControl("Plan", "GetPlanElevations");

  useEffect(() => {
    getPlanElevations(
      {
        ProjectID: state.selectedJob.projectId,
        projectNumber: state.selectedJob.projectNumber,
        planId: state.selectedJob.planId,
        planNumber: state.selectedJob.planNumber,
      },
      (res: any) => {
        dispatch({
          type: "AvailableElevations",
          payload: res.data.sort(
            (
              a: {
                planElevationNumber: string;
              },
              b: {
                planElevationNumber: string;
              }
            ) => a.planElevationNumber.localeCompare(b.planElevationNumber)
          ),
        });
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        )
    );
    getPlans(
      {
        ProjectID: state.selectedJob.projectId,
        projectNumber: state.selectedJob.projectNumber,
      },
      (res: any) => {
        dispatch({
          type: "AvailablePlans",
          payload: res.data.sort(
            (
              a: {
                planNumber: string;
              },
              b: {
                planNumber: string;
              }
            ) => a.planNumber.localeCompare(b.planNumber)
          ),
        });
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  }, []);

  const calculatePriceDifference = (
    currentPrice: number,
    newPrice: number | string
  ): string => {
    const difference = Number(newPrice) - Number(currentPrice);
    setPriceDifference(difference.toString());
    return difference.toString();
  };

  useEffect(() => {
    calculatePriceDifference(currentPrice, newHomeInformation?.salesPrice);
  }, [currentPrice, newHomeInformation.salesPrice, adjustPrice]);

  const validateAdjustPrice = () => {
    const priceDiff = Math.abs(Number(priceDifference));

    const isError =
      (Number(priceDifference) > 0 &&
        (Number(adjustPrice) < -priceDiff || Number(adjustPrice) > 0)) ||
      (Number(priceDifference) < 0 &&
        (Number(adjustPrice) > priceDiff || Number(adjustPrice) < 0));

    setError(isError);
    return isError;
  };

  useEffect(() => {
    setAdjustPrice(0);
  }, [newHomeInformation.planElevationNumber]);

  return (
    <Stack gap={2}>
      <Autocomplete
        defaultValue={""}
        clearIcon={null}
        disabled={true}
        fullWidth
        multiple={false}
        options={uniqBy(state.availablePlans, (obj: PlanTypes) => {
          return obj.description;
        })}
        renderInput={(params: any) => (
          <TextField {...params} variant="outlined" label="Plan" fullWidth />
        )}
        size="medium"
        value={state.selectedJob.planNumber}
      />

      <Autocomplete
        multiple={false}
        fullWidth
        size="medium"
        clearIcon={null}
        defaultValue={""}
        value={newHomeInformation || null}
        onChange={(e, value) => {
          console.log(value);
          if (isNil(value)) {
            setNewHomeInformation({
              ...newHomeInformation,
              planElevationNumber: "",
            });
          } else
            setNewHomeInformation({
              ...newHomeInformation,
              ...value,
            });
        }}
        options={
          state.availableElevations.filter(
            (elevation: ElevationTypes) =>
              elevation.planElevationNumber !==
              state.selectedJob.planElevationNumber
          ) as ElevationTypes[]
        }
        getOptionLabel={(option) => `${option.planElevationNumber}`}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Elevation"
            fullWidth
          />
        )}
      />

      {!updateElevationMode && (
        <TextField
          margin="dense"
          id="adjustAmmount"
          label="Enter Elevation Change Price Adjustment (if needed)"
          placeholder="0"
          name="adjustAmount"
          type="text" // Keep as text to allow users to input decimals freely
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          value={adjustPrice}
          onChange={(e) => {
            const { value } = e.target;
            if (value === "" || /^-?\d*\.?\d*$/.test(value)) {
              setAdjustPrice(value);
            }
          }}
          disabled={Math.abs(Number(priceDifference)) === 0}
          error={validateAdjustPrice()}
          helperText={
            (Number(priceDifference) === 0 &&
              "$0 difference in elevation price") ||
            (Number(priceDifference) > 0 &&
              (Number(adjustPrice) < -Math.abs(Number(priceDifference)) ||
                Number(adjustPrice) > 0) &&
              `The amount must be between -$${Math.abs(
                Number(priceDifference)
              )} and $0`) ||
            (Number(priceDifference) < 0 &&
              (Number(adjustPrice) > Math.abs(Number(priceDifference)) ||
                Number(adjustPrice) < 0) &&
              `The amount must be between $0 and $${Math.abs(
                Number(priceDifference)
              )}`)
          }
        />
      )}
      {!updateElevationMode && (
        <Stack direction="column">
          <FormControl sx={{ minWidth: 100 }}>
            <InputLabel id="demo-simple-select-helper-label">
              Number Of Signatures
            </InputLabel>
            <Select
              value={numberOfSignatures}
              label="Number Of Signatures"
              onChange={(e) => setNumberOfSignatures(e.target.value as number)}
            >
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e, value) => {
                  setSpecBuyer(value);
                }}
              />
            }
            label="Change buyer to Spec"
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Step1;
