import { useState, useContext, useEffect, useRef, useCallback } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  BuyerTypes,
  CustomerBuyerTypes,
  ListQuoteBuyerInfo,
  QuoteSummary,
  UIContext,
  UIState,
} from "../../providers/UIProvider";
import {
  AddNewQuoteBuyer,
  findCustomerByNameSFQuote,
  getListQuoteForBuyer,
  retrieveUserInfo,
  UpdateBuyer,
} from "../../apiCalls";
import { debounce, isNil, isObject, isString } from "lodash";
import useAccessControl from "../../hooks/useAccessControl";
import { Edit, PersonAddAlt1Sharp } from "@mui/icons-material";
import { Stack } from "@mui/system";
import { getEnvironmentFromURL } from "../../utils/getEnviromentFromUrl";
import { ReactComponent as SalesforceIcon } from "../../icons/salesforce.svg";
import { ReactComponent as ChangeOrderIcon } from "../../icons/changeOrderIcon.svg";

import axios, { CancelTokenSource } from "axios";
import { dispatchError } from "../../common/fx";
import { useSearchParams, useNavigate } from "react-router-dom";

// import useAccessControl from "../../hooks/useAccessControl";

const EditQuoteBuyer = ({ fetchQuote }: { fetchQuote: () => void }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let quoteBuyerId = searchParams.get("quoteBuyerId");
  const [open, setOpen] = useState(false);
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [buyerLabel, setBuyerLabel] = useState("Buyer");
  const [buyerInfo, setBuyerInfo] = useState<ListQuoteBuyerInfo>();
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  const searchSalesForce = useCallback(
    debounce((value: string, cancelTokenSourceRef: any) => {
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel(
          "Operation canceled due to new request."
        );
      }

      const newCancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = newCancelTokenSource;

      try {
        findCustomerByNameSFQuote(
          {
            searchCriteria: value,
            projectID: state.createQuoteForm.community.projectId || "",
          },
          (res: any) => {
            if (res?.data) {
              dispatch({
                type: "AvailableBuyerQuoteNames",
                payload: res.data,
              });
            }
            setLoadingBuyerNames(false);
          },
          (err: any) => {
            console.error("API Error:", err);
          },
          newCancelTokenSource
        );
      } catch (err) {
        console.error("Promise Error:", err);
      }
    }, 2000),
    [state.createQuoteForm.community.projectId]
  );
  const cancelTokenSourceRef = useRef<any>(null);
  const handleInputChange = (e: any, value: string) => {
    if (value.length >= 3) {
      setLoadingBuyerNames(true);
      searchSalesForce(value, cancelTokenSourceRef);
      dispatch({
        type: "CreateQuoteForm",
        payload: {
          customerBuyerFreeForm: value,
        },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: "availableBuyerQuoteNames",
      payload: [],
    });
    dispatch({
      type: "ResetCreateQuoteForm",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editQuote = () => {
    UpdateBuyer(
      {
        quoteBuyerId: quoteBuyerId as string,
        firstName: buyerInfo?.firstName as string,
        lastName: buyerInfo?.lastName as string,
        email: buyerInfo?.email ?? "",
        phone: buyerInfo?.phone ?? "",
        SalesforceAccountId: (buyerInfo?.salesforceAccountId as string) ?? null,
      },
      (res: any) => {
        if (buyerInfo?.source === "ChangeOrder") {
          navigate(`/buyer-quote-summary?quoteBuyerId=${buyerInfo.id}`);
        } else {
          fetchQuote();
        }
        setOpen(false);
      },
      (err: any) => dispatch(dispatchError(err.response.data))
    );
  };

  return (
    <ReusableDialog
      icon={<Edit />}
      setIsOpen={(value) => setOpen(value)}
      isOpen={open}
      onChange={() =>
        dispatch({
          type: "ResetCreateQuoteForm",
        })
      }
      maxWidth={"md"}
      content={
        <>
          <DialogContent>
            <>
              <Box marginY={(theme) => theme.spacing(2)}>
                <Stack
                  direction={"row"}
                  gap={2}
                  alignContent={"center"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  justifyItems={"center"}
                >
                  <Autocomplete
                    fullWidth
                    multiple={false}
                    loading={loadingBuyerName}
                    size="medium"
                    value={buyerInfo as any}
                    sx={{
                      ".Mui-error": {
                        color: "green!important",
                        borderColor: "green!important",
                      },
                      ".Mui-error fieldset": {
                        borderColor: "green!important",
                      },
                      ".Mui-error input": {
                        color: "black",
                      },
                    }}
                    onChange={(e, value) => {
                      setBuyerInfo(value as ListQuoteBuyerInfo);
                    }}
                    onInputChange={handleInputChange}
                    options={state.availableBuyerQuoteNames}
                    getOptionLabel={(option: any) => {
                      const firstName = option.firstName || "";
                      const lastName = option.lastName || "";
                      const email = option.email
                        ? `- Email: ${option.email}`
                        : "";
                      const phone = option.phone
                        ? `Phone: ${option.phone}`
                        : "";

                      return `${firstName} ${lastName} ${email} ${phone}`.trim();
                    }}
                    renderOption={(props, option) => {
                      const firstName = option.firstName || "";
                      const lastName = option.lastName || "";
                      const email = option.email
                        ? `- Email: ${option.email}`
                        : "";
                      const phone = option.phone
                        ? `Phone: ${option.phone}`
                        : "";

                      return (
                        <li
                          {...props}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {`${firstName} ${lastName} ${email} ${phone}`.trim()}
                          {option.source === "Salesforce" ? (
                            <SalesforceIcon style={{ width: 32, height: 32 }} />
                          ) : (
                            <ChangeOrderIcon
                              style={{ width: 32, height: 32 }}
                            />
                          )}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        error={
                          isObject(state?.createQuoteForm?.customerBuyer) &&
                          state?.createQuoteForm?.customerBuyer
                            ?.salesforceAccountId
                        }
                        helperText={
                          isObject(state?.createQuoteForm?.customerBuyer) &&
                          state?.createQuoteForm?.customerBuyer
                            ?.salesforceAccountId &&
                          "Buyer in Salesforce"
                        }
                        label={buyerLabel}
                        fullWidth
                      />
                    )}
                  />
                  <Tooltip title="Create Salesforce Buyer">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setBuyerLabel(
                          "Search for newly added Salesforce buyer"
                        );
                        dispatch({
                          type: "CreateQuoteForm",
                          payload: {
                            customerBuyer: null,
                            buyerType: "buyer",
                          },
                        });
                        if (getEnvironmentFromURL() === "co") {
                          window.open(
                            "https://highlandhomes.lightning.force.com/lightning/o/Account/new?",
                            "_blank"
                          );
                        } else
                          window.open(
                            "https://highlandhomes--uat.sandbox.my.salesforce.com/lightning/o/Account/new?",
                            "_blank"
                          );
                      }}
                    >
                      <PersonAddAlt1Sharp />
                    </Button>
                  </Tooltip>
                </Stack>
              </Box>
            </>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                dispatch({
                  type: "ResetCreateQuoteForm",
                });
              }}
              variant="outlined"
              color="primary"
            >
              Exit
            </Button>
            <Button
              onClick={() => editQuote()}
              disabled={submitLoading}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </DialogActions>
        </>
      }
      buttonText=""
      title="Associate Buyer with Salesforce"
    />
  );
};

export default EditQuoteBuyer;
