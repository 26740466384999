import { useState, useContext, useEffect } from "react";
import { dispatchError } from "../../common/fx";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { mainTheme } from "../../styles/MainTheme";
import { Stack } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import ValidatedInput from "../../components/ValidatedInput";
import Pending from "./pending";
import {
  LineItems,
  OptionsCategory,
  HighlandCategoryOption,
  UIContext,
  UIState,
  ConstructionManagerTypes,
} from "../../providers/UIProvider";
import {
  convertToJob,
  downloadChangeOrderPDF,
  findCustomerByNameSF,
  getIntention,
  retrieveAllOptions,
  retrieveOptionCategories,
} from "../../apiCalls";
import EditFooter from "./EditFooter";
import { debounce } from "lodash";
import { JobCategory } from "./interfaces";
import Loading from "../../components/Loading";
import "./Edit.scss";
import { numberWithCommas } from "../../utils/formatMoney";
import useAccessControl from "../../hooks/useAccessControl";
import { Check, Close, PendingActionsOutlined } from "@mui/icons-material";
import moment from "moment";

export default function ArchivedView() {
  const GetIntentionAccess = useAccessControl("Intention", "GetIntention");
  const navigate = useNavigate();

  //const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let intentionId = searchParams.get("intentionId");
  let changeOrderId = searchParams.get("changeorderid");
  const [isChangeOrderLoaded, setIsChangeOrderLoaded] =
    useState<boolean>(false);
  const [state, dispatch] = useContext<UIState | any>(UIContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingBuyerName, setLoadingBuyerNames] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pendingCategories, setPendingCategories] = useState<JobCategory[]>([]);
  const [constructionManager, setConstructionManager] =
    useState<ConstructionManagerTypes | null>(null);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [isSummarySticky, setIsSummarySticky] = useState<boolean>(false);
  const [coAmount, setCoAmount] = useState<number>(0);
  const [lastSaved, setLastSaved] = useState<Date | null>(null);
  const FindCustomerByNameFromSFAccess = useAccessControl(
    "Customer",
    "FindCustomerByNameFromSF"
  );
  const RetrieveOptionsCategoryAccess = useAccessControl(
    "Option",
    "GetOptionsCategory"
  );
  const DownloadChangeOrderAccess = useAccessControl(
    "ChangeOrder",
    "DownloadPDF"
  );
  const RetrieveAllOptionsAccess = useAccessControl("Job", "GetAllOptions");

  const handleDownloadItems = () => {
    setDownloading(true);

    if (intentionId) {
      DownloadChangeOrderAccess &&
        downloadChangeOrderPDF(
          { changeOrderId: intentionId },
          async (res: {
            fileData: "string";
            contentType: "string";
            documentName: "string";
          }) => {
            function base64ToBlob(
              base64: string,
              contentType: string = ""
            ): Blob {
              // Convert Base64 to a byte array
              const byteCharacters = atob(base64);
              const byteArrays = [];

              for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += 512
              ) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                  byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
              }

              // Create a blob from the byte array
              return new Blob(byteArrays, { type: contentType });
            }

            function downloadPDF(
              documentName: string,
              contentType: string,
              fileData: string
            ) {
              // Convert Base64 fileData to Blob
              const blob = base64ToBlob(fileData, contentType);

              // Create a Blob URL
              const blobUrl = window.URL.createObjectURL(blob);

              // Create a link element
              const link = document.createElement("a");

              // Set the download attribute with a filename
              link.download = documentName;

              // Set the href to the blob URL
              link.href = blobUrl;

              // Append the link to the document body
              document.body.appendChild(link);

              // Programmatically click the link to trigger the download
              link.click();

              // Clean-up: remove the link from the document
              document.body.removeChild(link);
            }

            downloadPDF(res.documentName, res.contentType, res.fileData);
            setDownloading(false);
          },
          (err: any) => {
            setDownloading(false);
            dispatch(dispatchError(err?.response?.data));
          }
        );
    }
  };

  useEffect(() => {
    dispatch({ type: "ResetChangeOrder" });
    setIsChangeOrderLoaded(false);
    retrieveIntentionData();
    if (state.changeOrder.selectedCO) {
      setIsChangeOrderLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.changeOrder.status === "clear") {
      retrieveIntentionData();
    }
    if (state.changeOrder.status === "editing") {
      const listenersAreSet =
        window.onbeforeunload !== null ||
        window.onpopstate !== null ||
        window.onunload !== null;

      // If not, add event listeners
      if (listenersAreSet) {
        removeListeners();
      }
      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("popstate", handleBeforeUnload);
      window.addEventListener("unload", handleBeforeUnload);

      // Cleanup when component is unmounted:
      return () => {
        removeListeners();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.status, GetIntentionAccess]);

  useEffect(() => {
    if (lastSaved === null && lastSaved !== state.changeOrder.lastSaved) {
      retrieveIntentionData();
      setLastSaved(state.changeOrder.lastSaved);
    }
    if (lastSaved !== null && lastSaved !== state.changeOrder.lastSaved) {
      setLastSaved(state.changeOrder.lastSaved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.changeOrder.lastSaved]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 70;
      if (window.scrollY > scrollThreshold && !isSummarySticky) {
        setIsSummarySticky(true);
      } else if (window.scrollY <= scrollThreshold && isSummarySticky) {
        setIsSummarySticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSummarySticky]);

  useEffect(() => {
    console.log("ChangeOrder", state.changeOrder);
    FindCustomerByNameFromSFAccess &&
      handleBuyerSearch(
        `${state.changeOrder.customerBuyer?.buyerFirstName} ${state.changeOrder.customerBuyer?.buyerLastName}`
      );
    RetrieveOptionsCategoryAccess && retrieveChangeOrderData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.changeOrder.plan,
    state.changeOrder.community,
    state.changeOrder.elevation,
    state.changeOrder.address?.projectID,
    FindCustomerByNameFromSFAccess,
    RetrieveOptionsCategoryAccess,
  ]);

  useEffect(() => {
    if (state.changeOrder.cmName && state.constructionManagers.length > 0) {
      const currentCM = state.constructionManagers.find(
        (option: ConstructionManagerTypes) =>
          option.cmName === state.changeOrder.cmName
      );
      if (currentCM) setConstructionManager(currentCM);
    } else setConstructionManager(state.changeOrder.cmName);
  }, [state.changeOrder.cmName, state.constructionManagers]);

  useEffect(() => {
    setCoAmount(
      state.pendingItems
        .map((item: LineItems) => Number(item.quantity) * item.unitPrice)
        .reduce((acc: any, curr: any) => acc + curr, 0)
    );
  }, [state.pendingItems]);

  // Prevent exiting without confirming:
  const handleBeforeUnload = (event: any) => {
    if (
      state.changeOrder.lastSaved !== null ||
      state.changeOrder.status === "editing"
    ) {
      event.preventDefault();
    }
  };

  const removeListeners = () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
    window.removeEventListener("popstate", handleBeforeUnload);
    window.removeEventListener("unload", handleBeforeUnload);
  };

  const retrieveIntentionData = () => {
    if (intentionId) {
      GetIntentionAccess &&
        getIntention(
          intentionId,
          (res: any) => {
            const currentCO = res.data.intentionNumbers.filter(
              (obj: { isCurrent: boolean }) => obj.isCurrent === true
            )[0].number;

            console.log("intention retrieved");
            const payload = { ...state.changeOrder };
            payload.address = {
              address: res.data.sites[0]?.address,
              community: res.data.sites[0]?.communityName,
              jobID: res.data.sites[0]?.jobID,
              jobNumber: res.data.sites[0]?.jobNumber,
              planElevationId: res.data.sites[0]?.planElevationID,
              planElevationNumber: res.data.sites[0]?.planElevationNumber,
              planId: res.data.sites[0]?.planID,
              planNumber: res.data.sites[0]?.planNumber,
              projectID: res.data.sites[0]?.projectID,
              projectNumber: res.data.sites[0]?.projectNumber,
            };
            payload.cmId = res.data.sites[0]?.cmId;
            payload.cmName = res.data.sites[0]?.cmName;
            payload.community = {
              name: res.data.sites[0]?.communityName,
              projectId: res.data.sites[0]?.projectID,
              projectNumber: res.data.sites[0]?.projectNumber,
            };
            if (res.data.submittedAt === null) {
              const currentIntention = res.data.intentionNumbers.find(
                (intention: any) => intention.isCurrent
              );
              payload.currentIntention = currentIntention;
            }
            payload.customerBuyer = {
              buyerFirstName: res.data.buyers[0]?.firstName,
              buyerLastName: res.data.buyers[0]?.lastName,
              salesforceAccountId: res.data.buyers[0]?.salesforceAccountId,
              contractBuyerNames: res.data.buyers[0]?.legalName || null,
              connectionNumber: res.data.buyers[0]?.salesforceConnectionNumber,
            };
            payload.plan = {
              abbreviation: "",
              community: res.data.sites[0]?.communityName,
              description: "",
              planElevationID: res.data.sites[0]?.planElevationID.toString(),
              planElevationNumber: res.data.sites[0]?.planElevationNumber,
              planId: res.data.sites[0]?.planID.toString(),
              planNumber: res.data.sites[0]?.planNumber,
              projectID: res.data.sites[0]?.projectID,
              projectNumber: res.data.sites[0]?.projectNumber,
            };
            payload.keyword = res.data.keyword;
            payload.lot = res.data.sites[0]?.lot || "";
            payload.elevation = {
              planElevationID: res.data.sites[0]?.planElevationID,
              planElevationNumber: res.data.sites[0]?.planElevationNumber,
            };
            payload.planElevationOverride =
              res.data.sites[0]?.planElevationOverride;
            let currentSort: number;
            let currentHighlandCategory: string | null = null;
            const pendingItems = res.data.intentionOptions
              ? res.data.intentionOptions
                  .sort((objA: any, objB: any) => {
                    const hcA = state.highlandCategories.find(
                      (hc: HighlandCategoryOption) =>
                        hc.key === objA.highlandCategory
                    );
                    const hcB = state.highlandCategories.find(
                      (hc: HighlandCategoryOption) =>
                        hc.key === objB.highlandCategory
                    );
                    if (hcA.sort !== hcB.sort) return hcA.sort - hcB.sort;
                    if (objA.sortOrder !== objB.sortOrder)
                      return objA.sortOrder - objB.sortOrder;
                    if (objA.category !== objB.category)
                      return objA.category.localeCompare(objB.category);
                    return objA.description.localeCompare(objB.description);
                  })
                  .map((item: any, itemIx: number) => {
                    const newItem = { ...item };
                    newItem.highlandCategory = state.highlandCategories.find(
                      (category: HighlandCategoryOption) =>
                        category.key === item.highlandCategory
                    );
                    newItem.isNew = false;
                    newItem.option = item.category;
                    currentSort =
                      itemIx === 0 ||
                      item.highlandCategory !== currentHighlandCategory
                        ? 1
                        : currentSort + 1;
                    newItem.sortOrder = currentSort;
                    currentHighlandCategory = item.highlandCategory;
                    return newItem;
                  })
              : [];
            if (pendingItems.length > 0) {
              const lastSaved = new Date(res.data.createdAt);
              payload.lastSaved = lastSaved;
              setLastSaved(lastSaved);
            }
            payload.previousContractPrice = res.data.previousContractPrice || 0;
            payload.stage = res.data.sites[0]?.stage;
            payload.status = "saved";
            payload.createdAt = res.data.createdAt;
            payload.submittedAt = res.data.submittedAt;
            payload.startMemoEligible = res.data.startMemoEligible;
            payload.startMemoId = res.data.startMemoId;
            payload.approvalHistory = res.data.approvalHistory;
            payload.currentApprovalStatus = res.data.currentApprovalStatus;
            // Building used HighlandCategories out of data:
            const pendingCategories: HighlandCategoryOption[] = [];
            if (res.data.intentionOptions) {
              res.data.intentionOptions.forEach((item: any) => {
                if (
                  item.highlandCategory !== null &&
                  !pendingCategories.some(
                    (category: HighlandCategoryOption) =>
                      category.key === item.highlandCategory
                  )
                ) {
                  pendingCategories.push(
                    state.highlandCategories.find(
                      (category: HighlandCategoryOption) =>
                        category.key === item.highlandCategory
                    )
                  );
                }
              });
              pendingCategories.sort(
                (a: HighlandCategoryOption, b: HighlandCategoryOption) => {
                  if (a.sort < b.sort) return -1;
                  if (a.sort > b.sort) return 1;
                  return 0;
                }
              );
            }
            dispatch({
              type: "ChangeOrder",
              payload,
              source: "Edit page load",
            });
            dispatch({
              type: "PendingCategories",
              payload: pendingCategories,
            });
            dispatch({
              type: "ChangeOrderPending",
              payload: pendingItems,
              preventStatusChange: true,
            });

            //
            if (res.data.submittedAt === null) {
              dispatch({
                type: "Navbar",
                payload: {
                  title: "Pending Change Order : ",
                },
              });
            } else {
              dispatch({
                type: "Navbar",
                payload: {
                  title: `Submitted Change Order - ${currentCO} ${
                    res?.data?.sites[0]?.closingDate
                      ? `(Closing Date ${moment(
                          res?.data?.sites[0]?.closingDate
                        ).format("MM-DD-YYYY")})`
                      : ""
                  }
                `,
                },
              });
            }

            setIsChangeOrderLoaded(true);
          },
          (err: any) => {
            const errorMessage =
              err?.response?.data?.errors?.OperationCanceledException;
            if (
              (Array.isArray(errorMessage) &&
                errorMessage[0] ===
                  "Change order not found or has been deleted.") ||
              errorMessage === "Change order not found or has been deleted."
            ) {
              navigate("/");
            }

            dispatch(
              dispatchError({
                message: err?.message,
                statusText: err?.response?.statusText,
                title: err?.response?.data.title,
                status: err?.response?.status,
                detail: err?.response?.data.detail,
                data: err?.response?.data,
              })
            );
          }
        );
    }
  };

  const retrieveChangeOrderData = () => {
    if (
      state?.changeOrder?.plan?.planId &&
      state?.changeOrder?.community?.projectId &&
      state?.changeOrder?.elevation?.planElevationID
    ) {
      retrieveOptionCategories(
        {
          PlanId: state.changeOrder.plan.planId,
          ProjectId: state.changeOrder.community.projectId,
          PlanElevationId: state.changeOrder.elevation.planElevationID,
        },
        (res: any) => {
          // build the category catalog:
          const newOptionsCategory = res.data.map((obj: OptionsCategory) => ({
            ...obj,
          }));
          state.highlandCategories.forEach(
            (highlandCategory: HighlandCategoryOption) => {
              newOptionsCategory.push({
                option: "Custom Option",
                highlandCategory: highlandCategory.key,
                category: "Custom Option",
              });
            }
          );
          dispatch({
            type: "AvailableOptionCategories",
            payload: newOptionsCategory,
          });
        },
        (err: any) => {
          dispatch(dispatchError(err?.response?.data));
          dispatch(
            dispatchError({
              message: err?.message,
              statusText: err?.response?.statusText,
              title: err?.response?.data.title,
              status: err?.response?.status,
              detail: err?.response?.data.detail,
              data: err?.response?.data,
            })
          );
        }
      );

      RetrieveAllOptionsAccess &&
        retrieveAllOptions(
          {
            PlanId: state.changeOrder.plan.planId,
            ProjectId: state.changeOrder.community.projectId,
            PlanElevationId: state.changeOrder.elevation.planElevationID,
          },
          (res: any) => {
            const newOptions = res.data;
            state.highlandCategories.forEach(
              (highlandCategory: HighlandCategoryOption) => {
                newOptions.push({
                  option: "Custom Option",
                  description: "",
                  highlandCategory: highlandCategory.key,
                  category: highlandCategory.key.toUpperCase(),
                  optionAbbreviation: "Custom Option",
                  optionCategoryID: 0,
                  optionCode: "",
                  optionId: 0,
                  salesPrice: "",
                  salesPriceControlID: 0,
                });
              }
            );
            dispatch({
              type: "AvailableOptions",
              payload: newOptions,
            });
          },
          (err: any) =>
            dispatch(
              dispatchError({
                message: err?.message,
                statusText: err?.response?.statusText,
                title: err?.response?.data.title,
                status: err?.response?.status,
                detail: err?.response?.data.detail,
                data: err?.response?.data,
              })
            )
        );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, react-hooks/exhaustive-deps
  const handleBuyerSearch = debounce((value: string) => {
    findCustomerByNameSF(
      {
        BuyerName: value,
        CommunityName: state.changeOrder.community?.name
          ? state?.changeOrder?.community?.name
          : "",
      },
      (res: any) => {
        dispatch({
          type: "AvailableBuyerNames",
          payload: res.data,
        });
        setLoadingBuyerNames(false);
      },
      (err: any) =>
        dispatch(
          dispatchError({
            message: err?.message,
            statusText: err?.response?.statusText,
            title: err?.response?.data.title,
            status: err?.response?.status,
            detail: err?.response?.data.detail,
            data: err?.response?.data,
          })
        )
    );
  }, 1000);

  return (
    <ThemeProvider theme={mainTheme}>
      <Container
        sx={{
          my: mainTheme.spacing(2),
        }}
        component="main"
        maxWidth={false}
      >
        <CssBaseline />
        {isChangeOrderLoaded &&
        state.availableOptions.length > 0 &&
        state.availableOptionCategories.length > 0 ? (
          <>
            <Stack
              className={`summaries-wrapper${isSummarySticky ? " sticky" : ""}`}
              sx={{
                width: "100%",
                height: "100%",
              }}
              gap={2}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Stack
                alignItems={"center"}
                alignContent={"center"}
                gap={2}
                flexDirection={"row"}
                sx={{
                  alignItems: "top",
                  border: 1,
                  borderColor: (mainTheme) => mainTheme.palette.grey[300],
                  borderTop: 4,
                  borderRadius: "10px",
                  width: "100%",
                  borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
                  justifyContent: { xs: "center", md: "space-between" },
                  flexDirection: { xs: "column", md: "row" },
                  maxWidth: { xs: "100%", lg: "60%" },
                  py: 0.5,
                  px: 2,
                }}
              >
                <div className="summary-details">
                  <p style={{ margin: "0 auto .5rem" }}>
                    <strong>Buyer</strong>
                  </p>
                  <p className="summary-details--info">
                    <ValidatedInput
                      className="summary-details--editor"
                      //dropdownData={state.availableBuyerNames || ""}
                      //editTitle="Assign a buyer from SalesForce"
                      inputVariant="standard"
                      isVerified={
                        !!state.changeOrder.customerBuyer?.salesforceAccountId
                      }
                      mode="readonly"
                      nonVerifiedTitle="This C.O. doesn't has a buyer assigned yet"
                      optionLabel={(option) => {
                        if (option.contractBuyerNames === "Spec") {
                          return `${option.contractBuyerNames}`;
                        } else if (
                          option.contractBuyerNames &&
                          option.connectionNumber
                        ) {
                          return `${option.buyerFirstName} ${option.buyerLastName}`.trim();
                        } else if (typeof option === "string") {
                          return option;
                        }
                        return `${option.buyerFirstName} ${option.buyerLastName}`.trim();
                      }}
                      type="autocomplete" // switch to autocomplete if the editor function gets activated
                      title={
                        state.changeOrder.customerBuyer?.contractBuyerNames
                      }
                      value={state.changeOrder.customerBuyer}
                      verify={true}
                      verifiedTitle="Buyer in SalesForce"
                      //saveTitle="Assign this Buyer to the C.O."
                    />
                  </p>
                </div>
                <div className="summary-details">
                  <p className="summary-details--title">Community</p>
                  <p className="summary-details--info">
                    {state.changeOrder.community?.name || null}
                  </p>
                </div>
                <div className="summary-details">
                  <p className="summary-details--title">Address</p>
                  <p className="summary-details--info">
                    <ValidatedInput
                      className="summary-details--editor"
                      dropdownData={state.availableAddresses}
                      editTitle="Assign an address from BRIX"
                      finalLabel={(value) => {
                        if (typeof value === "string") return value;
                        return value.address;
                      }}
                      inputVariant="standard"
                      isVerified={!!state.changeOrder.address?.jobID}
                      mode={
                        state.changeOrder.address?.jobID ? "readonly" : "editor"
                      }
                      nonVerifiedTitle="this C.O. doesn't have assigned an address from BRIX yet"
                      onChange={(newAddress) => {
                        convertToJob(
                          {
                            intention: intentionId as string,
                            jobId: newAddress.jobID ? newAddress.jobID : 0,
                            jobNumber: newAddress.jobNumber,
                          },
                          (res: any) => {
                            dispatch({
                              type: "ChangeOrder",
                              payload: {
                                address: newAddress,
                                lastSaved: new Date(),
                              },
                              source: "converToJob",
                            });
                          },
                          (err: any) =>
                            dispatch(dispatchError(err?.response?.data))
                        );
                      }}
                      optionDisabled={(option: any) =>
                        option.planId !== state.changeOrder.plan?.planId ||
                        option.planElevationId !==
                          state.changeOrder.plan?.planElevationID
                      }
                      optionLabel={(option) => {
                        if (typeof option === "string") return option;
                        if (option.address === "TBD / Preliminary")
                          return option.address;
                        return option && option.address !== null
                          ? `${option.address} (${option.planNumber}-${option.planElevationNumber})`
                          : "";
                      }}
                      type="autocomplete"
                      title=""
                      value={state.changeOrder.address}
                      verify={true}
                      verifiedTitle="Address in BRIX"
                      saveTitle="Assign this Address to the C.O."
                    />
                  </p>
                </div>
                <div className="summary-details">
                  <p className="summary-details--title">Plan / Elevation</p>
                  <p className="summary-details--info">
                    {state.changeOrder.plan?.planNumber || null} /&nbsp;
                    {state.changeOrder.elevation?.planElevationNumber || null}
                  </p>
                </div>
                <div className="summary-details">
                  <p className="summary-details--title">Stage / CM</p>
                  <p className="summary-details--info">
                    {state.changeOrder.stage === null
                      ? ""
                      : state.changeOrder.stage}{" "}
                    /&nbsp;
                    <ValidatedInput
                      className="summary-details--editor"
                      dropdownData={state.constructionManagers}
                      editTitle="Assign a Construction Manager"
                      inputVariant="standard"
                      isVerified={constructionManager !== null}
                      mode="readonly"
                      optionLabel={(option) => {
                        if (option) {
                          if (typeof option === "string") return option;
                          if (option === null) return "--";
                          return option.cmName;
                        }
                      }}
                      type="autocomplete"
                      value={constructionManager}
                      verify={false}
                      verifiedTitle="Construnction Manager assigned"
                      saveTitle="Assign this Construction Manager to the C.O."
                    />
                  </p>
                </div>
                <div className="summary-details">
                  <p className="summary-details--title">Ops Status</p>
                  <p className="summary-details--info">
                    {state.changeOrder.currentApprovalStatus === "Approved" ? (
                      <Check color="success" />
                    ) : state.changeOrder.currentApprovalStatus ===
                      "FurtherActionNeeded" ? (
                      <Close color={"error"} />
                    ) : (
                      <PendingActionsOutlined color="warning" />
                    )}
                    {state.changeOrder.currentApprovalStatus}
                  </p>
                </div>
              </Stack>
              <Stack
                alignItems={"center"}
                alignContent={"center"}
                gap={2}
                flexDirection={"row"}
                sx={{
                  width: "100%",
                  alignItems: "top",
                  border: 1,
                  borderColor: (mainTheme) => mainTheme.palette.grey[300],
                  borderTop: 4,
                  borderRadius: "10px",
                  borderTopColor: (mainTheme) => mainTheme.palette.primary.main,
                  justifyContent: { xs: "center", md: "space-between" },
                  flexDirection: { xs: "column", md: "row" },
                  maxWidth: { xs: "100%", lg: "35%" },
                  py: 0.5,
                  px: 2,
                }}
              >
                <div>
                  <p style={{ margin: "0 auto .5rem" }}>
                    <strong>C.O. Amount</strong>
                  </p>
                  <p style={{ margin: "0 auto", textAlign: "right" }}>
                    $ {numberWithCommas(coAmount)}
                  </p>
                </div>
                <div>
                  <p style={{ margin: "0 auto .5rem" }}>
                    <strong>Previous Amount</strong>
                  </p>
                  <p style={{ margin: "0 auto", textAlign: "right" }}>
                    ${" "}
                    {numberWithCommas(state.changeOrder.previousContractPrice)}
                  </p>
                </div>
                <div>
                  <p style={{ margin: "0 auto .5rem" }}>
                    <strong>New Amount</strong>
                  </p>
                  <p style={{ margin: "0 auto", textAlign: "right" }}>
                    ${" "}
                    {numberWithCommas(
                      state.changeOrder.previousContractPrice + coAmount
                    )}
                  </p>
                </div>
              </Stack>
            </Stack>

            <div className={`pending-main${isSummarySticky ? " sticky" : ""}`}>
              <Pending
                setCategories={setPendingCategories}
                changeOrderId={changeOrderId as string}
              />
            </div>
            <EditFooter handleDownloadItems={handleDownloadItems} />
          </>
        ) : (
          <Loading />
        )}
      </Container>
    </ThemeProvider>
  );
}
