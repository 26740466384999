import React, { useContext, useEffect, useState } from "react";
import { Signatory, UIContext, UIState } from "../../providers/UIProvider";
import { Box, Stack } from "@mui/system";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

type Props = {
  type: "Spec" | any;
  setSignatories: (signature: Signatory[]) => void;
  signatories: Signatory[];
  buyerCount: number;
  setBuyerCount: (buyerCount: number) => void;
};

const Index = ({
  setSignatories,
  signatories,
  buyerCount,
  setBuyerCount,
  type,
}: Props) => {
  const [state] = useContext<UIState | any>(UIContext);

  useEffect(() => {
    if (state.isAdmin.userPrincipalName && state.changeOrder.customerBuyer) {
      const newSignatories: Signatory[] = [];
      const newSignatorie: Signatory = {
        name: state.isAdmin.name,
        email: state.isAdmin.userPrincipalName,
        nameError: false,
        emailError: false,
      };
      newSignatories.push(newSignatorie);
      for (let bc = 1; bc <= buyerCount; bc++) {
        let name = "";
        let names = [];
        if (state?.changeOrder?.customerBuyer?.contractBuyerNames) {
          names = state?.changeOrder?.customerBuyer?.contractBuyerNames.split(
            /\s*(?:&|\s+and\s+)\s*/
          );
        }
        if (bc === 1 && state.changeOrder.customerBuyer) {
          name = `${state.changeOrder.customerBuyer.buyerFirstName} ${state.changeOrder.customerBuyer.buyerLastName}`;
        }
        if (bc > 1 && bc <= names.length) {
          name = names[bc - 1];
        }
        const newSignatorie: Signatory = {
          name,
          email: "",
          nameError: false,
          emailError: false,
        };
        newSignatories.push(newSignatorie);
      }
      setSignatories(newSignatories);
    }
  }, [state.isAdmin, state.changeOrder.customerBuyer, buyerCount]);

  const validateSignerName = (signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].nameError = false;
    if (!(newSignatories[signerIx].name.trim().length > 0)) {
      newSignatories[signerIx].nameError = "Please provide a valid name";
    }
    setSignatories(newSignatories);
  };

  const updateSignerName = (newName: string, signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].name = newName;
    setSignatories(newSignatories);
  };

  const updateSignerEmail = (newEmail: string, signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].email = newEmail;
    setSignatories(newSignatories);
  };

  const validateSignerEmail = (signerIx: number) => {
    const newSignatories = [...signatories];
    newSignatories[signerIx].emailError = false;
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(signatories[signerIx].email)) {
      newSignatories[signerIx].emailError =
        "Please provide a valid email address";
    }
    if (
      signerIx === 0 &&
      !signatories[0].email.endsWith("@highlandhomes.com")
    ) {
      newSignatories[signerIx].emailError =
        "Authorized signature must come from a valid Highland Homes email address";
    }
    setSignatories(newSignatories);
  };

  const renderEmailFields = () => {
    return signatories.map((signer: Signatory, signerIx: number) => (
      <Box
        component="fieldset"
        key={`signer-box-${signerIx}`}
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: 2,
          mt: 2,
          px: 2,
          py: 1,
          position: "relative",
        }}
      >
        <Box component="legend" sx={{ px: 1, fontSize: "0.75rem" }}>
          {signerIx === 0 ? "Authorizing" : `Buyer ${signerIx}:`}
        </Box>
        <FormControl fullWidth>
          <TextField
            error={signer.nameError !== false}
            label={
              signerIx === 0 ? "Authorizing name" : `Buyer ${signerIx} name:`
            }
            helperText={signer.nameError}
            id={`buyer-name-${signerIx}`}
            fullWidth
            value={signer.name}
            onBlur={() => validateSignerName(signerIx)}
            onChange={(event: any) =>
              updateSignerName(event.target.value, signerIx)
            }
            size="small"
            sx={{ backgroundColor: "white" }}
            type="email"
            required
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1.5 }}>
          <TextField
            error={signer.emailError !== false}
            label={
              signerIx === 0 ? "Authorizing email" : `Buyer ${signerIx} email:`
            }
            helperText={signer.emailError}
            id={`buyer-email-${signerIx}`}
            fullWidth
            value={signer.email}
            onBlur={() => validateSignerEmail(signerIx)}
            onChange={(event: any) =>
              updateSignerEmail(event.target.value, signerIx)
            }
            size="small"
            sx={{ backgroundColor: "white" }}
            type="email"
            required
          />
        </FormControl>
      </Box>
    ));
  };

  useEffect(() => {
    if (type === "Spec") {
      setBuyerCount(0);
    } else setBuyerCount(1);
  }, [type]);

  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      spacing={3}
      useFlexGap
    >
      {
        <Stack
          sx={{
            backgroundColor: "rgba(0,0,0,.04)",
            border: "1px solid #E0E0E0",
            mt: 2,
            width: "100%",
            mb: 2,
            px: 3,
            pt: 2,
          }}
        >
          <h4 style={{ margin: 0 }}>
            {type !== "Spec"
              ? "Select the number of buyer signatures required"
              : ""}
          </h4>
          {type !== "Spec" && (
            <FormControl sx={{ mt: 1, width: "284px" }}>
              <InputLabel id="buyer-signatures-label">
                Number of Buyer signatures
              </InputLabel>
              <Select
                label="Number of Buyer signatures"
                labelId="subyer-signatures-label"
                id="buyer-signatures"
                value={buyerCount}
                onChange={(event: any) =>
                  setBuyerCount(parseInt(event.target.value))
                }
                size="small"
                sx={{
                  backgroundColor: "white",
                  textAlign: "right",
                  width: "185px",
                }}
              >
                {[1, 2, 3, 4].map((bc: number) => (
                  <MenuItem key={`mi-${bc}`} value={bc}>
                    {bc}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {renderEmailFields()}
        </Stack>
      }
    </Stack>
  );
};

export default Index;
