import { useEffect, useState, useContext } from "react";
import { OptionTypes } from "../../providers/UIProvider";
import Box from "@mui/material/Box";
import { ReportProblem } from "@mui/icons-material";
import { Stack, Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UIContext, UIState } from "../../providers/UIProvider";
import useAccessControl from "../../hooks/useAccessControl";
import {
  changeOrderOptions,
  CloneQuote,
  convertQuoteToCO,
  modifyQuoteOptions,
  updateQuote
} from "../../apiCalls";
import { isEmpty, omit } from "lodash";
//import QuotePDFPreview from "../../modals/QuotePDFPreview";
import SaveTemplate from "../../modals/SaveTemplate";
import { handleUnchangedNavigation, dispatchError } from "../../common/fx";
import DiscardQuoteConfirmation from "../../modals/DiscardQuoteConfirmation";
import AssignJobToQuote from "../../modals/AssignJobToQuote";
import QuoteProposeWarning from "../../modals/QuoteProposeWarning";

const QuoteFooter = () => {
  const navigate = useNavigate();
  //const theme = useTheme();

  const [searchParams] = useSearchParams();
  let quoteId = searchParams.get("quoteId");

  const [state, dispatch] = useContext<UIState | any>(UIContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [PDFUrl, setPDFUrl] = useState<string>("");
  const [isReadyForSave, setIsReadyForSave] = useState<boolean>(false);
  const [incompleteOptions, setIncompleteOptions] = useState<number>(0);
  const [isIncomplete, setIsIncomplete] = useState<boolean>(false);
  const [saveRequirements, setSaveRequirements] = useState<string>("");
  const [showDiscardConfirmation, setShowDiscardConfirmation] = useState<boolean>(false);
  const [isConvertToCODisabled, setIsConvertToCODisabled] = useState<boolean>(true);
  const [disabledToolTip, setDisabledToolTip] = useState<string>("");
  const templatesAllowed = useAccessControl("OptionTemplate", "GetTemplates");
  const cloneQuoteAllowed = useAccessControl("Quote", "CloneQuote");
  const saveQuoteAllowed = useAccessControl("Quote", "ModifyQuoteOptions");
  const convertToCOAllowed = useAccessControl("Quote", "ConvertQuoteToIntention");
  const proposeAllowed = useAccessControl("Quote", "ProposeQuote");

  useEffect(() => {
    setIncompleteOptions(
      state.pendingItems.filter(
        (item: OptionTypes) =>
          !item.category ||
          item.category === "" ||
          ((!item.description ||
          item.description === "") &&
          item.option !== "Formatting Separator")
      ).length
    );
  }, [state.pendingItems]);

  useEffect(() => {
    setIsIncomplete(incompleteOptions > 0);
    if (incompleteOptions > 0)
      setSaveRequirements(
        "You have option line(s) that are incomplete. Please complete or delete the option line(s) to Save."
      );
    else setSaveRequirements("");
    const newIsReadyForSave = !(
      state.quote.status !== "editing" || incompleteOptions > 0 || !saveQuoteAllowed
    );
    setIsReadyForSave(newIsReadyForSave);
  }, [state.quote.status, incompleteOptions]);

  const handleExit = () => {
    // is the order completely empty? Maybe the user wants to discard it:
    if((incompleteOptions === state.pendingItems &&
      state.pendingItems.length > 0) ||
     state.pendingItems.length === 0) {
      setShowDiscardConfirmation(true);
     } else {
      // Is the order unchanged?
      handleUnchangedNavigation(
        `/buyer-quote-summary?quoteBuyerId=${state.quote.quoteBuyer.quoteBuyerId}`,
        state.quote.status,
        navigate,
        dispatch,
        () => {
          // So it's unchanged, the user wants to leave,
          // but if all the items are new, that means it will become empty...
          // therefore, maybe the users wants to discard it?
          if(state.pendingItems
              .filter((item:OptionTypes) => item.isNew)
              .length === state.pendingItems.length) {
            setShowDiscardConfirmation(true);
          } else {
            navigate(-1);
            dispatch({
              type: "ResetQuote",
            });
            dispatch({
              type: "ResetCreateQuoteForm",
            });
          }
        }
      );
     }
  }

  const saveQuote = (
    redirect?: boolean
  ) => {
    dispatch({
      type: "Quote",
      payload: {
        lastSaved: null,
        status: "blocked",
      },
      source: "Edit Page save",
    });

    const options = state.pendingItems
    ? state.pendingItems
        .filter((obj: changeOrderOptions) => !(isEmpty(obj.description) && obj.option !== "Formatting Separator"))
        .map((obj: changeOrderOptions) => {
          const newObj = { ...obj };
          if (
            newObj.category === "Manual Option" &&
            newObj.optionCode === null
          ) {
            newObj.optionCode = "";
          }
          newObj.highlandCategory = obj.highlandCategory.key;
          newObj.salesPrice = (
            newObj.quantity * newObj.unitPrice
          ).toFixed(2);
          return newObj.isNew ? omit(newObj, "id") : newObj;
        })
    : null;
    modifyQuoteOptions(
      quoteId as string,
      options,
      (res: any) => {
        dispatch({
          type: "Quote",
          payload: {
            lastSaved: Date.now(),
          },
          source: "Edit page Print",
        });
        redirect && navigate(`/buyer-quote-summary?quoteBuyerId=${state.quote.quoteBuyer.quoteBuyerId}`);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `The Quote has been saved successfully!`,
            severity: "success",
          },
        });
      },
      (err: any) => {
        dispatch(
          dispatchError({
            message: err.message,
            statusText: err.response.statusText,
            title: err.response.data.title,
            status: err.response.status,
            detail: err.response.data.detail,
            data: err.response.data,
          })
        );
      }
    );
  };

  const cloneQuote = () => {
    CloneQuote(
      state.quote.id,
      (res:any) => {
        const clonedQuoteId = res.data;
        let clonedQuoteKeyword = `Cloned - ${state.quote.quoteBuyer.firstName} ${state.quote.quoteBuyer.lastName}`;
        clonedQuoteKeyword = clonedQuoteKeyword.substring(0, 25);
        updateQuote(
          clonedQuoteId,
          {
            quoteKeyword: clonedQuoteKeyword
          },
          (res:any) => {
            navigate(`/quote?quoteId=${clonedQuoteId}`);
          },
          (err:any) => dispatch(dispatchError(err.response.data))
        )
      },
      (err:any) => dispatch(dispatchError(err.response.data))
    );
  }

  const createCO = () => {
    convertQuoteToCO(
      state.quote.id,
      (res:any) => {
        console.log('convertQuote', res);
        dispatch({
          type: "Snackbar",
          payload: {
            show: true,
            message: `The Quote ${
              state.quote.address && state.quote.address.address} has been converted successfully!`,
            severity: "success",
          },
        });
        dispatch({ type: "ResetChangeOrder" });
        navigate(`/edit?intentionId=${res.data}`);
      },
      (err:any) => {
        const errorMessage = err.response.data.errors.OperationCanceledException[0] ?? err.message;

        if(errorMessage === "This Quote has already been converted to a Change Order.") {
          dispatch({
            type: "Snackbar",
            payload: {
              duration: 5000,
              show: true,
              message: (
                <div
                  style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}
                >
                  This quote has already been converted to a Change Order
                  <Button
                    onClick={() => {
                      window.open(
                        `/edit?intentionId=${state.quote.intentionId}`
                      );
                    }}
                    variant="outlined"
                    sx={{
                      color: "white",
                      borderColor: "white",
                      marginLeft: "8px",
                    }}
                  >
                    Go to Change Order
                  </Button>
                </div>
              ),
              severity: "warning",
            },
          })
        } else {
          dispatch(
            dispatchError({
              statusText: err.response.statusText,
              title: err.response.data.title,
              status: err.response.status,
              detail: errorMessage,
              data: err.response.data,
            })
          );
        }
      }
    )
  }

  const isSaveTemplateDisabled = () => {
    return (
      state.quote.lastSaved === null ||
      state.quote.status === "editing" ||
      state.pendingItems.length === 0 ||
      state.pendingItems.filter((item: OptionTypes) => item.isNew)
        .length > 0
    );
  };

  useEffect(() => {
    const result = 
      !convertToCOAllowed ||
      state.quote.lastSaved === null ||
      !state.quote.quoteBuyer?.salesforceAccountId ||
      !state.quote.quoteBuyer?.salesforceOpportunityId ||
      !state.quote.address.jobId ||
      state.quote.status === "editing" ||
      state.pendingItems.length === 0 ||
      state.pendingItems.filter((item: OptionTypes) => item.isNew)
        .length > 0 ||
      state.quote.updatedPrices.length > 0;
    setIsConvertToCODisabled(result);
    if(result) {
      let newToolTip = "";
      if(!state.quote.quoteBuyer || !state.quote.quoteBuyer.salesforceAccountId) {
        newToolTip = "Verify Buyer";
      }
      if(!state.quote.quoteBuyer || !state.quote.quoteBuyer.salesforceOpportunityId) {
        if(newToolTip !== "") newToolTip += "\r";
        newToolTip += "Verify Salesforce Connection";
      }
      setDisabledToolTip(newToolTip);
    } else {
      setDisabledToolTip("");
    }
  }, [state.quote.lastSaved, state.quote.quoteBuyer, state.quote.address, state.quote.status, state.pendingItems]);

  return (
    <>
      <Stack
        className="edit-footer"
        justifyContent={"space-between"}
        sx={{
          width: "100%",
          flexDirection: "row",
          marginTop: "0!important",
          alignItems: "center",
        }}
      >
        <Box width={"100%"} display={"flex"} alignItems={"center"}>
          <Button
            onClick={handleExit}
            variant="outlined"
          >
            Exit
          </Button>
          {templatesAllowed && (
            <>
              <span style={{ margin: "0 20px" }}>|</span>
              <SaveTemplate disabled={isSaveTemplateDisabled()} />
            </>
          )}
        </Box>

        <Box
          width={"100%"}
          gap={2}
          display={"flex"}
          justifyContent={"flex-end"}
          alignItems={"center"}
        >
          {isIncomplete && (
            <span title={saveRequirements} style={{ height: "1.5rem" }}>
              <ReportProblem sx={{ color: "#e56d29" }} />
            </span>
          )}
          { 
            state.quote.quoteStatus === "Converted" &&
            <>
              <Button
                disabled={!cloneQuoteAllowed ||
                  state.pendingItems.filter((item: OptionTypes) => !item.isNew).length === 0}
                onClick={() => cloneQuote()}
                variant="contained"
              >
                Clone
              </Button>
              <Button
                onClick={() => {
                  navigate(`/edit?intentionId=${state.quote.intentionId}`);
                }}
                variant="contained"
              >
                Go to CO
              </Button>
            </>
          }
          {
            state.quote.quoteStatus !== "Converted" &&
            <>
              <Button
                disabled={!isReadyForSave}
                onClick={() => saveQuote(false)}
                variant="contained"
              >
                Save
              </Button>
              <Button
                disabled={!isReadyForSave}
                onClick={() => saveQuote(true)}
                variant="contained"
              >
                Save & Exit
              </Button>
              <Button
                disabled={!cloneQuoteAllowed ||
                  state.pendingItems.filter((item: OptionTypes) => !item.isNew).length === 0}
                onClick={() => cloneQuote()}
                variant="contained"
              >
                Clone
              </Button>
            </>
          }
          { state.quote.quoteStatus === "Open" && 
            <QuoteProposeWarning />
          }
          { state.quote.quoteStatus === "Quoted" &&
            <>{
              state.quote.address?.jobId ?
                <Button
                  disabled={isConvertToCODisabled ||
                    state.pendingItems.filter((item: OptionTypes) => !item.isNew).length === 0 ||
                    !state.quote.quoteBuyer.salesforceAccountId ||
                    !state.quote.quoteBuyer.salesforceConnectionNumber ||
                    state.quote.quoteBuyer.salesforceConnectionNumber === "Create SF Connection"}
                  onClick={() => createCO() }
                  title={disabledToolTip}
                  variant="contained"
                >
                  Convert to CO
                </Button>
                :
                <AssignJobToQuote />
            }</>
          }
        </Box>
      </Stack>
      { showDiscardConfirmation && <DiscardQuoteConfirmation /> }
    </>
  );
};

export default QuoteFooter;
